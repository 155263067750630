import React, { useEffect, useState } from "react"

import "./ActionsMenuDropdown.css"

const Icon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  )
}

const ActionsMenuDropdown = ({ options, deleteAction, renameAction, style }) => {
  const [openMenu, setOpenMenu] = useState(false)

  const onItemClick = option => {
    console.log(option)
    if (option.value === 1) {
      deleteAction()
    } else if (option.value === 2) {
      renameAction()
    } else {
    }
    // setSelectedValue(option)
  }

  useEffect(() => {
    const handler = () => setOpenMenu(false)

    window.addEventListener("click", handler)
    return () => {
      window.removeEventListener("click", handler)
    }
  }, [])

  const handleClick = e => {
    e.stopPropagation()
    setOpenMenu(!openMenu)
  }

  return (
    <div className={"action-dropdown-container"}>
      <div className={"action-dropdown-input"} onClick={handleClick}>
        <div className="dropdown-tools">
          <div className="dropdown-tool">{/* <Icon /> */}</div>
        </div>
        {openMenu && (
          <div className={"action-dropdown-menu"} onMouseOver={e => e.stopPropagation()}>
            {options.map((x, i) => (
              <div key={x.value} className={"action-dropdown-item"} onClick={() => onItemClick(x)}>
                {x.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ActionsMenuDropdown
