import React, { useState, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { Auth, API } from "aws-amplify"
import awsmobile from "../../aws-exports"
import "./LeaderboardEntry.css"

Auth.configure(awsmobile)

const LeaderboardEntry = ({ rank, name, score, color, user_id }) => {
  const [profilePicIcon, setProfilePicIcon] = useState(null)

  const { isPending, error, data } = useQuery({
    queryKey: [`ProfilePicture_${user_id}`],
    queryFn: async () => {
      const ProfilePhotoUrl = await API.get("ZenoApp", "/getProfilePicture", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: user_id,
        },
      })
      return { ProfilePhotoUrl }
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  useEffect(() => {
    if (isPending) {
    } else {
      setProfilePicIcon(data.ProfilePhotoUrl)
    }
  }, [isPending])

  const teamMemberIconStyle = {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    backgroundColor: `${color}`,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    overflow: "hidden",
  }
  const initials = name.split(" ")[0].toUpperCase().charAt(0) + name.split(" ")[1].toUpperCase().charAt(0)
  return (
    <div className="LeaderboardEntryBox">
      <label className="LeaderboardEntryRank">{rank}</label>
      <div className="iconContainer">
        <div style={teamMemberIconStyle}>
          {profilePicIcon ? <img src={profilePicIcon} className="TopNavProPic"></img> : initials}
        </div>
      </div>
      <label className="LeaderboardEntryName">{name}</label>
      <label className="LeaderboardEntryScore">{score}</label>
    </div>
  )
}

export default LeaderboardEntry
