import { Auth } from "aws-amplify"
import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"
import { getUserInfo, getProfilePictureFromS3 } from "../../api/routes"

const UserFieldsModel = types
  .model("UserFields", {
    lastLoginDatetime: types.number,
    managerEmail: types.string,
    company: types.string,
    userEmail: types.string,
    userId: types.string,
    managerId: types.string,
    name: types.string,
    color: types.string,
    type: types.string,
    assistant_id: types.maybe(types.string),
    profilePicture: types.maybe(types.string)
    // TODO: Missing fields?
    // - FirstName, LastName (we have name, but SessionStorage was using first/last)
    //    - Note: made views for firstName/lastName, but should prob send down all 3
    //      separately (firstName, lastName, fullName) for all scenarios
    // - ProfilePicture
    // - RP_Creation_Assistant
  })
  .views(self => ({
    get firstName() {
      return self.name ?.split(" ") ?.[0] || "User"
    },
    get lastName() {
      return self.name ?.split(" ") ?.slice(-1)[0] || "User"
    }
  }))
  .views(self => ({
    get profileInitials() {
      return `${(self.firstName || "").charAt(0)}${(self.lastName || "").charAt(0)}`
    },
  }))
  .views(self => ({
    get profPicSignedUrl() {
      return self.profilePicture || "PP_NOT_DEFINED"
    },
  }))
export type UserFieldsSnapshotIn = SnapshotIn<typeof UserFieldsModel>

export const UserStoreModel = types
  .model("UserStore", {
    user: types.maybeNull(UserFieldsModel),
  })
  .views(self => ({
    isLoggedIn() {
      return false
    },
  }))
  .actions(self => ({
    getUser: flow(function* getUser() {
      try {
        const userInfoRes = yield getUserInfo()
        if (userInfoRes.kind === "ok") {
          self.user = UserFieldsModel.create(userInfoRes.data)
          return true
        }
      } catch (e) {
        return false
      }
      return false
    }),
  }))
  .actions(self => ({
    setProfilePicture: flow(function* setProfilePicture() {
      try {
        if (self.user) {
          // Call API to get signed URL for profile picture
          const urlRes = yield getProfilePictureFromS3(); // Ensure userId is passed to fetch the correct image
          
          // Set the profile picture URL in the user model
          if (urlRes.kind === "ok") {
            self.user.profilePicture = urlRes.data;
            console.log("User Store: successfully set profile picture to ", urlRes.data);
          }
        }
      } catch (e) {
        console.error("Error setting profile picture:", e.message);
      }
    }),
  }))
  .actions(self => ({
    login: flow(function* login(username: string, password: string) {
      try {
        yield Auth.signIn(username.toLowerCase(), password)

        // After successful login, call getUser()
        const getUserRes = yield self.getUser()
        if (getUserRes) {
          // Fetch and set the profile picture from S3
          yield self.setProfilePicture();
        }
        console.log(JSON.stringify(self))
        return getUserRes
      } catch (e) {
        alert(e.message)
      }
      return false
    }),
    logout: flow(function* logout() {
      yield Auth.signOut()
      self.user = null
    }),
  }))
export type UserStore = Instance<typeof UserStoreModel>
