import React, { useState, useRef, useEffect } from "react"

import "./PrivacyPolicyPage.css"

const PrivacyPolicyPage = () => {
  return (
    <div data-custom-class="body">
      <div>
        <strong>
          <span style={{ fontSize: 26 }}>
            <span data-custom-class="title">
              <bdt className="block-component" />
              <bdt className="question">PRIVACY POLICY</bdt>
              <bdt className="statement-end-if-in-editor" />
            </span>
          </span>
        </strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <strong>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="subtitle">
                Last updated <bdt className="question">January 12, 2024</bdt>
              </span>
            </span>
          </strong>
        </span>
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              This privacy notice for{" "}
              <bdt className="question">
                Zeno AI Solutions Ltd.
                <bdt className="block-component" /> (doing business as <bdt className="question">Pare.ai</bdt>)
                <bdt className="statement-end-if-in-editor" />
              </bdt>{" "}
              (<bdt className="block-component" />"<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>
              "
              <bdt className="statement-end-if-in-editor" />
            </span>
            <span data-custom-class="body_text">
              ), describes how and why we might collect, store, use, and/or share (
              <bdt className="block-component" />"<strong>process</strong>"
              <bdt className="statement-end-if-in-editor" />) your information when you use our services (
              <bdt className="block-component" />"<strong>Services</strong>"
              <bdt className="statement-end-if-in-editor" />
              ), such as when you:
            </span>
          </span>
        </span>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Visit our website
                <bdt className="block-component" /> at{" "}
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <bdt className="question">
                    <a href="https://www.pare-ai.com" target="_blank" data-custom-class="link">
                      https://www.pare-ai.com
                    </a>
                  </bdt>
                </span>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor">
                            , or any website of ours that links to this privacy notice
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div>
        <bdt className="block-component">
          <span style={{ fontSize: 15 }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </bdt>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Engage with us in other related ways, including any sales, marketing, or events
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <strong>Questions or concerns? </strong>Reading this privacy notice will help you understand your
                privacy rights and choices. If you do not agree with our policies and practices, please do not use our
                Services.
                <bdt className="block-component" /> If you still have any questions or concerns, please contact us at{" "}
                <bdt className="question">hello@pare-ai.com</bdt>.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <strong>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
            </span>
          </strong>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                <em>
                  This summary provides key points from our privacy notice, but you can find out more details about any
                  of these topics by clicking the link following each key point or by using our{" "}
                </em>
              </strong>
            </span>
          </span>
          <a data-custom-class="link" href="#toc">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>table of contents</em>
                </strong>
              </span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                <em> below to find the section you are looking for.</em>
              </strong>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services,
              we may process personal information depending on how you interact with us and the Services, the choices
              you make, and the products and features you use. Learn more about{" "}
            </span>
          </span>
          <a data-custom-class="link" href="#personalinfo">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <span data-custom-class="body_text">personal information you disclose to us</span>
            </span>
          </a>
          <span data-custom-class="body_text">.</span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>Do we process any sensitive personal information?</strong> <bdt className="block-component" />
              We do not process sensitive personal information.
              <bdt className="else-block" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>Do we receive any information from third parties?</strong> <bdt className="block-component" />
              We may receive information from public databases, marketing partners, social media platforms, and other
              outside sources. Learn more about{" "}
            </span>
          </span>
          <a data-custom-class="link" href="#othersources">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <span data-custom-class="body_text">information collected from other sources</span>
            </span>
          </a>
          <span data-custom-class="body_text">.</span>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="statement-end-if-in-editor" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>How do we process your information?</strong> We process your information to provide, improve, and
              administer our Services, communicate with you, for security and fraud prevention, and to comply with law.
              We may also process your information for other purposes with your consent. We process your information
              only when we have a valid legal reason to do so. Learn more about{" "}
            </span>
          </span>
          <a data-custom-class="link" href="#infouse">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <span data-custom-class="body_text">how we process your information</span>
            </span>
          </a>
          <span data-custom-class="body_text">.</span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                In what situations and with which <bdt className="block-component" />
                types of <bdt className="statement-end-if-in-editor" />
                parties do we share personal information?
              </strong>{" "}
              We may share information in specific situations and with specific <bdt className="block-component" />
              categories of <bdt className="statement-end-if-in-editor" />
              third parties. Learn more about{" "}
            </span>
          </span>
          <a data-custom-class="link" href="#whoshare">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <span data-custom-class="body_text">when and with whom we share your personal information</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              .<bdt className="block-component" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>How do we keep your information safe?</strong> We have <bdt className="block-component" />
              organizational
              <bdt className="statement-end-if-in-editor" /> and technical processes and procedures in place to protect
              your personal information. However, no electronic transmission over the internet or information storage
              technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
              cybercriminals, or other <bdt className="block-component" />
              unauthorized
              <bdt className="statement-end-if-in-editor" /> third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information. Learn more about{" "}
            </span>
          </span>
          <a data-custom-class="link" href="#infosafe">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <span data-custom-class="body_text">how we keep your information safe</span>
            </span>
          </a>
          <span data-custom-class="body_text">.</span>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="statement-end-if-in-editor" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable
              privacy law may mean you have certain rights regarding your personal information. Learn more about{" "}
            </span>
          </span>
          <a data-custom-class="link" href="#privacyrights">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <span data-custom-class="body_text">your privacy rights</span>
            </span>
          </a>
          <span data-custom-class="body_text">.</span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by{" "}
              <bdt className="block-component">submitting a </bdt>
            </span>
          </span>
          <a
            data-custom-class="link"
            href="https://app.termly.io/notify/7b604b77-6008-4b21-bfaf-646f17fe595a"
            rel="noopener noreferrer"
            target="_blank">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <span data-custom-class="body_text">data subject access request</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />, or by contacting us. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              Want to learn more about what we do with any information we collect?{" "}
            </span>
          </span>
          <a data-custom-class="link" href="#toc">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <span data-custom-class="body_text">Review the privacy notice in full</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">.</span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="toc" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                </strong>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infocollect">
              <span style={{ color: "rgb(0, 58, 250)" }}>1. WHAT INFORMATION DO WE COLLECT?</span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infouse">
              <span style={{ color: "rgb(0, 58, 250)" }}>
                2. HOW DO WE PROCESS YOUR INFORMATION?
                <bdt className="block-component" />
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(0, 58, 250)" }}>
              <a data-custom-class="link" href="#whoshare">
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </span>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#cookies">
              <span style={{ color: "rgb(0, 58, 250)" }}>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#sociallogins">
              <span style={{ color: "rgb(0, 58, 250)" }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  <span style={{ color: "rgb(0, 58, 250)" }}>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
                </span>
              </span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#inforetain">
              <span style={{ color: "rgb(0, 58, 250)" }}>6. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infosafe">
              <span style={{ color: "rgb(0, 58, 250)" }}>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infominors">
              <span style={{ color: "rgb(0, 58, 250)" }}>8. DO WE COLLECT INFORMATION FROM MINORS?</span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(0, 58, 250)" }}>
              <a data-custom-class="link" href="#privacyrights">
                9. WHAT ARE YOUR PRIVACY RIGHTS?
              </a>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#DNT">
              <span style={{ color: "rgb(0, 58, 250)" }}>
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
                <bdt className="block-component" />
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#uslaws">
              <span style={{ color: "rgb(0, 58, 250)" }}>
                11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </a>
          </span>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }} />
          </bdt>
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
          <bdt className="block-component" />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#policyupdates">
              <span style={{ color: "rgb(0, 58, 250)" }}>12. DO WE MAKE UPDATES TO THIS NOTICE?</span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#contact">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#request">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            </span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="infocollect" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 0, 0)" }}>
            <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="personalinfo" style={{ lineHeight: "1.5" }}>
          <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
            <span style={{ fontSize: 15 }}>
              <strong>Personal information you disclose to us</strong>
            </span>
          </span>
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:</em>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em> </em>
                        </strong>
                        <em>We collect personal information that you provide to us.</em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                We collect personal information that you voluntarily provide to us when you{" "}
                <span style={{ fontSize: 15 }}>
                  <bdt className="block-component" />
                </span>
                register on the Services,{" "}
              </span>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                <span data-custom-class="body_text">
                  express an interest in obtaining information about us or our products and Services, when you
                  participate in activities on the Services, or otherwise when you contact us.
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>Personal Information Provided by You.</strong> The personal information that we collect depends
                on the context of your interactions with us and the Services, the choices you make, and the products and
                features you use. The personal information we collect may include the following:
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">names</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">phone numbers</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">email addresses</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">mailing addresses</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">job titles</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">usernames</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">passwords</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">contact preferences</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">contact or authentication data</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">billing addresses</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">debit/credit card numbers</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>Sensitive Information.</strong> <bdt className="block-component" />
              We do not process sensitive information.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="else-block" />
            </span>
          </span>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make
                purchases, such as your payment instrument number, and the security code associated with your payment
                instrument. All payment data is stored by
                <bdt className="forloop-component" />
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>{" "}
                <bdt className="question">__________</bdt>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <bdt className="forloop-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      . You may find their privacy notice link(s) here:
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="forloop-component" />
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>{" "}
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <bdt className="question">__________</bdt>
                      </span>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <bdt className="forloop-component" />
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text">
                                .<bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>Social Media Login Data. </strong>We may provide you with the option to register with us using
                your existing social media account details, like your Facebook, Twitter, or other social media account.
                If you choose to register in this way, we will collect the information described in the section called{" "}
                <bdt className="block-component" />"
                <bdt className="statement-end-if-in-editor" />
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a data-custom-class="link" href="#sociallogins">
                          HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </a>
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="block-component" />"
                <bdt className="statement-end-if-in-editor" /> below.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <bdt className="statement-end-if-in-editor">
                    <bdt className="statement-end-if-in-editor" />
                  </bdt>
                </span>
              </span>
            </span>
          </span>
          <bdt className="block-component" />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                All personal information that you provide to us must be true, complete, and accurate, and you must
                notify us of any changes to such personal information.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
            <span style={{ fontSize: 15 }}>
              <strong>Information automatically collected</strong>
            </span>
          </span>
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:</em>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em> </em>
                        </strong>
                        <em>
                          Some information — such as your Internet Protocol (IP) address and/or browser and device
                          characteristics — is collected automatically when you visit our Services.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                We automatically collect certain information when you visit, use, or navigate the Services. This
                information does not reveal your specific identity (like your name or contact information) but may
                include device and usage information, such as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device name, country, location, information
                about how and when you use our Services, and other technical information. This information is primarily
                needed to maintain the security and operation of our Services, and for our internal analytics and
                reporting purposes.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Like many businesses, we also collect information through cookies and similar technologies.{" "}
                <bdt className="block-component" />
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor">
                  <span data-custom-class="body_text" />
                </bdt>
              </span>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                The information we collect includes:
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access or use our Services and which we record
                  in log files. Depending on how you interact with us, this log data may include your IP address, device
                  information, browser type, and settings and information about your activity in the Services
                  <span style={{ fontSize: 15 }}> </span>(such as the date/time stamps associated with your usage, pages
                  and files viewed, searches, and other actions you take such as which features you use), device event
                  information (such as system activity, error reports (sometimes called{" "}
                  <bdt className="block-component" />
                  "crash dumps"
                  <bdt className="statement-end-if-in-editor" />
                  ), and hardware settings).
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
          </bdt>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Device Data.</em> We collect device data such as information about your computer, phone, tablet,
                  or other device you use to access the Services. Depending on the device used, this device data may
                  include information such as your IP address (or proxy server), device and application identification
                  numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier,
                  operating system, and system configuration information.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
          </bdt>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Location Data.</em> We collect location data such as information about your device's location,
                  which can be either precise or imprecise. How much information we collect depends on the type and
                  settings of the device you use to access the Services. For example, we may use GPS and other
                  technologies to collect geolocation data that tells us your current location (based on your IP
                  address). You can opt out of allowing us to collect this information either by refusing access to the
                  information or by disabling your Location setting on your device. However, if you choose to opt out,
                  you may not be able to use certain aspects of the Services.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }} />
          </bdt>
          <bdt className="statement-end-if-in-editor" />
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div id="othersources" style={{ lineHeight: "1.5" }}>
          <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
            <span style={{ fontSize: 15 }}>
              <strong>Information collected from other sources</strong>
            </span>
          </span>
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short: </em>
                        </strong>
                        <em>
                          We may collect limited data from public databases, marketing partners,{" "}
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15 }}>
                              <em>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                  social media platforms, <bdt className="statement-end-if-in-editor" />
                                </span>
                              </em>
                            </span>
                          </span>
                          and other outside sources.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                In order to enhance our ability to provide relevant marketing, offers, and services to you and update
                our records, we may obtain information about you from other sources, such as public databases, joint
                marketing partners, affiliate programs, data providers,
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" /> social media platforms,
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>{" "}
                </span>
                and from other third parties. This information includes mailing addresses, job titles, email addresses,
                phone numbers, intent data (or user <bdt className="block-component" />
                behavior
                <bdt className="statement-end-if-in-editor" /> data), Internet Protocol (IP) addresses, social media
                profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event
                promotion.
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>{" "}
                      </span>
                      If you interact with us on a social media platform using your social media account (e.g.
                      <bdt className="block-component" />,
                      <bdt className="statement-end-if-in-editor" /> Facebook or Twitter), we receive personal
                      information about you such as your name, email address, and gender. Any personal information that
                      we collect from your social media account depends on your social media account's privacy settings.
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component">
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor">
                                      <bdt className="statement-end-if-in-editor" />
                                    </bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </div>
        <div id="infouse" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short: </em>
                        </strong>
                        <em>
                          We process your information to provide, improve, and administer our Services, communicate with
                          you, for security and fraud prevention, and to comply with law. We may also process your
                          information for other purposes with your consent.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  We process your personal information for a variety of reasons, depending on how you interact with our
                  Services, including:
                </strong>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>
                    To facilitate account creation and authentication and otherwise manage user accounts.{" "}
                  </strong>
                  We may process your information so you can create and log in to your account, as well as keep your
                  account in working order.
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>To deliver and facilitate delivery of services to the user. </strong>
                  We may process your information to provide you with the requested service.
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>To respond to user inquiries/offer support to users. </strong>
                    We may process your information to respond to your inquiries and solve any potential issues you
                    might have with the requested service.
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
                <span style={{ fontSize: 15 }} />
              </bdt>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>To send administrative information to you. </strong>We may process your information to
                      send you details about our products and services, changes to our terms and policies, and other
                      similar information.
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
                <span style={{ fontSize: 15 }} />
              </bdt>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
                <div style={{ lineHeight: "1.5" }}>
                  <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text" />
                    </span>
                  </bdt>
                  <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                  </p>
                  <ul>
                    <li style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <strong>To enable user-to-user communications. </strong>
                              We may process your information if you choose to use any of our offerings that allow for
                              communication with another user.
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                          <span data-custom-class="body_text">
                                            <bdt className="statement-end-if-in-editor" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                  </p>
                  <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                    <bdt className="block-component" />
                  </p>
                  <ul>
                    <li style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <strong>To request feedback. </strong>We may process your information when necessary to
                              request feedback and to contact you about your use of our Services.
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                          <span data-custom-class="body_text">
                                            <bdt className="statement-end-if-in-editor" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                    <bdt className="block-component" />
                  </p>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text" />
                      </span>
                    </bdt>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <span style={{ fontSize: 15 }}>
                            <bdt className="block-component">
                              <span data-custom-class="body_text" />
                            </bdt>
                          </span>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                          </div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                  <strong>To post testimonials.</strong> We post testimonials on our Services that may
                                  contain personal information.
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                          </div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <strong>To protect our Services.</strong> We may process your information as part of
                                  our efforts to keep our Services safe and secure, including fraud monitoring and
                                  prevention.
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                            </li>
                          </ul>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                              </div>
                              <ul>
                                <li style={{ lineHeight: "1.5" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <strong>
                                        To evaluate and improve our Services, products, marketing, and your experience.
                                      </strong>{" "}
                                      We may process your information when we believe it is necessary to identify usage
                                      trends, determine the effectiveness of our promotional campaigns, and to evaluate
                                      and improve our Services, products, marketing, and your experience.
                                    </span>
                                  </span>
                                  <bdt className="statement-end-if-in-editor">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                </li>
                              </ul>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                              </div>
                              <ul>
                                <li style={{ lineHeight: "1.5" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <strong>To identify usage trends.</strong> We may process information about how
                                      you use our Services to better understand how they are being used so we can
                                      improve them.
                                    </span>
                                  </span>
                                  <bdt className="statement-end-if-in-editor">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                </li>
                              </ul>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div id="whoshare" style={{ lineHeight: "1.5" }}>
                                          <span style={{ color: "rgb(127, 127, 127)" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                                fontSize: 15,
                                              }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span
                                                    id="control"
                                                    style={{
                                                      color: "rgb(0, 0, 0)",
                                                    }}>
                                                    <strong>
                                                      <span data-custom-class="heading_1">
                                                        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                                                      </span>
                                                    </strong>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <strong>
                                                  <em>In Short:</em>
                                                </strong>
                                                <em>
                                                  {" "}
                                                  We may share information in specific situations described in this
                                                  section and/or with the following <bdt className="block-component" />
                                                  categories of <bdt className="statement-end-if-in-editor" />
                                                  third parties.
                                                </em>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <strong>
                                                Vendors, Consultants, and Other Third-Party Service Providers.
                                              </strong>{" "}
                                              We may share your data with third-party vendors, service providers,
                                              contractors, or agents (
                                              <bdt className="block-component" />"<strong>third parties</strong>"
                                              <bdt className="statement-end-if-in-editor" />) who perform services for
                                              us or on our behalf and require access to such information to do that
                                              work. <bdt className="block-component" />
                                              The <bdt className="block-component" />
                                              categories of <bdt className="statement-end-if-in-editor" />
                                              third parties we may share personal information with are as follows:
                                            </span>
                                          </span>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                <bdt className="forloop-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="question">Data Analytics Services</bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <span style={{ fontSize: 15 }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="forloop-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="question">Data Storage Service Providers</bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <span style={{ fontSize: 15 }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="forloop-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="question">Sales &amp; Marketing Tools</bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <span style={{ fontSize: 15 }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="forloop-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="question">Social Networks</bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <span style={{ fontSize: 15 }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="forloop-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="question">
                                                    Communication &amp; Collaboration Tools
                                                  </bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <span style={{ fontSize: 15 }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="forloop-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="question">Product Engineering &amp; Design Tools</bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <span style={{ fontSize: 15 }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="forloop-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="question">Website Hosting Service Providers</bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <span style={{ fontSize: 15 }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="forloop-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                color: "rgb(89, 89, 89)",
                                                                              }}>
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      color: "rgb(89, 89, 89)",
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <span
                                                                                        style={{
                                                                                          color: "rgb(89, 89, 89)",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <bdt className="block-component" />
                                          <span data-custom-class="body_text" />
                                          <span data-custom-class="body_text" />
                                          <span data-custom-class="body_text" />
                                          <span data-custom-class="body_text" />
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              We <bdt className="block-component" />
                                              also <bdt className="statement-end-if-in-editor" />
                                              may need to share your personal information in the following situations:
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text">
                                                <strong>Business Transfers.</strong> We may share or transfer your
                                                information in connection with, or during negotiations of, any merger,
                                                sale of company assets, financing, or acquisition of all or a portion of
                                                our business to another company.
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: 15 }}>
                                              <bdt className="block-component">
                                                <span data-custom-class="body_text" />
                                              </bdt>
                                            </span>
                                          </div>
                                          <ul>
                                            <li style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  <strong>Affiliates. </strong>We may share your information with our
                                                  affiliates, in which case we will require those affiliates to{" "}
                                                  <bdt className="block-component" />
                                                  honor
                                                  <bdt className="statement-end-if-in-editor" /> this privacy notice.
                                                  Affiliates include our parent company and any subsidiaries, joint
                                                  venture partners, or other companies that we control or that are under
                                                  common control with us.
                                                </span>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span data-custom-class="body_text" />
                                                </bdt>
                                              </span>
                                            </li>
                                          </ul>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <strong>Business Partners.</strong> We may share your information with
                                                  our business partners to offer you certain products, services, or
                                                  promotions.
                                                </span>
                                              </span>
                                              <bdt className="statement-end-if-in-editor">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                            </li>
                                          </ul>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  <strong>Other Users.</strong> When you share personal information
                                                  <bdt className="block-component" /> (for example, by posting comments,
                                                  contributions, or other content to the Services)
                                                  <bdt className="statement-end-if-in-editor" /> or otherwise interact
                                                  with public areas of the Services, such personal information may be
                                                  viewed by all users and may be publicly made available outside the
                                                  Services in perpetuity.
                                                  <bdt className="block-component" /> If you interact with other users
                                                  of our Services and register for our Services through a social network
                                                  (such as Facebook), your contacts on the social network will see your
                                                  name, profile photo, and descriptions of your activity.
                                                  <bdt className="statement-end-if-in-editor" /> Similarly, other users
                                                  will be able to view descriptions of your activity, communicate with
                                                  you within our Services, and view your profile.
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                  fontSize: 15,
                                                }}>
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span style={{ fontSize: 15 }}>
                                                      <span
                                                        style={{
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <bdt className="block-component">
                                                          <span data-custom-class="heading_1" />
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div id="cookies" style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15,
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: "rgb(0, 0, 0)",
                                                        }}>
                                                        <strong>
                                                          <span data-custom-class="heading_1">
                                                            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <em>In Short:</em>
                                                    </strong>
                                                    <em>
                                                      {" "}
                                                      We may use cookies and other tracking technologies to collect and
                                                      store your information.
                                                    </em>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    We may use cookies and similar tracking technologies (like web
                                                    beacons and pixels) to access or store information. Specific
                                                    information about how we use such technologies and how you can
                                                    refuse certain cookies is set out in our Cookie Notice
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />.
                                                      </span>
                                                      <span
                                                        style={{
                                                          color: "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}>
                                                        <span style={{ fontSize: 15 }}>
                                                          <span
                                                            style={{
                                                              color: "rgb(89, 89, 89)",
                                                            }}>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}>
                                                              <span
                                                                style={{
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}>
                                                      <span
                                                        style={{
                                                          color: "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}>
                                                          <span style={{ fontSize: 15 }}>
                                                            <span
                                                              style={{
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    color: "rgb(89, 89, 89)",
                                                                  }}>
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div id="sociallogins" style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15,
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: "rgb(0, 0, 0)",
                                                        }}>
                                                        <strong>
                                                          <span data-custom-class="heading_1">
                                                            5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <em>In Short: </em>
                                                    </strong>
                                                    <em>
                                                      If you choose to register or log in to our Services using a social
                                                      media account, we may have access to certain information about
                                                      you.
                                                    </em>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    Our Services offer you the ability to register and log in using your
                                                    third-party social media account details (like your Facebook or
                                                    Twitter logins). Where you choose to do this, we will receive
                                                    certain profile information about you from your social media
                                                    provider. The profile information we receive may vary depending on
                                                    the social media provider concerned, but will often include your
                                                    name, email address, friends list, and profile picture, as well as
                                                    other information you choose to make public on such a social media
                                                    platform.
                                                    <span style={{ fontSize: 15 }}>
                                                      <span
                                                        style={{
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    We will use the information we receive only for the purposes that
                                                    are described in this privacy notice or that are otherwise made
                                                    clear to you on the relevant Services. Please note that we do not
                                                    control, and are not responsible for, other uses of your personal
                                                    information by your third-party social media provider. We recommend
                                                    that you review their privacy notice to understand how they collect,
                                                    use, and share your personal information, and how you can set your
                                                    privacy preferences on their sites and apps.
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}>
                                                      <span
                                                        style={{
                                                          color: "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}>
                                                              <span
                                                                style={{
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                    <bdt className="block-component">
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div id="inforetain" style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15,
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: "rgb(0, 0, 0)",
                                                        }}>
                                                        <strong>
                                                          <span data-custom-class="heading_1">
                                                            6. HOW LONG DO WE KEEP YOUR INFORMATION?
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <em>In Short: </em>
                                                    </strong>
                                                    <em>
                                                      We keep your information for as long as necessary to{" "}
                                                      <bdt className="block-component" />
                                                      fulfill
                                                      <bdt className="statement-end-if-in-editor" /> the purposes
                                                      outlined in this privacy notice unless otherwise required by law.
                                                    </em>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    We will only keep your personal information for as long as it is
                                                    necessary for the purposes set out in this privacy notice, unless a
                                                    longer retention period is required or permitted by law (such as
                                                    tax, accounting, or other legal requirements).
                                                    <bdt className="block-component" /> No purpose in this notice will
                                                    require us keeping your personal information for longer than{" "}
                                                    <span style={{ fontSize: 15 }}>
                                                      <span
                                                        style={{
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>{" "}
                                                    </span>{" "}
                                                    <bdt className="block-component" />
                                                    the period of time in which users have an account with us
                                                    <bdt className="block-component" />
                                                    <span style={{ fontSize: 15 }}>
                                                      <span
                                                        style={{
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="else-block" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                    .
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    When we have no ongoing legitimate business need to process your
                                                    personal information, we will either delete or{" "}
                                                    <bdt className="block-component" />
                                                    anonymize
                                                    <bdt className="statement-end-if-in-editor" /> such information, or,
                                                    if this is not possible (for example, because your personal
                                                    information has been stored in backup archives), then we will
                                                    securely store your personal information and isolate it from any
                                                    further processing until deletion is possible.
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div id="infosafe" style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15,
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: "rgb(0, 0, 0)",
                                                        }}>
                                                        <strong>
                                                          <span data-custom-class="heading_1">
                                                            7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <em>In Short: </em>
                                                    </strong>
                                                    <em>
                                                      We aim to protect your personal information through a system of{" "}
                                                      <bdt className="block-component" />
                                                      organizational
                                                      <bdt className="statement-end-if-in-editor" /> and technical
                                                      security measures.
                                                    </em>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    We have implemented appropriate and reasonable technical and{" "}
                                                    <bdt className="block-component" />
                                                    organizational
                                                    <bdt className="statement-end-if-in-editor" /> security measures
                                                    designed to protect the security of any personal information we
                                                    process. However, despite our safeguards and efforts to secure your
                                                    information, no electronic transmission over the Internet or
                                                    information storage technology can be guaranteed to be 100% secure,
                                                    so we cannot promise or guarantee that hackers, cybercriminals, or
                                                    other <bdt className="block-component" />
                                                    unauthorized
                                                    <bdt className="statement-end-if-in-editor" /> third parties will
                                                    not be able to defeat our security and improperly collect, access,
                                                    steal, or modify your information. Although we will do our best to
                                                    protect your personal information, transmission of personal
                                                    information to and from our Services is at your own risk. You should
                                                    only access the Services within a secure environment.
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <bdt className="statement-end-if-in-editor" />
                                                    </span>
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div id="infominors" style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15,
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: "rgb(0, 0, 0)",
                                                        }}>
                                                        <strong>
                                                          <span data-custom-class="heading_1">
                                                            8. DO WE COLLECT INFORMATION FROM MINORS?
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <em>In Short:</em>
                                                    </strong>
                                                    <em>
                                                      {" "}
                                                      We do not knowingly collect data from or market to{" "}
                                                      <bdt className="block-component" />
                                                      children under 18 years of age
                                                      <bdt className="else-block" />.
                                                    </em>
                                                    <bdt className="block-component" />
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    We do not knowingly solicit data from or market to children under 18
                                                    years of age. By using the Services, you represent that you are at
                                                    least 18 or that you are the parent or guardian of such a minor and
                                                    consent to such minor dependent’s use of the Services. If we learn
                                                    that personal information from users less than 18 years of age has
                                                    been collected, we will deactivate the account and take reasonable
                                                    measures to promptly delete such data from our records. If you
                                                    become aware of any data we may have collected from children under
                                                    age 18, please contact us at{" "}
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                        <bdt className="question">hello@pare-ai.com</bdt>
                                                        <bdt className="else-block" />
                                                      </span>
                                                    </span>
                                                    .
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    <bdt className="else-block">
                                                      <bdt className="block-component" />
                                                    </bdt>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div id="privacyrights" style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15,
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: "rgb(0, 0, 0)",
                                                        }}>
                                                        <strong>
                                                          <span data-custom-class="heading_1">
                                                            9. WHAT ARE YOUR PRIVACY RIGHTS?
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <em>In Short:</em>
                                                    </strong>
                                                    <em>
                                                      {" "}
                                                      <span
                                                        style={{
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span style={{ fontSize: 15 }}>
                                                          <span data-custom-class="body_text">
                                                            <em>
                                                              <bdt className="block-component" />
                                                            </em>
                                                          </span>
                                                        </span>{" "}
                                                      </span>
                                                      You may review, change, or terminate your account at any time.
                                                    </em>
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span style={{ fontSize: 15 }}>
                                                        <bdt className="block-component">
                                                          <bdt className="block-component" />
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div id="withdrawconsent" style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <u>Withdrawing your consent:</u>
                                                    </strong>{" "}
                                                    If we are relying on your consent to process your personal
                                                    information,
                                                    <bdt className="block-component" /> which may be express and/or
                                                    implied consent depending on the applicable law,
                                                    <bdt className="statement-end-if-in-editor" /> you have the right to
                                                    withdraw your consent at any time. You can withdraw your consent at
                                                    any time by contacting us by using the contact details provided in
                                                    the section <bdt className="block-component" />
                                                    "
                                                    <bdt className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </span>
                                              <a data-custom-class="link" href="#contact">
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(0, 58, 250)",
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(0, 58, 250)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                                    </span>
                                                  </span>
                                                </span>
                                              </a>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <bdt className="block-component" />
                                                    "
                                                    <bdt className="statement-end-if-in-editor" /> below
                                                    <bdt className="block-component" />.
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  However, please note that this will not affect the lawfulness of the
                                                  processing before its withdrawal nor,
                                                  <bdt className="block-component" /> when applicable law allows,
                                                  <bdt className="statement-end-if-in-editor" /> will it affect the
                                                  processing of your personal information conducted in reliance on
                                                  lawful processing grounds other than consent.
                                                  <bdt className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    <u>Opting out of marketing and promotional communications:</u>
                                                  </strong>
                                                  <strong>
                                                    <u> </u>
                                                  </strong>
                                                  You can unsubscribe from our marketing and promotional communications
                                                  at any time by
                                                  <bdt className="block-component" />
                                                  <bdt className="block-component" /> replying{" "}
                                                  <bdt className="block-component" />
                                                  "STOP" or "UNSUBSCRIBE"
                                                  <bdt className="statement-end-if-in-editor" /> to the SMS messages
                                                  that we send,
                                                  <bdt className="statement-end-if-in-editor" />
                                                  <bdt className="block-component" /> or by contacting us using the
                                                  details provided in the section <bdt className="block-component" />
                                                  "
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                              <a data-custom-class="link" href="#contact">
                                                <span
                                                  style={{
                                                    color: "rgb(0, 58, 250)",
                                                    fontSize: 15,
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                                  </span>
                                                </span>
                                              </a>
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="block-component" />
                                                  "
                                                  <bdt className="statement-end-if-in-editor" /> below. You will then be
                                                  removed from the marketing lists. However, we may still communicate
                                                  with you — for example, to send you service-related messages that are
                                                  necessary for the administration and use of your account, to respond
                                                  to service requests, or for other non-marketing purposes.
                                                </span>
                                                <span data-custom-class="body_text">
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="heading_2">
                                                  <strong>Account Information</strong>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  If you would at any time like to review or change the information in
                                                  your account or terminate your account, you can:
                                                  <bdt className="forloop-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span data-custom-class="body_text">
                                                  <span style={{ fontSize: 15 }}>
                                                    <bdt className="question">
                                                      Contact us using the contact information provided.
                                                    </bdt>
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <bdt className="forloop-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  Upon your request to terminate your account, we will deactivate or
                                                  delete your account and information from our active databases.
                                                  However, we may retain some information in our files to prevent fraud,
                                                  troubleshoot problems, assist with any investigations, enforce our
                                                  legal terms and/or comply with applicable legal requirements.
                                                </span>
                                              </span>
                                              <bdt className="statement-end-if-in-editor">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <span style={{ fontSize: 15 }}>
                                                      <span
                                                        style={{
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span style={{ fontSize: 15 }}>
                                                          <span
                                                            style={{
                                                              color: "rgb(89, 89, 89)",
                                                            }}>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    color: "rgb(89, 89, 89)",
                                                                  }}>
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <u>Cookies and similar technologies:</u>
                                                    </strong>{" "}
                                                    Most Web browsers are set to accept cookies by default. If you
                                                    prefer, you can usually choose to set your browser to remove cookies
                                                    and to reject cookies. If you choose to remove cookies or reject
                                                    cookies, this could affect certain features or services of our
                                                    Services.{" "}
                                                    <bdt className="block-component">
                                                      <span style={{ fontSize: 15 }}>
                                                        <span
                                                          style={{
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}>
                                                              <span
                                                                style={{
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </bdt>
                                                  </span>
                                                </span>
                                              </span>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  If you have questions or comments about your privacy rights, you may
                                                  email us at <bdt className="question">hello@pare-ai.com</bdt>.
                                                </span>
                                              </span>
                                              <bdt className="statement-end-if-in-editor">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div id="DNT" style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15,
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: "rgb(0, 0, 0)",
                                                        }}>
                                                        <strong>
                                                          <span data-custom-class="heading_1">
                                                            10. CONTROLS FOR DO-NOT-TRACK FEATURES
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    Most web browsers and some mobile operating systems and mobile
                                                    applications include a Do-Not-Track (
                                                    <bdt className="block-component" />
                                                    "DNT"
                                                    <bdt className="statement-end-if-in-editor" />) feature or setting
                                                    you can activate to signal your privacy preference not to have data
                                                    about your online browsing activities monitored and collected. At
                                                    this stage no uniform technology standard for{" "}
                                                    <bdt className="block-component" />
                                                    recognizing
                                                    <bdt className="statement-end-if-in-editor" /> and implementing DNT
                                                    signals has been <bdt className="block-component" />
                                                    finalized
                                                    <bdt className="statement-end-if-in-editor" />
                                                    . As such, we do not currently respond to DNT browser signals or any
                                                    other mechanism that automatically communicates your choice not to
                                                    be tracked online. If a standard for online tracking is adopted that
                                                    we must follow in the future, we will inform you about that practice
                                                    in a revised version of this privacy notice.
                                                    <bdt className="block-component" />
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div id="uslaws" style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15,
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: "rgb(0, 0, 0)",
                                                        }}>
                                                        <strong>
                                                          <span data-custom-class="heading_1">
                                                            11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <em>In Short: </em>
                                                    </strong>
                                                    <em>
                                                      If you are a resident of <bdt className="forloop-component" />
                                                      <bdt className="block-component" />
                                                      <bdt className="forloop-component" /> California
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="block-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="block-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component" />
                                                      , Colorado
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="statement-end-if-in-editor" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="block-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component">, Connecticut</bdt>
                                                      <bdt className="forloop-component" />
                                                      <bdt className="statement-end-if-in-editor" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="block-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component">, Utah</bdt>
                                                      <bdt className="forloop-component" />
                                                      <bdt className="statement-end-if-in-editor" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="block-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component" />
                                                      <bdt className="forloop-component" /> or Virginia
                                                      <bdt className="forloop-component" />
                                                      <bdt className="else-block" />
                                                      <bdt className="forloop-component" />, you are granted specific
                                                      rights regarding access to your personal information.
                                                    </em>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      What categories of personal information do we collect?
                                                    </strong>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    We have collected the following categories of personal information
                                                    in the past twelve (12) months:
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <table style={{ width: "100%" }}>
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span data-custom-class="body_text">
                                                          <strong>Category</strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderTop: "1px solid black",
                                                      borderRight: "1px solid black",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span data-custom-class="body_text">
                                                          <strong>Examples</strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                      textAlign: "center",
                                                    }}>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)",
                                                      }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span data-custom-class="body_text">
                                                          <strong>Collected</strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">A. Identifiers</span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderTop: "1px solid black",
                                                      borderRight: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Contact details, such as real name, alias, postal address,
                                                            telephone or mobile contact number, unique personal
                                                            identifier, online identifier, Internet Protocol address,
                                                            email address, and account name
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      textAlign: "center",
                                                      verticalAlign: "middle",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component">
                                                              <bdt className="block-component" />
                                                              <bdt className="forloop-component" />
                                                              YES
                                                              <bdt className="forloop-component" />
                                                              <bdt className="block-component" />
                                                              <bdt className="statement-end-if-in-editor">
                                                                <bdt className="block-component" />
                                                              </bdt>
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component" />
                                            </div>
                                            <table style={{ width: "100%" }}>
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            B. Personal information as defined in the California
                                                            Customer Records statute
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Name, contact information, education, employment, employment
                                                            history, and financial information
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="forloop-component">
                                                              <bdt className="block-component" />
                                                            </bdt>
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            NO
                                                            <bdt className="statement-end-if-in-editor">
                                                              <bdt className="block-component" />
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component" />
                                            </div>
                                            <table style={{ width: "100%" }}>
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            C
                                                            <bdt className="else-block" />. Protected classification
                                                            characteristics under state or federal law
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Gender and date of birth
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                                      <bdt className="forloop-component">
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                          <bdt className="forloop-component" />
                                                          <bdt className="block-component" />
                                                          YES
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="forloop-component" />
                                                          <bdt className="block-component">
                                                            <bdt className="block-component" />
                                                          </bdt>
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            D
                                                            <bdt className="else-block" />. Commercial information
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Transaction information, purchase history, financial
                                                            details, and payment information
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                                      <bdt className="forloop-component">
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </bdt>
                                                      <bdt className="forloop-component" />
                                                      <bdt className="block-component">
                                                        NO
                                                        <bdt className="block-component" />
                                                      </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            E
                                                            <bdt className="else-block" />. Biometric information
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Fingerprints and voiceprints
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                                      <bdt className="forloop-component">
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component">
                                                            <bdt className="forloop-component" />
                                                            <bdt className="block-component" />
                                                            NO
                                                            <bdt className="statement-end-if-in-editor" />
                                                          </bdt>
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            F
                                                            <bdt className="else-block" />. Internet or other similar
                                                            network activity
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Browsing history, search history, online{" "}
                                                            <bdt className="block-component" />
                                                            behavior
                                                            <bdt className="statement-end-if-in-editor" />, interest
                                                            data, and interactions with our and other websites,
                                                            applications, systems, and advertisements
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                                      <bdt className="forloop-component">
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                          <bdt className="forloop-component" />
                                                          <bdt className="block-component">NO</bdt>
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            G
                                                            <bdt className="else-block" />. Geolocation data
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">Device location</span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                                      <bdt className="forloop-component">
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                          <bdt className="forloop-component" />
                                                          <bdt className="block-component" />
                                                          YES
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="forloop-component" />
                                                          <bdt className="block-component">
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                          </bdt>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            H
                                                            <bdt className="else-block" />. Audio, electronic, visual,
                                                            thermal, olfactory, or similar information
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Images and audio, video or call recordings created in
                                                            connection with our business activities
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                                      <bdt className="forloop-component">
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                          <bdt className="forloop-component" />
                                                          <bdt className="block-component">NO</bdt>
                                                          <bdt className="statement-end-if-in-editor">
                                                            <bdt className="block-component" />
                                                          </bdt>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "33.8274%",
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            I
                                                            <bdt className="else-block" />. Professional or
                                                            employment-related information
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "51.4385%",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Business contact details in order to provide you our
                                                            Services at a business level or job title, work history, and
                                                            professional qualifications if you apply for a job with us
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "14.9084%",
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                                      <bdt className="forloop-component">
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                          <bdt className="forloop-component" />
                                                          <bdt className="block-component" />
                                                          YES
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="forloop-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                      width: "33.8274%",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            J
                                                            <bdt className="else-block" />. Education Information
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                      width: "51.4385%",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Student records and directory information
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderTop: "1px solid black",
                                                      width: "14.9084%",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                                      <bdt className="forloop-component">
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                          <bdt className="forloop-component" />
                                                          <bdt className="block-component" />
                                                          NO
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      borderWidth: 1,
                                                      borderColor: "black",
                                                      borderStyle: "solid",
                                                      width: "33.8274%",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            K
                                                            <bdt className="else-block" />. Inferences drawn from
                                                            collected personal information
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      borderBottom: "1px solid black",
                                                      borderTop: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      width: "51.4385%",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)",
                                                        }}>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            Inferences drawn from any of the collected personal
                                                            information listed above to create a profile or summary
                                                            about, for example, an individual’s preferences and
                                                            characteristics
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                      borderRight: "1px solid black",
                                                      borderBottom: "1px solid black",
                                                      borderTop: "1px solid black",
                                                      width: "14.9084%",
                                                    }}>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                    <div data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                        NO
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)",
                                                          }}>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color: "rgb(89, 89, 89)",
                                                            }}>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      borderLeft: "1px solid black",
                                                      borderRight: "1px solid black",
                                                      borderBottom: "1px solid black",
                                                      lineHeight: "1.5",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                      L
                                                      <bdt className="else-block" />. Sensitive personal Information
                                                    </span>
                                                  </td>
                                                  <td
                                                    style={{
                                                      borderRight: "1px solid black",
                                                      borderBottom: "1px solid black",
                                                      lineHeight: "1.5",
                                                    }}>
                                                    <bdt className="block-component">
                                                      <span data-custom-class="body_text" />
                                                    </bdt>
                                                  </td>
                                                  <td
                                                    style={{
                                                      borderRight: "1px solid black",
                                                      borderBottom: "1px solid black",
                                                    }}>
                                                    <div
                                                      data-empty="true"
                                                      style={{
                                                        textAlign: "center",
                                                      }}>
                                                      <br />
                                                    </div>
                                                    <div
                                                      data-custom-class="body_text"
                                                      data-empty="true"
                                                      style={{
                                                        textAlign: "center",
                                                        lineHeight: "1.5",
                                                      }}>
                                                      <bdt className="block-component">
                                                        <span data-custom-class="body_text" />
                                                      </bdt>
                                                      NO
                                                      <bdt className="statement-end-if-in-editor">
                                                        <span data-custom-class="body_text" />
                                                      </bdt>
                                                    </div>
                                                    <div
                                                      data-empty="true"
                                                      style={{
                                                        textAlign: "center",
                                                      }}>
                                                      <br />
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span data-custom-class="body_text" />
                                              </bdt>
                                              <span data-custom-class="body_text">
                                                We will use and retain the collected personal information as needed to
                                                provide the Services or for:
                                                <bdt className="block-component" />
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span data-custom-class="body_text">
                                                  Category A -{" "}
                                                  <bdt className="question">
                                                    As long as the user has an account with us
                                                  </bdt>
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span data-custom-class="body_text">
                                                  Category B -{" "}
                                                  <bdt className="question">
                                                    As long as the user has an account with us
                                                  </bdt>
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span data-custom-class="body_text">
                                                  Category <bdt className="block-component" />
                                                  C<bdt className="else-block" /> -{" "}
                                                  <bdt className="question">
                                                    As long as the user has an account with us
                                                  </bdt>
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                              </span>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="block-component" />
                                                </span>
                                                <div style={{ lineHeight: "1.5" }}>
                                                  <span data-custom-class="body_text">
                                                    <bdt className="block-component" />
                                                  </span>
                                                  <div style={{ lineHeight: "1.5" }}>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                      <span data-custom-class="body_text">
                                                        Category <bdt className="block-component" />
                                                        G
                                                        <bdt className="else-block" /> -{" "}
                                                        <bdt className="question">
                                                          As long as the user has an account with us
                                                        </bdt>
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div style={{ lineHeight: "1.5" }}>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                    </span>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}>
                                                        <span data-custom-class="body_text">
                                                          Category <bdt className="block-component" />
                                                          I
                                                          <bdt className="else-block" /> -{" "}
                                                          <bdt className="question">
                                                            As long as the user has an account with us
                                                          </bdt>
                                                          <bdt className="statement-end-if-in-editor" />
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                      </span>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                        </span>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span data-custom-class="body_text" />
                                                          </bdt>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  We may also collect other personal information outside
                                                                  of these categories through instances where you
                                                                  interact with us in person, online, or by phone or
                                                                  mail in the context of:
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color: "rgb(89, 89, 89)",
                                                                            fontSize: 15,
                                                                          }}>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight: "1.5",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)",
                                                                  }}>
                                                                  <span data-custom-class="body_text">
                                                                    Receiving help through our customer support
                                                                    channels;
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color: "rgb(89, 89, 89)",
                                                                              fontSize: 15,
                                                                            }}>
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color: "rgb(89, 89, 89)",
                                                                                  fontSize: 15,
                                                                                }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                      }}>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color: "rgb(89, 89, 89)",
                                                                            fontSize: 15,
                                                                          }}>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight: "1.5",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)",
                                                                  }}>
                                                                  <span data-custom-class="body_text">
                                                                    Participation in customer surveys or contests; and
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color: "rgb(89, 89, 89)",
                                                                              fontSize: 15,
                                                                            }}>
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color: "rgb(89, 89, 89)",
                                                                                  fontSize: 15,
                                                                                }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                      }}>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color: "rgb(89, 89, 89)",
                                                                            fontSize: 15,
                                                                          }}>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight: "1.5",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)",
                                                                  }}>
                                                                  <span data-custom-class="body_text">
                                                                    Facilitation in the delivery of our Services and to
                                                                    respond to your inquiries.
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color: "rgb(89, 89, 89)",
                                                                              fontSize: 15,
                                                                            }}>
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color: "rgb(89, 89, 89)",
                                                                                  fontSize: 15,
                                                                                }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                      }}>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    How do we use and share your personal information?
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}>
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}>
                                                            <span
                                                              data-custom-class="body_text"
                                                              style={{
                                                                fontSize: 15,
                                                              }}>
                                                              Learn about how we use your personal information in the
                                                              section, <bdt className="block-component" />
                                                              "
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                            <a data-custom-class="link" href="#infouse">
                                                              <span
                                                                style={{
                                                                  color: "rgb(0, 58, 250)",
                                                                  fontSize: 15,
                                                                }}>
                                                                HOW DO WE PROCESS YOUR INFORMATION?
                                                              </span>
                                                            </a>
                                                            <span
                                                              data-custom-class="body_text"
                                                              style={{
                                                                fontSize: 15,
                                                              }}>
                                                              <bdt className="block-component" />"
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                data-custom-class="body_text"
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              />
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}>
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color: "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  We collect and share your personal information
                                                                  through:
                                                                  <span
                                                                    style={{
                                                                      color: "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight: "1.5",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)",
                                                                  }}>
                                                                  <span data-custom-class="body_text">
                                                                    Targeting cookies/Marketing cookies
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              color: "rgb(89, 89, 89)",
                                                                            }}>
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}>
                                                              <span
                                                                style={{
                                                                  color: "rgb(89, 89, 89)",
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <bdt className="block-component" />
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <div>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    color: "rgb(89, 89, 89)",
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            color: "rgb(89, 89, 89)",
                                                                          }}>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <ul>
                                                              <li>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)",
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span data-custom-class="body_text">
                                                                      Beacons/Pixels/Tags
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            color: "rgb(89, 89, 89)",
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                color: "rgb(89, 89, 89)",
                                                                              }}>
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}>
                                                                                <span
                                                                                  style={{
                                                                                    color: "rgb(89, 89, 89)",
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                      }}>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </li>
                                                            </ul>
                                                            <div>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    color: "rgb(89, 89, 89)",
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            color: "rgb(89, 89, 89)",
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                color: "rgb(89, 89, 89)",
                                                                              }}>
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <div>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              color: "rgb(89, 89, 89)",
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}>
                                                                              <span
                                                                                style={{
                                                                                  color: "rgb(89, 89, 89)",
                                                                                }}>
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      color: "rgb(89, 89, 89)",
                                                                                    }}>
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <div>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            color: "rgb(89, 89, 89)",
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                color: "rgb(89, 89, 89)",
                                                                              }}>
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}>
                                                                                <span
                                                                                  style={{
                                                                                    color: "rgb(89, 89, 89)",
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                      }}>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                              <bdt className="block-component" />
                                                                                            </bdt>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              color: "rgb(89, 89, 89)",
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}>
                                                                              <span
                                                                                style={{
                                                                                  color: "rgb(89, 89, 89)",
                                                                                }}>
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      color: "rgb(89, 89, 89)",
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <span
                                                                                        style={{
                                                                                          color: "rgb(89, 89, 89)",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              color: "rgb(89, 89, 89)",
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}>
                                                                              <span
                                                                                style={{
                                                                                  color: "rgb(89, 89, 89)",
                                                                                }}>
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      color: "rgb(89, 89, 89)",
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <span
                                                                                        style={{
                                                                                          color: "rgb(89, 89, 89)",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: "1.5",
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          Will your information be shared with anyone
                                                                          else?
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: "1.5",
                                                                  }}>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: "1.5",
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span data-custom-class="body_text">
                                                                        We may disclose your personal information with
                                                                        our service providers pursuant to a written
                                                                        contract between us and each service provider.
                                                                        Learn more about how we disclose personal
                                                                        information to in the section,{" "}
                                                                        <bdt className="block-component" />
                                                                        "
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <a data-custom-class="link" href="#whoshare">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(0, 58, 250)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color: "rgb(0, 58, 250)",
                                                                        }}>
                                                                        WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                                                                        INFORMATION?
                                                                      </span>
                                                                    </span>
                                                                  </a>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                        "
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: "1.5",
                                                                  }}>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: "1.5",
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: "rgb(89, 89, 89)",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span data-custom-class="body_text">
                                                                        We may use your personal information for our own
                                                                        business purposes, such as for undertaking
                                                                        internal research for technological development
                                                                        and demonstration. This is not considered to be{" "}
                                                                        <bdt className="block-component" />
                                                                        "selling"
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        of your personal information.
                                                                        <span
                                                                          style={{
                                                                            color: "rgb(89, 89, 89)",
                                                                            fontSize: 15,
                                                                          }}>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: "1.5",
                                                                  }}>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: "1.5",
                                                                  }}>
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </bdt>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color: "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          We have disclosed the following categories of
                                                                          personal information to third parties for a
                                                                          business or commercial purpose in the
                                                                          preceding twelve (12) months:
                                                                          <bdt className="forloop-component" />
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <bdt className="forloop-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                  </p>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                      lineHeight: "1.5",
                                                                    }}>
                                                                    <bdt className="forloop-component">
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </bdt>
                                                                  </p>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                      lineHeight: "1.5",
                                                                    }}>
                                                                    <bdt className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </bdt>
                                                                  </p>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color: "rgb(89, 89, 89)",
                                                                        }}>
                                                                        <bdt className="forloop-component" />
                                                                      </span>
                                                                    </span>
                                                                  </p>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                  </p>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                  </p>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                  </p>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                  </p>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                  </p>
                                                                  <p
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                  </p>
                                                                  <div
                                                                    style={{
                                                                      lineHeight: "1.5",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                    <p
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                      <bdt className="block-component" />
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              color: "rgb(89, 89, 89)",
                                                                            }}>
                                                                            <bdt className="forloop-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component" />
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <bdt className="forloop-component">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        />
                                                                      </bdt>
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}>
                                                                        <bdt className="forloop-component" />
                                                                      </span>
                                                                    </p>
                                                                    <div>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}>
                                                                        <bdt className="forloop-component" />
                                                                      </span>
                                                                      <bdt className="block-component">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        />
                                                                      </bdt>
                                                                      <bdt className="forloop-component" />
                                                                      <div>
                                                                        <bdt className="forloop-component">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          />
                                                                        </bdt>
                                                                        <div>
                                                                          <bdt className="forloop-component">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            />
                                                                          </bdt>
                                                                          <div>
                                                                            <bdt className="forloop-component">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}
                                                                              />
                                                                            </bdt>
                                                                            <div>
                                                                              <bdt className="block-component">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                />
                                                                              </bdt>
                                                                              <div>
                                                                                <bdt className="block-component" />
                                                                                <div
                                                                                  style={{
                                                                                    lineHeight: "1.5",
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color: "rgb(89, 89, 89)",
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)",
                                                                                      }}>
                                                                                      <span data-custom-class="body_text">
                                                                                        The categories of third parties
                                                                                        to whom we disclosed personal
                                                                                        information for a business or
                                                                                        commercial purpose can be found
                                                                                        under{" "}
                                                                                        <bdt className="block-component" />
                                                                                        "
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(0, 58, 250)",
                                                                                              }}>
                                                                                              <a
                                                                                                data-custom-class="link"
                                                                                                href="#whoshare">
                                                                                                WHEN AND WITH WHOM DO WE
                                                                                                SHARE YOUR PERSONAL
                                                                                                INFORMATION?
                                                                                              </a>
                                                                                            </span>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}>
                                                                                                            <bdt className="block-component" />
                                                                                                            "
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                }}>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                  }}>
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                    }}>
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                      }}>
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        }}>
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15,
                                                                                                                          }}>
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                            }}>
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                  }}>
                                                                                                                                  <span
                                                                                                                                    style={{
                                                                                                                                      color:
                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                    }}>
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                      }}>
                                                                                                                                      <span
                                                                                                                                        style={{
                                                                                                                                          color:
                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                        }}>
                                                                                                                                        <span
                                                                                                                                          style={{
                                                                                                                                            fontSize: 15,
                                                                                                                                          }}>
                                                                                                                                          <span
                                                                                                                                            style={{
                                                                                                                                              color:
                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                            }}>
                                                                                                                                            <span
                                                                                                                                              style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                              }}>
                                                                                                                                              <span
                                                                                                                                                style={{
                                                                                                                                                  color:
                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                }}>
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                                                </span>
                                                                                                                                              </span>
                                                                                                                                            </span>
                                                                                                                                          </span>
                                                                                                                                        </span>
                                                                                                                                      </span>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </bdt>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color: "rgb(0, 0, 0)",
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                                <div
                                                                                  style={{
                                                                                    lineHeight: "1.5",
                                                                                  }}>
                                                                                  <br />
                                                                                </div>
                                                                                <div
                                                                                  style={{
                                                                                    lineHeight: "1.5",
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                      }}>
                                                                                      <span
                                                                                        style={{
                                                                                          color: "rgb(89, 89, 89)",
                                                                                        }}>
                                                                                        <span data-custom-class="body_text">
                                                                                          We have sold or shared the
                                                                                          following categories of
                                                                                          personal information to third
                                                                                          parties in the preceding
                                                                                          twelve (12) months:
                                                                                          <bdt className="forloop-component" />
                                                                                          <bdt className="block-component" />
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                    <span data-custom-class="body_text">
                                                                                      <span
                                                                                        style={{
                                                                                          color: "rgb(89, 89, 89)",
                                                                                        }}>
                                                                                        <bdt className="forloop-component" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                  <div
                                                                                    style={{
                                                                                      lineHeight: "1.5",
                                                                                    }}>
                                                                                    <bdt className="forloop-component">
                                                                                      <span data-custom-class="body_text" />
                                                                                    </bdt>
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <bdt className="forloop-component" />
                                                                                      <bdt className="block-component" />
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <bdt className="forloop-component" />
                                                                                        </span>
                                                                                      </span>
                                                                                    </p>
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <bdt className="forloop-component" />
                                                                                    </p>
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <bdt className="forloop-component" />
                                                                                    </p>
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <bdt className="forloop-component" />
                                                                                    </p>
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <bdt className="forloop-component" />
                                                                                      <bdt className="block-component" />
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <bdt className="forloop-component" />
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </p>
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <bdt className="forloop-component" />
                                                                                    </p>
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <bdt className="forloop-component" />
                                                                                      <bdt className="block-component" />
                                                                                      <bdt className="forloop-component" />
                                                                                    </p>
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <bdt className="forloop-component" />
                                                                                    </p>
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}>
                                                                                      <bdt className="block-component" />
                                                                                    </p>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight: "1.5",
                                                                                      }}>
                                                                                      <bdt className="block-component" />
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              The categories of third
                                                                                              parties to whom we sold
                                                                                              personal information are:
                                                                                              <bdt className="block-component" />
                                                                                              <bdt className="forloop-component" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                        <span
                                                                                          style={{
                                                                                            color: "rgb(0, 0, 0)",
                                                                                          }}>
                                                                                          <bdt className="block-component">
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="block-component" />
                                                                                            </span>
                                                                                          </bdt>
                                                                                        </span>
                                                                                        <bdt className="block-component">
                                                                                          <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                          <bdt className="block-component">
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="block-component" />
                                                                                            </span>
                                                                                            <bdt className="block-component">
                                                                                              <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                              </span>
                                                                                              <bdt className="block-component">
                                                                                                <span data-custom-class="body_text">
                                                                                                  <bdt className="block-component" />
                                                                                                </span>
                                                                                                <span data-custom-class="body_text" />
                                                                                                <span data-custom-class="body_text" />
                                                                                                <span data-custom-class="body_text" />
                                                                                                <bdt className="block-component">
                                                                                                  <span data-custom-class="body_text" />
                                                                                                </bdt>
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(0, 0, 0)",
                                                                                                    }}>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}>
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                                <bdt className="block-component" />
                                                                                              </bdt>
                                                                                            </bdt>
                                                                                          </bdt>
                                                                                        </bdt>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <strong>
                                                                                          <span
                                                                                            data-custom-class="heading_2"
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            California Residents
                                                                                          </span>
                                                                                        </strong>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          data-custom-class="body_text"
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          California Civil Code Section
                                                                                          1798.83, also known as the{" "}
                                                                                          <bdt className="block-component" />
                                                                                          "Shine The Light"
                                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                                          law permits our users who are
                                                                                          California residents to
                                                                                          request and obtain from us,
                                                                                          once a year and free of
                                                                                          charge, information about
                                                                                          categories of personal
                                                                                          information (if any) we
                                                                                          disclosed to third parties for
                                                                                          direct marketing purposes and
                                                                                          the names and addresses of all
                                                                                          third parties with which we
                                                                                          shared personal information in
                                                                                          the immediately preceding
                                                                                          calendar year. If you are a
                                                                                          California resident and would
                                                                                          like to make such a request,
                                                                                          please submit your request in
                                                                                          writing to us using the
                                                                                          contact information provided
                                                                                          below.
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <br />
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          data-custom-class="body_text"
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          If you are under 18 years of
                                                                                          age, reside in California, and
                                                                                          have a registered account with
                                                                                          the Services, you have the
                                                                                          right to request removal of
                                                                                          unwanted data that you
                                                                                          publicly post on the Services.
                                                                                          To request removal of such
                                                                                          data, please contact us using
                                                                                          the contact information
                                                                                          provided below and include the
                                                                                          email address associated with
                                                                                          your account and a statement
                                                                                          that you reside in California.
                                                                                          We will make sure the data is
                                                                                          not publicly displayed on the
                                                                                          Services, but please be aware
                                                                                          that the data may not be
                                                                                          completely or comprehensively
                                                                                          removed from all our systems
                                                                                          (e.g.
                                                                                          <bdt className="block-component" />
                                                                                          ,
                                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                                          backups, etc.).
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <br />
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <strong>
                                                                                              <u>CCPA Privacy Notice</u>
                                                                                            </strong>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <br />
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            This section applies only to
                                                                                            California residents. Under
                                                                                            the California Consumer
                                                                                            Privacy Act (CCPA), you have
                                                                                            the rights listed below.
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <br />
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            The California Code of
                                                                                            Regulations defines a{" "}
                                                                                            <bdt className="block-component" />
                                                                                            "residents"
                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                            as:
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <br />
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          marginLeft: 20,
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            (1) every individual who is
                                                                                            in the State of California
                                                                                            for other than a temporary
                                                                                            or transitory purpose and
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          marginLeft: 20,
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            (2) every individual who is
                                                                                            domiciled in the State of
                                                                                            California who is outside
                                                                                            the State of California for
                                                                                            a temporary or transitory
                                                                                            purpose
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <br />
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            All other individuals are
                                                                                            defined as{" "}
                                                                                            <bdt className="block-component" />
                                                                                            "non-residents."
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <br />
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            If this definition of{" "}
                                                                                            <bdt className="block-component" />
                                                                                            "resident"
                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                            applies to you, we must
                                                                                            adhere to certain rights and
                                                                                            obligations regarding your
                                                                                            personal information.
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <strong>
                                                                                                Your rights with respect
                                                                                                to your personal data
                                                                                              </strong>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <u>
                                                                                                Right to request
                                                                                                deletion of the data —
                                                                                                Request to delete
                                                                                              </u>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              You can ask for the
                                                                                              deletion of your personal
                                                                                              information. If you ask us
                                                                                              to delete your personal
                                                                                              information, we will
                                                                                              respect your request and
                                                                                              delete your personal
                                                                                              information, subject to
                                                                                              certain exceptions
                                                                                              provided by law, such as
                                                                                              (but not limited to) the
                                                                                              exercise by another
                                                                                              consumer of his or her
                                                                                              right to free speech, our
                                                                                              compliance requirements
                                                                                              resulting from a legal
                                                                                              obligation, or any
                                                                                              processing that may be
                                                                                              required to protect
                                                                                              against illegal
                                                                                              activities.
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <u>
                                                                                                Right to be informed —
                                                                                                Request to know
                                                                                              </u>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              Depending on the
                                                                                              circumstances, you have a
                                                                                              right to know:
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                whether we collect and
                                                                                                use your personal
                                                                                                information;
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 0, 0)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                the categories of
                                                                                                personal information
                                                                                                that we collect;
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 0, 0)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                the purposes for which
                                                                                                the collected personal
                                                                                                information is used;
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 0, 0)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                whether we sell or share
                                                                                                personal information to
                                                                                                third parties;
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 0, 0)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                the categories of
                                                                                                personal information
                                                                                                that we sold, shared, or
                                                                                                disclosed for a business
                                                                                                purpose;
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 0, 0)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                the categories of third
                                                                                                parties to whom the
                                                                                                personal information was
                                                                                                sold, shared, or
                                                                                                disclosed for a business
                                                                                                purpose;
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 0, 0)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                the business or
                                                                                                commercial purpose for
                                                                                                collecting, selling, or
                                                                                                sharing personal
                                                                                                information; and
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 0, 0)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <bdt className="block-component">
                                                                                          <span data-custom-class="body_text" />
                                                                                        </bdt>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            the specific pieces of
                                                                                            personal information we
                                                                                            collected about you.
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              In accordance with
                                                                                              applicable law, we are not
                                                                                              obligated to provide or
                                                                                              delete consumer
                                                                                              information that is
                                                                                              de-identified in response
                                                                                              to a consumer request or
                                                                                              to re-identify individual
                                                                                              data to verify a consumer
                                                                                              request.
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <u>
                                                                                                Right to
                                                                                                Non-Discrimination for
                                                                                                the Exercise of a
                                                                                                Consumer’s Privacy
                                                                                                Rights
                                                                                              </u>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              We will not discriminate
                                                                                              against you if you
                                                                                              exercise your privacy
                                                                                              rights.
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}>
                                                                                          <u>
                                                                                            <span data-custom-class="body_text">
                                                                                              Right to Limit Use and
                                                                                              Disclosure of Sensitive
                                                                                              Personal Information
                                                                                            </span>
                                                                                          </u>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <bdt className="block-component">
                                                                                          <span data-custom-class="body_text" />
                                                                                        </bdt>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span data-custom-class="body_text">
                                                                                          We do not process consumer's
                                                                                          sensitive personal
                                                                                          information.
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                          <span data-custom-class="body_text" />
                                                                                        </bdt>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <u>
                                                                                                Verification process
                                                                                              </u>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              Upon receiving your
                                                                                              request, we will need to
                                                                                              verify your identity to
                                                                                              determine you are the same
                                                                                              person about whom we have
                                                                                              the information in our
                                                                                              system. These verification
                                                                                              efforts require us to ask
                                                                                              you to provide information
                                                                                              so that we can match it
                                                                                              with information you have
                                                                                              previously provided us.
                                                                                              For instance, depending on
                                                                                              the type of request you
                                                                                              submit, we may ask you to
                                                                                              provide certain
                                                                                              information so that we can
                                                                                              match the information you
                                                                                              provide with the
                                                                                              information we already
                                                                                              have on file, or we may
                                                                                              contact you through a
                                                                                              communication method (e.g.
                                                                                              <bdt className="block-component" />
                                                                                              ,
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              phone or email) that you
                                                                                              have previously provided
                                                                                              to us. We may also use
                                                                                              other verification methods
                                                                                              as the circumstances
                                                                                              dictate.
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              We will only use personal
                                                                                              information provided in
                                                                                              your request to verify
                                                                                              your identity or authority
                                                                                              to make the request. To
                                                                                              the extent possible, we
                                                                                              will avoid requesting
                                                                                              additional information
                                                                                              from you for the purposes
                                                                                              of verification. However,
                                                                                              if we cannot verify your
                                                                                              identity from the
                                                                                              information already
                                                                                              maintained by us, we may
                                                                                              request that you provide
                                                                                              additional information for
                                                                                              the purposes of verifying
                                                                                              your identity and for
                                                                                              security or
                                                                                              fraud-prevention purposes.
                                                                                              We will delete such
                                                                                              additionally provided
                                                                                              information as soon as we
                                                                                              finish verifying you.
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight: "1.5",
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <u>
                                                                                                Other privacy rights
                                                                                              </u>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                You may object to the
                                                                                                processing of your
                                                                                                personal information.
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(0, 0, 0)",
                                                                                                              }}>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                }}>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                You may request
                                                                                                correction of your
                                                                                                personal data if it is
                                                                                                incorrect or no longer
                                                                                                relevant, or ask to
                                                                                                restrict the processing
                                                                                                of the information.
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(0, 0, 0)",
                                                                                                              }}>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                }}>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                You can designate an{" "}
                                                                                                <bdt className="block-component" />
                                                                                                authorized
                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                agent to make a request
                                                                                                under the CCPA on your
                                                                                                behalf. We may deny a
                                                                                                request from an{" "}
                                                                                                <bdt className="block-component" />
                                                                                                authorized
                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                agent that does not
                                                                                                submit proof that they
                                                                                                have been validly{" "}
                                                                                                <bdt className="block-component" />
                                                                                                authorized
                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                to act on your behalf in
                                                                                                accordance with the
                                                                                                CCPA.
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0, 0, 0)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(0, 0, 0)",
                                                                                                              }}>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                }}>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                          </bdt>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              You can opt out from the
                                                                                              selling or sharing of your
                                                                                              personal information by
                                                                                              disabling cookies in
                                                                                              Cookie Preference Settings
                                                                                              and clicking on the Do Not
                                                                                              Sell or Share My Personal
                                                                                              Information link on our
                                                                                              homepage.
                                                                                            </span>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                To exercise these
                                                                                                rights, you can contact
                                                                                                us{" "}
                                                                                                <bdt className="block-component" />
                                                                                                by submitting a{" "}
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                          <a
                                                                                            data-custom-class="link"
                                                                                            href="https://app.termly.io/notify/7b604b77-6008-4b21-bfaf-646f17fe595a"
                                                                                            rel="noopener noreferrer"
                                                                                            target="_blank">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(0, 58, 250)",
                                                                                              }}>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(0, 58, 250)",
                                                                                                }}>
                                                                                                data subject access
                                                                                                request
                                                                                              </span>
                                                                                            </span>
                                                                                          </a>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                ,{" "}
                                                                                                <bdt className="block-component" />
                                                                                              </span>
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                  fontSize: 15,
                                                                                                }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <bdt className="block-component" />
                                                                                                      by email at{" "}
                                                                                                      <bdt className="question">
                                                                                                        hello@pare-ai.com
                                                                                                      </bdt>
                                                                                                      ,{" "}
                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                      <bdt className="block-component" />
                                                                                                    </span>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <bdt className="block-component" />
                                                                                                      <bdt className="block-component">
                                                                                                        <span data-custom-class="body_text" />
                                                                                                      </bdt>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                          <span data-custom-class="body_text">
                                                                                            or by referring to the
                                                                                            contact details at the
                                                                                            bottom of this document. If
                                                                                            you have a complaint about
                                                                                            how we handle your data, we
                                                                                            would like to hear from you.
                                                                                          </span>
                                                                                          <span
                                                                                            style={{
                                                                                              color: "rgb(89, 89, 89)",
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="block-component">
                                                                                                <bdt className="block-component" />
                                                                                              </bdt>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                          </bdt>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <strong>
                                                                                              <span data-custom-class="heading_2">
                                                                                                Colorado Residents
                                                                                              </span>
                                                                                            </strong>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              This section applies only
                                                                                              to Colorado residents.
                                                                                              Under the Colorado Privacy
                                                                                              Act (CPA), you have the
                                                                                              rights listed below.
                                                                                              However, these rights are
                                                                                              not absolute, and in
                                                                                              certain cases, we may
                                                                                              decline your request as
                                                                                              permitted by law.
                                                                                            </span>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to be informed
                                                                                                whether or not we are
                                                                                                processing your personal
                                                                                                data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to access your
                                                                                                personal data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component" />
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to correct
                                                                                                inaccuracies in your
                                                                                                personal data
                                                                                              </span>
                                                                                            </span>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component" />
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to request
                                                                                                deletion of your
                                                                                                personal data
                                                                                              </span>
                                                                                            </span>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to obtain a copy
                                                                                                of the personal data you
                                                                                                previously shared with
                                                                                                us
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component" />
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to opt out of the
                                                                                                processing of your
                                                                                                personal data if it is
                                                                                                used for targeted
                                                                                                advertising, the sale of
                                                                                                personal data, or
                                                                                                profiling in furtherance
                                                                                                of decisions that
                                                                                                produce legal or
                                                                                                similarly significant
                                                                                                effects (
                                                                                                <bdt className="block-component" />
                                                                                                "profiling"
                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                )
                                                                                              </span>
                                                                                            </span>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          data-custom-class="body_text"
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component">
                                                                                            <span data-custom-class="body_text" />
                                                                                          </bdt>
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              We sell personal data to
                                                                                              third parties or process
                                                                                              personal data for targeted
                                                                                              advertising. You can opt
                                                                                              out from the selling of
                                                                                              your personal data,
                                                                                              targeted advertising, or
                                                                                              profiling by disabling
                                                                                              cookies in Cookie
                                                                                              Preference Settings.
                                                                                            </span>{" "}
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              To submit a request to
                                                                                              exercise
                                                                                            </span>{" "}
                                                                                            <bdt
                                                                                              className="block-component"
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              any of the other
                                                                                            </span>{" "}
                                                                                            <bdt
                                                                                              className="else-block"
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              rights described above,
                                                                                              please{" "}
                                                                                              <bdt className="block-component" />
                                                                                              email
                                                                                            </span>{" "}
                                                                                            <bdt
                                                                                              className="question"
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              hello@pare-ai.com
                                                                                            </bdt>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              {" "}
                                                                                              or{" "}
                                                                                            </span>
                                                                                            <bdt
                                                                                              className="block-component"
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              submit a{" "}
                                                                                            </span>
                                                                                            <a
                                                                                              data-custom-class="link"
                                                                                              href="https://app.termly.io/notify/7b604b77-6008-4b21-bfaf-646f17fe595a"
                                                                                              rel="noopener noreferrer"
                                                                                              target="_blank">
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 58, 250)",
                                                                                                  fontSize: 15,
                                                                                                }}>
                                                                                                <span data-custom-class="link">
                                                                                                  data subject access
                                                                                                  request
                                                                                                </span>
                                                                                              </span>
                                                                                            </a>
                                                                                            <bdt
                                                                                              className="block-component"
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                          </span>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              .
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              If we decline to take
                                                                                              action regarding your
                                                                                              request and you wish to
                                                                                              appeal our decision,
                                                                                              please email us at{" "}
                                                                                              <bdt className="block-component" />
                                                                                              <bdt className="question">
                                                                                                hello@pare-ai.com
                                                                                              </bdt>
                                                                                              <bdt className="else-block" />
                                                                                              . Within forty-five (45)
                                                                                              days of receipt of an
                                                                                              appeal, we will inform you
                                                                                              in writing of any action
                                                                                              taken or not taken in
                                                                                              response to the appeal,
                                                                                              including a written
                                                                                              explanation of the reasons
                                                                                              for the decisions.
                                                                                            </span>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component" />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <strong>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="heading_2">
                                                                                                Connecticut Residents
                                                                                              </span>
                                                                                            </span>
                                                                                          </strong>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              This section applies only
                                                                                              to Connecticut residents.
                                                                                              Under the Connecticut Data
                                                                                              Privacy Act (CTDPA), you
                                                                                              have the rights listed
                                                                                              below. However, these
                                                                                              rights are not absolute,
                                                                                              and in certain cases, we
                                                                                              may decline your request
                                                                                              as permitted by law.
                                                                                            </span>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to be informed
                                                                                                whether or not we are
                                                                                                processing your personal
                                                                                                data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component" />
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              Right to access your
                                                                                              personal data
                                                                                            </span>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to correct
                                                                                                inaccuracies in your
                                                                                                personal data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component" />
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to request
                                                                                                deletion of your
                                                                                                personal data
                                                                                              </span>
                                                                                            </span>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to obtain a copy
                                                                                                of the personal data you
                                                                                                previously shared with
                                                                                                us
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component" />
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to opt out of the
                                                                                                processing of your
                                                                                                personal data if it is
                                                                                                used for targeted
                                                                                                advertising, the sale of
                                                                                                personal data, or
                                                                                                profiling in furtherance
                                                                                                of decisions that
                                                                                                produce legal or
                                                                                                similarly significant
                                                                                                effects (
                                                                                                <bdt className="block-component" />
                                                                                                "profiling"
                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                )
                                                                                              </span>
                                                                                            </span>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          data-custom-class="body_text"
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <bdt className="block-component">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text" />
                                                                                            </span>
                                                                                          </bdt>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              We sell personal data to
                                                                                              third parties or process
                                                                                              personal data for targeted
                                                                                              advertising. You can opt
                                                                                              out from the selling of
                                                                                              your personal data,
                                                                                              targeted advertising, or
                                                                                              profiling by disabling
                                                                                              cookies in Cookie
                                                                                              Preference Settings.{" "}
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                              To submit a request to
                                                                                              exercise{" "}
                                                                                              <bdt className="block-component" />
                                                                                              any of the other
                                                                                              <bdt className="else-block" />{" "}
                                                                                              rights described above,
                                                                                              please{" "}
                                                                                              <bdt className="block-component" />
                                                                                              email{" "}
                                                                                              <bdt className="question">
                                                                                                hello@pare-ai.com
                                                                                              </bdt>{" "}
                                                                                              or{" "}
                                                                                              <bdt className="block-component" />
                                                                                              submit a{" "}
                                                                                              <a
                                                                                                data-custom-class="link"
                                                                                                href="https://app.termly.io/notify/7b604b77-6008-4b21-bfaf-646f17fe595a"
                                                                                                rel="noopener noreferrer"
                                                                                                target="_blank">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 58, 250)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="link">
                                                                                                    data subject access
                                                                                                    request
                                                                                                  </span>
                                                                                                </span>
                                                                                              </a>
                                                                                              <bdt className="block-component" />
                                                                                              .
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              If we decline to take
                                                                                              action regarding your
                                                                                              request and you wish to
                                                                                              appeal our decision,
                                                                                              please email us at{" "}
                                                                                              <bdt className="block-component" />
                                                                                              <bdt className="question">
                                                                                                hello@pare-ai.com
                                                                                              </bdt>
                                                                                              <bdt className="else-block" />
                                                                                              . Within sixty (60) days
                                                                                              of receipt of an appeal,
                                                                                              we will inform you in
                                                                                              writing of any action
                                                                                              taken or not taken in
                                                                                              response to the appeal,
                                                                                              including a written
                                                                                              explanation of the reasons
                                                                                              for the decisions.
                                                                                            </span>
                                                                                          </span>
                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                          <bdt className="block-component">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                          </bdt>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <strong>
                                                                                              <span data-custom-class="heading_2">
                                                                                                Utah Residents
                                                                                              </span>
                                                                                            </strong>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              This section applies only
                                                                                              to Utah residents. Under
                                                                                              the Utah Consumer Privacy
                                                                                              Act (UCPA), you have the
                                                                                              rights listed below.
                                                                                              However, these rights are
                                                                                              not absolute, and in
                                                                                              certain cases, we may
                                                                                              decline your request as
                                                                                              permitted by law.
                                                                                            </span>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.4",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to be informed
                                                                                                whether or not we are
                                                                                                processing your personal
                                                                                                data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.4",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to access your
                                                                                                personal data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.4",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to request
                                                                                                deletion of your
                                                                                                personal data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.4",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to obtain a copy
                                                                                                of the personal data you
                                                                                                previously shared with
                                                                                                us
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.4",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to opt out of the
                                                                                                processing of your
                                                                                                personal data if it is
                                                                                                used for targeted
                                                                                                advertising or the sale
                                                                                                of personal data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          data-custom-class="body_text"
                                                                                          style={{
                                                                                            lineHeight: "1.4",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component">
                                                                                              <span data-custom-class="body_text" />
                                                                                            </bdt>
                                                                                            <span data-custom-class="body_text">
                                                                                              We sell personal data to
                                                                                              third parties or process
                                                                                              personal data for targeted
                                                                                              advertising. You can opt
                                                                                              out from the selling of
                                                                                              your personal data or
                                                                                              targeted advertising by
                                                                                              disabling cookies in
                                                                                              Cookie Preference
                                                                                              Settings.{" "}
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                              To submit a request to
                                                                                              exercise{" "}
                                                                                              <bdt className="block-component" />
                                                                                              any of the other
                                                                                              <bdt className="else-block" />{" "}
                                                                                              rights described above,
                                                                                              please{" "}
                                                                                              <bdt className="block-component" />
                                                                                              email{" "}
                                                                                              <bdt className="question">
                                                                                                hello@pare-ai.com
                                                                                              </bdt>{" "}
                                                                                              or{" "}
                                                                                              <bdt className="block-component" />
                                                                                              submit a{" "}
                                                                                              <a
                                                                                                data-custom-class="link"
                                                                                                href="https://app.termly.io/notify/7b604b77-6008-4b21-bfaf-646f17fe595a"
                                                                                                rel="noopener noreferrer"
                                                                                                target="_blank">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 58, 250)",
                                                                                                  }}>
                                                                                                  data subject access
                                                                                                  request
                                                                                                </span>
                                                                                              </a>
                                                                                              <bdt className="block-component" />
                                                                                              .
                                                                                            </span>
                                                                                          </span>
                                                                                          <bdt className="statement-end-if-in-editor">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                          </bdt>
                                                                                          <bdt className="block-component">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                          </bdt>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <strong>
                                                                                              <span data-custom-class="heading_2">
                                                                                                Virginia Residents
                                                                                              </span>
                                                                                            </strong>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              Under the Virginia
                                                                                              Consumer Data Protection
                                                                                              Act (VCDPA):
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          data-custom-class="body_text"
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component">
                                                                                              <span data-custom-class="body_text" />
                                                                                            </bdt>
                                                                                            "Consumer"
                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                            means a natural person who
                                                                                            is a resident of the
                                                                                            Commonwealth acting only in
                                                                                            an individual or household
                                                                                            context. It does not include
                                                                                            a natural person acting in a
                                                                                            commercial or employment
                                                                                            context.
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          data-custom-class="body_text"
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component">
                                                                                              <span data-custom-class="body_text" />
                                                                                            </bdt>
                                                                                            "Personal data"
                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                            means any information that
                                                                                            is linked or reasonably
                                                                                            linkable to an identified or
                                                                                            identifiable natural person.{" "}
                                                                                            <bdt className="block-component" />
                                                                                            "Personal data"
                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                            does not include
                                                                                            de-identified data or
                                                                                            publicly available
                                                                                            information.
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          data-custom-class="body_text"
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component">
                                                                                              <span data-custom-class="body_text" />
                                                                                            </bdt>
                                                                                            "Sale of personal data"
                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                            means the exchange of
                                                                                            personal data for monetary
                                                                                            consideration.
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              If this definition of{" "}
                                                                                              <bdt className="block-component" />
                                                                                              "consumer"
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              applies to you, we must
                                                                                              adhere to certain rights
                                                                                              and obligations regarding
                                                                                              your personal data.
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <u>
                                                                                                Your rights with respect
                                                                                                to your personal data
                                                                                              </u>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to be informed
                                                                                                whether or not we are
                                                                                                processing your personal
                                                                                                data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to access your
                                                                                                personal data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to correct
                                                                                                inaccuracies in your
                                                                                                personal data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to request
                                                                                                deletion of your
                                                                                                personal data
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to obtain a copy
                                                                                                of the personal data you
                                                                                                previously shared with
                                                                                                us
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                          <li
                                                                                            style={{
                                                                                              lineHeight: "1.5",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                Right to opt out of the
                                                                                                processing of your
                                                                                                personal data if it is
                                                                                                used for targeted
                                                                                                advertising, the sale of
                                                                                                personal data, or
                                                                                                profiling in furtherance
                                                                                                of decisions that
                                                                                                produce legal or
                                                                                                similarly significant
                                                                                                effects (
                                                                                                <bdt className="block-component" />
                                                                                                "profiling"
                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                )
                                                                                              </span>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </li>
                                                                                        </ul>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              We sell personal data to
                                                                                              third parties or process
                                                                                              personal data for targeted
                                                                                              advertising. Please see
                                                                                              the following section to
                                                                                              find out how you can opt
                                                                                              out from further selling
                                                                                              or sharing of your
                                                                                              personal data for targeted
                                                                                              advertising or profiling
                                                                                              purposes.
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <u>
                                                                                              <span data-custom-class="body_text">
                                                                                                Exercise your rights
                                                                                                provided under the
                                                                                                Virginia VCDPA
                                                                                              </span>
                                                                                            </u>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="block-component" />
                                                                                            <span data-custom-class="body_text">
                                                                                              You can opt out from the
                                                                                              selling of your personal
                                                                                              data, targeted
                                                                                              advertising, or profiling
                                                                                              by disabling cookies in
                                                                                              Cookie Preference
                                                                                              Settings.{" "}
                                                                                            </span>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                            <span data-custom-class="body_text">
                                                                                              You may{" "}
                                                                                              <bdt className="block-component" />
                                                                                              contact us by email at{" "}
                                                                                              <bdt className="question">
                                                                                                hello@pare-ai.com
                                                                                              </bdt>{" "}
                                                                                              or{" "}
                                                                                              <bdt className="block-component" />
                                                                                              submit a{" "}
                                                                                              <a
                                                                                                data-custom-class="link"
                                                                                                href="https://app.termly.io/notify/7b604b77-6008-4b21-bfaf-646f17fe595a"
                                                                                                rel="noopener noreferrer"
                                                                                                target="_blank">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 58, 250)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="link">
                                                                                                    data subject access
                                                                                                    request
                                                                                                  </span>
                                                                                                </span>
                                                                                              </a>
                                                                                              <bdt className="block-component" />
                                                                                              .
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              If you are using an{" "}
                                                                                              <bdt className="block-component" />
                                                                                              authorized
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              agent to exercise your
                                                                                              rights, we may deny a
                                                                                              request if the{" "}
                                                                                              <bdt className="block-component" />
                                                                                              authorized
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              agent does not submit
                                                                                              proof that they have been
                                                                                              validly{" "}
                                                                                              <bdt className="block-component" />
                                                                                              authorized
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              to act on your behalf.
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <u>
                                                                                              <span data-custom-class="body_text">
                                                                                                Verification process
                                                                                              </span>
                                                                                            </u>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              We may request that you
                                                                                              provide additional
                                                                                              information reasonably
                                                                                              necessary to verify you
                                                                                              and your consumer's
                                                                                              request. If you submit the
                                                                                              request through an{" "}
                                                                                              <bdt className="block-component" />
                                                                                              authorized
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              agent, we may need to
                                                                                              collect additional
                                                                                              information to verify your
                                                                                              identity before processing
                                                                                              your request.
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              Upon receiving your
                                                                                              request, we will respond
                                                                                              without undue delay, but
                                                                                              in all cases, within
                                                                                              forty-five (45) days of
                                                                                              receipt. The response
                                                                                              period may be extended
                                                                                              once by forty-five (45)
                                                                                              additional days when
                                                                                              reasonably necessary. We
                                                                                              will inform you of any
                                                                                              such extension within the
                                                                                              initial 45-day response
                                                                                              period, together with the
                                                                                              reason for the extension.
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <u>Right to appeal</u>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <br />
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              If we decline to take
                                                                                              action regarding your
                                                                                              request, we will inform
                                                                                              you of our decision and
                                                                                              reasoning behind it. If
                                                                                              you wish to appeal our
                                                                                              decision, please email us
                                                                                              at{" "}
                                                                                              <bdt className="block-component" />
                                                                                              <bdt className="question">
                                                                                                hello@pare-ai.com
                                                                                              </bdt>
                                                                                              <bdt className="else-block" />
                                                                                              . Within sixty (60) days
                                                                                              of receipt of an appeal,
                                                                                              we will inform you in
                                                                                              writing of any action
                                                                                              taken or not taken in
                                                                                              response to the appeal,
                                                                                              including a written
                                                                                              explanation of the reasons
                                                                                              for the decisions. If your
                                                                                              appeal is denied, you may
                                                                                              contact the{" "}
                                                                                              <a
                                                                                                data-custom-class="link"
                                                                                                href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                                                                                                rel="noopener noreferrer"
                                                                                                target="_blank">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0, 58, 250)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="link">
                                                                                                    Attorney General to
                                                                                                    submit a complaint
                                                                                                  </span>
                                                                                                </span>
                                                                                              </a>
                                                                                              .
                                                                                            </span>
                                                                                          </span>
                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                  fontSize: 15,
                                                                                                }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                          fontSize: 15,
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15,
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                    }}>
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                      }}>
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                          fontSize: 15,
                                                                                                                        }}>
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15,
                                                                                                                            }}>
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  color:
                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                  fontSize: 15,
                                                                                                                                }}>
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                  <span
                                                                                                                                    style={{
                                                                                                                                      color:
                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                      fontSize: 15,
                                                                                                                                    }}>
                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                      <bdt className="statement-end-if-in-editor">
                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                      </bdt>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </bdt>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                          <bdt className="block-component">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                          </bdt>
                                                                                          <bdt className="block-component">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            />
                                                                                          </bdt>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          id="policyupdates"
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(127, 127, 127)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    id="control"
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(0, 0, 0)",
                                                                                                    }}>
                                                                                                    <strong>
                                                                                                      <span data-custom-class="heading_1">
                                                                                                        12. DO WE MAKE
                                                                                                        UPDATES TO THIS
                                                                                                        NOTICE?
                                                                                                      </span>
                                                                                                    </strong>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                <em>
                                                                                                  <strong>
                                                                                                    In Short:{" "}
                                                                                                  </strong>
                                                                                                  Yes, we will update
                                                                                                  this notice as
                                                                                                  necessary to stay
                                                                                                  compliant with
                                                                                                  relevant laws.
                                                                                                </em>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                We may update this
                                                                                                privacy notice from time
                                                                                                to time. The updated
                                                                                                version will be
                                                                                                indicated by an updated{" "}
                                                                                                <bdt className="block-component" />
                                                                                                "Revised"
                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                date and the updated
                                                                                                version will be
                                                                                                effective as soon as it
                                                                                                is accessible. If we
                                                                                                make material changes to
                                                                                                this privacy notice, we
                                                                                                may notify you either by
                                                                                                prominently posting a
                                                                                                notice of such changes
                                                                                                or by directly sending
                                                                                                you a notification. We
                                                                                                encourage you to review
                                                                                                this privacy notice
                                                                                                frequently to be
                                                                                                informed of how we are
                                                                                                protecting your
                                                                                                information.
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          id="contact"
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(127, 127, 127)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    id="control"
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(0, 0, 0)",
                                                                                                    }}>
                                                                                                    <strong>
                                                                                                      <span data-custom-class="heading_1">
                                                                                                        13. HOW CAN YOU
                                                                                                        CONTACT US ABOUT
                                                                                                        THIS NOTICE?
                                                                                                      </span>
                                                                                                    </strong>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                If you have questions or
                                                                                                comments about this
                                                                                                notice, you may{" "}
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                    fontSize: 15,
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component">
                                                                                                      <bdt className="block-component" />
                                                                                                    </bdt>
                                                                                                    email us at{" "}
                                                                                                    <bdt className="question">
                                                                                                      hello@pare-ai.com
                                                                                                      or{" "}
                                                                                                    </bdt>
                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                      <bdt className="block-component" />
                                                                                                    </bdt>
                                                                                                  </span>
                                                                                                </span>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      contact us by post
                                                                                                      at:
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <bdt className="question">
                                                                                                          Zeno AI
                                                                                                          Solutions Ltd.
                                                                                                        </bdt>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="question">
                                                                                                14126 Marquesas Way APT
                                                                                                3224
                                                                                              </bdt>
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}>
                                                                                                  <bdt className="block-component" />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="question">
                                                                                                Marina Del Ray
                                                                                              </bdt>
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}>
                                                                                                  <bdt className="block-component" />
                                                                                                  <bdt className="block-component" />
                                                                                                  ,{" "}
                                                                                                  <bdt className="question">
                                                                                                    CA
                                                                                                  </bdt>
                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                  <bdt className="block-component" />
                                                                                                  <bdt className="block-component" />{" "}
                                                                                                  <bdt className="question">
                                                                                                    90292
                                                                                                  </bdt>
                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                  <bdt className="block-component" />
                                                                                                  <bdt className="block-component" />
                                                                                                  <bdt className="block-component" />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            data-custom-class="body_text"
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}>
                                                                                            <bdt className="question">
                                                                                              United States
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)",
                                                                                                              }}>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                }}>
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </bdt>
                                                                                          </span>
                                                                                          <bdt className="block-component">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text" />
                                                                                            </span>
                                                                                          </bdt>
                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}>
                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <bdt className="statement-end-if-in-editor">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <bdt className="block-component">
                                                                                                            <bdt className="block-component" />
                                                                                                          </bdt>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </bdt>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <bdt className="block-component">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              />
                                                                                            </bdt>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                    fontSize: 15,
                                                                                                  }}>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <bdt className="statement-end-if-in-editor">
                                                                                                        <bdt className="block-component" />
                                                                                                      </bdt>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          id="request"
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(127, 127, 127)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize: 15,
                                                                                              }}>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}>
                                                                                                  <span
                                                                                                    id="control"
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(0, 0, 0)",
                                                                                                    }}>
                                                                                                    <strong>
                                                                                                      <span data-custom-class="heading_1">
                                                                                                        14. HOW CAN YOU
                                                                                                        REVIEW, UPDATE,
                                                                                                        OR DELETE THE
                                                                                                        DATA WE COLLECT
                                                                                                        FROM YOU?
                                                                                                      </span>
                                                                                                    </strong>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <br />
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight: "1.5",
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color: "rgb(89, 89, 89)",
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}>
                                                                                              <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                                Based on the applicable
                                                                                                laws of your country,
                                                                                                you may have the right
                                                                                                to request access to the
                                                                                                personal information we
                                                                                                collect from you, change
                                                                                                that information, or
                                                                                                delete it.{" "}
                                                                                                <bdt className="else-block">
                                                                                                  <bdt className="block-component" />
                                                                                                  To request to review,
                                                                                                  update, or delete your
                                                                                                  personal information,
                                                                                                  please{" "}
                                                                                                  <bdt className="block-component" />
                                                                                                  fill out and submit a{" "}
                                                                                                </bdt>
                                                                                              </span>
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 58, 250)",
                                                                                                }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(0, 58, 250)",
                                                                                                      fontSize: 15,
                                                                                                    }}>
                                                                                                    <a
                                                                                                      data-custom-class="link"
                                                                                                      href="https://app.termly.io/notify/7b604b77-6008-4b21-bfaf-646f17fe595a"
                                                                                                      rel="noopener noreferrer"
                                                                                                      target="_blank">
                                                                                                      data subject
                                                                                                      access request
                                                                                                    </a>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                              <bdt className="block-component">
                                                                                                <span data-custom-class="body_text" />
                                                                                              </bdt>
                                                                                            </span>
                                                                                          </span>
                                                                                          <span data-custom-class="body_text">
                                                                                            .
                                                                                          </span>
                                                                                        </div>
                                                                                        <style
                                                                                          dangerouslySetInnerHTML={{
                                                                                            __html:
                                                                                              "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ",
                                                                                          }}
                                                                                        />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          color: "#595959",
                                                                                          fontSize: 14,
                                                                                          fontFamily: "Arial",
                                                                                          paddingTop: 16,
                                                                                        }}>
                                                                                        This privacy policy was created
                                                                                        using Termly's{" "}
                                                                                        <a
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(48, 48, 241) !important",
                                                                                          }}
                                                                                          href="https://termly.io/products/privacy-policy-generator/">
                                                                                          Privacy Policy Generator
                                                                                        </a>
                                                                                        .
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyPage
