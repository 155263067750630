import React from 'react';

const DynamicText = ({ content }) => {

  // Split the content by lines and map over each line to apply the correct styling
  const lines = content.split(/\r?\n/).map((line, index) => {
    // Trim the line to remove extra spaces
    const trimmedLine = line.trim();

    // If the line starts with '#', wrap it in a <strong> tag
    if (trimmedLine.startsWith('#')) {
      return <>
        <br></br>
        <span class="text-black font-semibold text-xl py-4" key={index}>{trimmedLine.replace('#', '')}</span>
      </>;
    }

    // Otherwise, just return the line as is
    return <span class="text-grey-600 text-base py-4" key={index}>{trimmedLine}</span>;
  });

  return (
    <div className="whitespace-pre-line">
      {/* Render each line with a line break in between */}
      {lines.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </div>
  );
};

export default DynamicText;