import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import SideBarItem from "./SideBarItem"
import { Avatar, AvatarFallback, AvatarImage } from "../../@shadcn_components/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../@shadcn_components/ui/dropdown-menu"
import { LifeBuoy, LogOut, Settings } from "lucide-react"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"

import profileMenu from "./SideBarIcons/unfold.png"

import "./SideBar.css"


const pareLogo = require("./SideBarIcons/PareLogo_new.png")

function SideBar() {
  const { userStore } = useStores()
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  if (!userStore.user) return null

  const signOutFunction = async () => {
    await userStore.logout()
    navigate("/")
  }

  const initials = userStore.user.profileInitials

  // Contains sidebar info (current page, whether manager), for all
  // pages where we should show the sidebar
  const ROUTE_TO_SIDEBAR_INFO = {
    "/homepage": {
      isManager: false,
      sideBarPageLabel: locationState => "Home",
    },
    "/context": {
      isManager: false,
      sideBarPageLabel: locationState => {
        if (locationState != null && locationState.source === "homepage") {
          return "Home"
        } else if (locationState != null && locationState.source === "accountData") {
          return "Recordings"
        }
      },
    },
    "/settings": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "Settings",
    },
    "/managerDashboard": {
      isManager: true,
      sideBarPageLabel: locationState => "Home",
    },
    "/managerTeam": {
      isManager: true,
      sideBarPageLabel: locationState => "Team",
    },
    "/managerDev": {
      isManager: true,
      sideBarPageLabel: locationState => "Development",
    },
    "/activityTeamView": {
      isManager: true,
      sideBarPageLabel: locationState => "Development",
    },
    "/activityCreationPage": {
      isManager: true,
      sideBarPageLabel: locationState => "Development",
    },
    "/moduleAnalysisPage": {
      isManager: userStore.user.type === "manager",
      sideBarPageLabel: locationState => "NOTHING?",
    },
    "/assignmentsPage": {
      isManager: false,
      sideBarPageLabel: locationState => "Development",
    },
    "/assignmentLandingPage": {
      isManager: false,
      sideBarPageLabel: locationState => "Development",
    },
    "/chat": {
      isManager: userStore.user.type === "Manager",
      sideBarPageLabel: locationState => "NOTHING?",
    },
    "/PresentationPage": {
      isManager: userStore.user.type === "Manager",
      sideBarPageLabel: locationState => "NOTHING?",
    },
    "/learningCenterPage": {
      isManager: userStore.user.type === "Manager",
      sideBarPageLabel: locationState => (userStore.user.type === "Manager" || userStore.user.type === "Admin" ? "Coaching Center" : "Learning Center"),
    },
  }

  let currPageInfo = ROUTE_TO_SIDEBAR_INFO[pathname]

  if (currPageInfo == null) {
    return <div></div>
  }
  let currPageName = currPageInfo.sideBarPageLabel(state)
  let isManager = currPageInfo.isManager

  // Doing this here to avoid nested inline decisions
  const homeSelected = currPageName === "Home"
  const recordingsSelected = currPageName === "Recordings"
  const teamSelected = currPageName === "Team"
  const developmentSelected = currPageName === "Development"
  const settingsSelected = currPageName === "Settings"
  const learningCenterSelected = currPageName === "learningCenterPage"

  return (
    <div className="sideBanner h-full flex-none flex flex-col items-center md:items-start w-16 md:w-48 lg:w-80">
      <img src={pareLogo} className="w-3/4 mb-4 md:w-10 rounded-xl mt-4 ml-0 md:ml-4 md:mb-4" alt="logo" />
      <div className="w-full">
        <div className="hidden md:block ml-4 MenuLabel">OVERVIEW</div>
        <div className="SideBannerItems ml-4">
          <SideBarItem
            label="Dashboard"
            icon={'home'}
            onClick={homeSelected ? null : () => navigate(isManager ? "/managerDashboard" : "/homepage")}
            isShaded={homeSelected}
          />
          {isManager ? (
            <SideBarItem
              label={"Team"}
              icon={"team"}
              onClick={teamSelected ? null : () => navigate("/managerTeam")}
              isShaded={teamSelected}
            />
          ) : null}
        </div>
        <div className="hidden md:block ml-4 MenuLabel">ENGAGE</div>
        <div className="SideBannerItems ml-4">
          <SideBarItem
            label={"Modules"}
            icon={"modules"}
            onClick={developmentSelected ? null : () => navigate(isManager ? "/managerDev" : "/assignmentsPage")}
            isShaded={developmentSelected}
          />
        </div>
        <div className="SideBannerItems ml-4">
          {isManager ? <SideBarItem
            label={"Coaching"}
            icon={"coaching"}
            onClick={learningCenterSelected ? null : () => navigate("/learningCenterPage")}
            isShaded={learningCenterSelected} />
            : <SideBarItem
              label={"Learning Center"}
              icon={"learningCenter"}
              onClick={learningCenterSelected ? null : () => navigate("/learningCenterPage")}
              isShaded={learningCenterSelected} />
          }

        </div>
        <div className="SideBannerItems ml-4">
          <SideBarItem
            label={"Settings"}
            icon={"settings"}
            onClick={settingsSelected ? null : () => navigate("/settings")}
            isShaded={settingsSelected}
          />
        </div>
      </div>
      <div className="grow" />

      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="profileIconBanner py-4 px:0 md:px-2">
            <div className="profileIconFlex md:mr-3">
              <Avatar>
                <AvatarImage
                  src={
                    userStore.user.profilePicture
                  }
                />
                <AvatarFallback>{initials}</AvatarFallback>
              </Avatar>
            </div>
            <div className="hidden md:block profileNameInfoContainer">
              <div className="profileName">{`${userStore.user.firstName}`}</div>
              <div className="profileEmail">{userStore.user.userEmail}</div>
            </div>
            <div className="hidden md:flex profileBannerButton">
              <img src={profileMenu} className="hidden md:block profileBannerDropdownTrigger"></img>
            </div>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent side={"top"} align={"start"} alignOffset={0}>
          <DropdownMenuItem onSelect={() => navigate("/settings")}>
            <Settings className="mr-2 h-4 w-4" />
            <span>Settings</span>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <LifeBuoy className="mr-2 h-4 w-4" />
            <span>Help</span>
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={signOutFunction}>
            <LogOut className="mr-2 h-4 w-4" />
            <span>Log out</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default observer(SideBar)
