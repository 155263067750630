import React, { useEffect, useState } from "react"
import { Auth, API } from "aws-amplify"
import { useQuery } from "@tanstack/react-query"
import PageTitle from "../../components/PageTitle/PageTitle"
import WarningPopUp from "../../components/PopUps/WarningPopUp"
import InviteUserPopUp from "../../components/PopUps/InviteUserPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import "react-multi-email/dist/style.css"
import "./MyTeamPage.css"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

const MyTeamPage = () => {
  const { userStore } = useStores()
  const [teamMemberList, setTeamMemberList] = useState([])
  const [teamMap, setTeamMap] = useState({})
  const [showInviteUserPopUp, setShowInviteUserPopUp] = useState(false)
  const [showDeletePopUp, setShowDeletePopUp] = useState(false)
  const [deleteDetails, setDeleteDetails] = useState({})
  const [popUpLoading, setPopUpLoading] = useState(false)
  const [inviteeList, setInviteeList] = useState([])
  const [emails, setEmails] = useState([])
  const [focused, setFocused] = useState(false)
  const [proPicMap, setProPicMap] = useState()
  const [type, setType] = useState("");



  const { isPending, error, data, refetch, isRefetching } = useQuery({
    queryKey: ["TeamData"],
    queryFn: async () => {
      const teamData = await API.get("ZenoApp", "/getTeamData", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      })
      if (!proPicMap) {
        const proPics = {}
        for (const rep_id of Object.keys(teamData.teamMap)) {
          const proPicUrl = await getProPic(rep_id)
          proPics[rep_id] = proPicUrl
        }
        setProPicMap(proPics)
      }
      return teamData
    },
  })

  useEffect(() => {
    if (isPending) {
      console.log("Loading")
    } else if (isRefetching) {
      console.log("Refetching")
    } else {
      // setProPicMap(data.proPics)
      console.log(data)
      setTeamMemberList(
        Object.keys(data.teamMap)
          .map(x => {
            return { user_id: x, name: data.teamMap[x].name, email: data.teamMap[x].userEmail, lastLoginTime: data.teamMap[x].lastLoginDatetime, role_type: data.teamMap[x].type }
          })
      )
      setTeamMap(data.teamMap)
      setInviteeList(data.inviteeList)
    }
  }, [isPending, isRefetching])

  const deleteInvitee = async invitee => {
    await API.post("ZenoApp", "/deleteInvitee", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        invitee,
      },
    })
    refetch()
  }
  const openDeletePopUp = teamMemberEntryObj => {
    setDeleteDetails(teamMemberEntryObj)
    setShowDeletePopUp(true)
  }
  const closeDeletePopUp = () => {
    setShowDeletePopUp(false)
    setDeleteDetails(null)
  }

  const deleteUser = async () => {
    setPopUpLoading(true)
    await API.post("ZenoApp", "/deleteUser", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        deleted_user_id: deleteDetails.user_id,
      },
    })
    setShowDeletePopUp(false)
    setDeleteDetails(null)
    setPopUpLoading(false)
    refetch()
  }

  const sendInviteToUsers = async () => {
    if (type.length > 0 && emails.length > 0) {
      setPopUpLoading(true)
      await API.post("ZenoApp", "/sendInvites", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          manager_name: userStore.user.name,
          managerId: userStore.user.userId,
          emailList: emails.join(","),
          company: userStore.user.company,
          type
        },
      })
      setEmails([])
      setType("")
      setPopUpLoading(false)
      setShowInviteUserPopUp(false)
      refetch()
    } else {
      alert("Please fill out all the information")
    }
  }

  const getProPic = async user_id => {
    // TODO: get profile pic from userStore
    const propicUrl = await API.get("ZenoApp", "/getProfilePicture", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      queryStringParameters: {
        user_id,
      },
    })
    return propicUrl
  }

  const handleInviteUserClick = () => setShowInviteUserPopUp(true)
  const handleTypeChange = (event) => setType(event.target.value)
  const handleBackButtonClick = () => setShowInviteUserPopUp(false)

  const inviteUserPopUp = showInviteUserPopUp ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Inviting Reps..."></LoadingPopUp>
      ) : (
          <InviteUserPopUp header={'Add Team Member'} body={`Please enter the emails of your new team members, click enter when you've written out the whole email`} leftButtonLabel="Cancel" leftButtonFunction={handleBackButtonClick} rightButtonLabel="Send Invite" rightButtonFunction={sendInviteToUsers} setEmails={setEmails} setFocused={setFocused} handleTypeChange={handleTypeChange}></InviteUserPopUp>
        )}
    </div>
  ) : null

  const deleteUserPopUp = showDeletePopUp ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Deleting Reps..."></LoadingPopUp>
      ) : (
          <WarningPopUp header="Remove Team Member" body={`Are you sure you would like to remove ${deleteDetails.name}?`} leftButtonFunction={closeDeletePopUp} leftButtonLabel="Cancel" rightButtonFunction={deleteUser} rightButtonLabel="Confirm Removal"></WarningPopUp>
        )}
    </div>
  ) : null



  return (
    <>
      <div className="w-full pt-8">

        <div class="px-4 sm:px-6 lg:px-8 w-full">
          <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
              <h1 class="text-base font-semibold leading-6 text-gray-900">Sales Team</h1>
              <p class="mt-2 text-sm text-gray-700">A list of your Pare sales org members.</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button type="button" class="block rounded-md bg-pareBlue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleInviteUserClick}>Add user</button>
            </div>
          </div>
          {isPending ? (
            <div className="flex-1 px-8 flex flex-row content-center justify-center">
              <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
            </div>
          ) : (
              <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
                          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Title</th>
                          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Login</th>
                          <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                            <span class="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200 bg-white">
                        {teamMemberList.map((teamMember, i) => (
                          <tr key={teamMember.user_id}>
                            <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                              <div class="flex items-center">
                                <div class="h-11 w-11 flex-shrink-0">
                                  <img class="h-11 w-11 rounded-full" src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""></img>
                                </div>
                                <div class="ml-4">
                                  <div class="font-medium text-gray-900">{teamMember.name}</div>
                                  <div class="mt-1 text-gray-500">{teamMember.email}</div>
                                </div>
                              </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              <div class="text-gray-900">Sales Rep</div>
                              <div class="mt-1 text-gray-500">{teamMember.role_type}</div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              <span class="inline-flex items-center rounded-md bg-green_light px-2 py-1 text-xs font-medium text-green">Active</span>
                            </td>
                            <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{new Date(teamMember.lastLoginTime).toDateString()}</td>
                            <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <button class="text-pareBlue hover:text-indigo-900" onClick={() => openDeletePopUp(teamMember)}>Delete</button>
                            </td>
                          </tr>
                        ))}
                        {inviteeList.map((invitee, i) => (
                          <tr key={invitee.email}>
                            <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                              <div class="flex items-center">
                                <div class="h-11 w-11 flex-shrink-0">
                                  <img class="h-11 w-11 rounded-full" src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""></img>
                                </div>
                                <div class="ml-4">
                                  <div class="mt-1 text-gray-500">{invitee.email}</div>
                                </div>
                              </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              <div class="text-gray-900">Sales Rep</div>
                              <div class="mt-1 text-gray-500">{invitee.type}</div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-500">Invited</span>
                            </td>
                            <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500"></td>
                            <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <button class="text-pareBlue hover:text-indigo-900" onClick={() => deleteInvitee(invitee)}>Delete</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
        </div>

      </div>
      {inviteUserPopUp}
      {deleteUserPopUp}
    </>
  )
}

export default observer(MyTeamPage)
