import { Link } from "react-router-dom"
import { Button } from "../components/Button"
import { SlimLayout } from "../components/SlimLayout"
import paresvg from "../images/logos/parelogo.svg"

export default function NotFound() {
  return (
    <SlimLayout>
      <div className="flex">
        <Link href="/" aria-label="Home">
          <img src={paresvg} alt={"pare_logo"} />
        </Link>
      </div>
      <p className="mt-20 text-sm font-medium text-gray-700">404</p>
      <h1 className="mt-3 text-lg font-semibold text-gray-900">Page not found</h1>
      <p className="mt-3 text-sm text-gray-700">Sorry, we couldn’t find the page you’re looking for.</p>
      <Button href="/" className="mt-10">
        Go back home
      </Button>
    </SlimLayout>
  )
}
