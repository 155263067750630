import React, { useState, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { Auth, API } from "aws-amplify"
import awsmobile from "../../aws-exports"
import "./MiniTeamView.css"

Auth.configure(awsmobile)

const MiniTeamView = ({ teamIconInfo }) => {
  const [profilePicIcon, setProfilePicIcon] = useState(null)

  const { isPending, error, data } = useQuery({
    queryKey: [`ProfilePicture_${teamIconInfo.user_id}`],
    queryFn: async () => {
      const ProfilePhotoUrl = await API.get("ZenoApp", "/getProfilePicture", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: teamIconInfo.user_id,
        },
      })
      return { ProfilePhotoUrl }
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  useEffect(() => {
    if (isPending) {
    } else {
      setProfilePicIcon(data.ProfilePhotoUrl)
    }
  }, [isPending])

  const MiniTeamViewIcon = {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    position: "relative",
    backgroundColor: teamIconInfo.color,
    color: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  }
  return (
    <div style={MiniTeamViewIcon}>
      {profilePicIcon ? <img src={profilePicIcon} className="TopNavProPic"></img> : teamIconInfo.initials}
    </div>
  )
}

export default MiniTeamView
