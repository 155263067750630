import { Auth, API } from "aws-amplify"

// Helper function that wraps some of the reused logic in API requests
export async function APIQueryBuilder(route: string, params?: Record<string, any>) {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken()
  const response = await API.get("ZenoApp", route, {
    headers: { Authorization: `Bearer ${token}` },
    queryStringParameters: params || {},
  })
  return response
}
