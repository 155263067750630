import { useState } from 'react'
import PersonalInformation from '../../components/Settings/personalInformation'
import Integrations from '../../components/Settings/integrations'
import { useStores } from '../../models/root-store'

const tabs = [
  { name: 'Personal Information', key: 'personalInformation', current: true, access: ['Admin', 'Manager', 'SDR', "AE"] },
  { name: 'Integrations', key: 'integrations', current: false, access: ['Admin'] },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CombinedModularSettings() {
  const { userStore } = useStores()
  const [currentTab, setCurrentTab] = useState('personalInformation')

  const userType = userStore.user.type // 'Admin', 'Manager', etc.

  // Filter tabs based on user access level

  const accessibleTabs = tabs.filter(tab => tab.access.includes(userType))

  return (
    <div className="px-6 w-full">
      <h1 className="sr-only">Account Settings</h1>

      {/* Add margin-top (mt-10) for more space above the "Settings" title */}
      <header className="border-b border-gray-200 pb-5 sm:pb-0 mt-10">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Settings</h3>
        <div className="mt-3 sm:mt-4">
          {/* Mobile dropdown */}
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              defaultValue={accessibleTabs.find((tab) => tab.key === currentTab).name}
              onChange={(e) => setCurrentTab(tabs.find((tab) => tab.name === e.target.value).key)}
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            >
              {accessibleTabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>

          {/* Desktop tabs */}
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {accessibleTabs.map((item) => (
                <button
                  key={item.name}
                  onClick={() => setCurrentTab(item.key)}
                  aria-current={currentTab === item.key ? 'page' : undefined}
                  className={classNames(
                    currentTab === item.key
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                  )}
                >
                  {item.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </header>

      {/* Settings content */}
      <div className="divide-y divide-gray-200 py-5 max-w-7xl mx-auto grid grid-cols-1 gap-x-8 gap-y-10 px-4 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {currentTab === 'personalInformation' ? 'Personal Information' : 'Integrations'}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            {currentTab === 'personalInformation'
              ? 'View your personal account information'
              : 'Manage your integrations'}
          </p>
        </div>

        <div className="md:col-span-2">
          {currentTab === 'personalInformation' && <PersonalInformation />}
          {currentTab === 'integrations' && userType === 'Admin' && <Integrations />}
        </div>
      </div>
    </div>
  )
}
