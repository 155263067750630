import React, { useState, useEffect } from "react"
import "./HomePage.css"
import "chart.js/auto"
import { Chart, Line } from "react-chartjs-2"
import { Auth, API } from "aws-amplify"
import { useQuery } from "@tanstack/react-query"
import PageTitle from "../../components/PageTitle/PageTitle"
import { grid } from "ldrs"
import LeadderboardEntry from "../../components/LeaderboardEntry/LeaderboardEntry"
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

grid.register()

const HomePage = observer(() => {
  const { userStore } = useStores()
  const { userId, userEmail, managerId, name } = userStore.user || {}
  const [chartData, setChartData] = useState({ datasets: [] })
  const [pendingModuleData, setPendingModuleData] = useState({ datasets: [] })
  const [overdueModuleData, setOverdueModuleData] = useState({ datasets: [] })
  const [totalOverdueModules, setTotalOverdueModules] = useState(0)
  const [totalPendingModules, setTotalPendingModules] = useState(0)
  const [leaderboardArray, setLeaderboardArray] = useState([])

  const { isPending, error, data, isRefetching } = useQuery({
    queryKey: [`homepageData_${userId}`],
    queryFn: async () =>
      await API.get("ZenoApp", "/getHomePageInfo", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: userId,
          email: userEmail,
          manager_id: managerId,
        },
      }),
    refetchOnMount: "always",
  })

  const individualActivityChartOptions = {
    hover: {
      intersect: false,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: "linear",
        ticks: {
          display: false,
          beginAtZero: true,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        min: 0,
        ticks: {
          display: false,
          beginAtZero: true,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  }

  useEffect(() => {
    if (isPending || isRefetching) {
      console.log("Loading")
    } else {
      const graphData = data.graphData.map(obj => ({ date: obj.date, count: obj.count }))
      setChartData({
        labels: graphData.map(d => `Week of ${d.date.replace(/^[^\s]*\s/, "").replace(/\s[^\s]*$/, "")}`),
        datasets: [
          {
            label: "Role Plays Completed Every Week",
            data: graphData.map(d => d.count),
            fill: true,
            backgroundColor: "rgb(48, 69, 255,0.2)",
            borderColor: "rgb(48, 69, 255)",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "rgb(48, 69, 255)",
            pointHoverBorderColor: "rgb(48, 69, 255)",
          },
        ],
      })

      setLeaderboardArray(data.leaderBoardInfo)
      const pendingInfoObj = data.involvedActivities.reduce(
        (acc, x) => {
          if (x.testParticipants) {
            const partipantsObj = JSON.parse(x.testParticipants)
            let totalModulePendingNum = 0
            const pendingActivityObj = {}
            Object.values(partipantsObj).forEach(participant => {
              if (participant.status === "Pending") {
                totalModulePendingNum++
                const day = new Date(participant.dueDate).toDateString()
                const epochDay = new Date(day).getTime()
                if (pendingActivityObj[epochDay]) {
                  pendingActivityObj[epochDay] += 1
                } else {
                  pendingActivityObj[epochDay] = 1
                }
              }
            })
            acc.mainMetric += totalModulePendingNum
            Object.assign(acc.data, pendingActivityObj)
          }
          return acc
        },
        { mainMetric: 0, data: { 0: 0 } },
      )
      setTotalPendingModules(pendingInfoObj.mainMetric)
      setPendingModuleData({
        labels: Object.keys(pendingInfoObj.data),
        datasets: [
          {
            // label: "Team Activity",
            data: Object.values(pendingInfoObj.data),
            fill: true,
            backgroundColor: "rgb(48, 69, 255,0.2)",
            borderColor: "rgb(48, 69, 255)",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "rgb(48, 69, 255)",
            pointHoverBorderColor: "rgb(48, 69, 255)",
            clip: 6,
            // lineTension: 0.1
          },
        ],
      })

      const overdueInfoObj = data.involvedActivities.reduce(
        (acc, x) => {
          if (x.testParticipants) {
            const partipantsObj = JSON.parse(x.testParticipants)
            let totalModuleOverdeNum = 0
            const overdueActivityObj = {}
            Object.values(partipantsObj).forEach(participant => {
              if (overdueChecker(participant.dueDate)) {
                totalModuleOverdeNum++
                const day = new Date(participant.dueDate).toDateString()
                const epochDay = new Date(day).getTime()
                if (overdueActivityObj[epochDay]) {
                  overdueActivityObj[epochDay] += 1
                } else {
                  overdueActivityObj[epochDay] = 1
                }
              }
            })
            acc.mainMetric += totalModuleOverdeNum
            Object.assign(acc.data, overdueActivityObj)
          }
          return acc
        },
        { mainMetric: 0, data: { 0: 0 } },
      )
      setTotalOverdueModules(overdueInfoObj.mainMetric)
      setOverdueModuleData({
        labels: Object.values(overdueInfoObj.data),
        datasets: [
          {
            // label: "Team Activity",
            data: Object.values(overdueInfoObj.data),
            fill: true,
            backgroundColor: "rgb(48, 69, 255,0.2)",
            borderColor: "rgb(48, 69, 255)",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "rgb(48, 69, 255)",
            pointHoverBorderColor: "rgb(48, 69, 255)",
            clip: 6,
          },
        ],
      })
    }
  }, [isPending, isRefetching])

  const overdueChecker = dueDate => {
    const stringToDate = new Date(dueDate)
    const epochDueDate = stringToDate.getTime()
    const today = new Date().toDateString()
    const epochToday = new Date(today).getTime()
    return epochToday > epochDueDate
  }

  // TODO use userStore:
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        ticks: {
          stepSize: 1,
        },
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  }

  return (
    <div className="w-full h-full flex flex-col px-8 md:px-20 pt-8">
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbPage>Home</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">{`Hello, ${name} 👋`}</h1>
          <p class="mt-2 text-sm text-gray-700">See what’s happening and where to spend your time</p>
        </div>

      </div>


    </div>


  )
})

export default HomePage
