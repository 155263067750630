import React, { useEffect, useRef } from "react"

const WebcamStream = () => {
  const videoEl = useRef(null)
  const videoStream = useRef(null)

  const videoBoxStyle = {
    borderRadius: "5px",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  }

  useEffect(() => {
    if (!videoEl) {
      return
    }
    const startWebcamStream = async () => {
      videoStream.current = await navigator.mediaDevices.getUserMedia({ video: { width: 500, height: 300 } })
      videoEl.current.srcObject = videoStream.current
      videoEl.current.play()
    }
    startWebcamStream()

    return () => {
      if (videoStream.current) {
        videoStream.current.getTracks().forEach(track => {
          track.stop()
        })
      }
      videoEl.current = null
      videoStream.current = null
    }
  }, [videoEl])

  return <video ref={videoEl} class="rounded overflow-hidden flex items-center" />
}

export default WebcamStream
