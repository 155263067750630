import React from "react"
import { useNavigate } from "react-router-dom"
import { Auth } from "aws-amplify"
import awsmobile from "../../aws-exports"
import "./IndividualActivityBox.css"
import { useStores } from "../../models/root-store"

Auth.configure(awsmobile)

const IndividualActivityBox = ({ type, activityInfo }) => {
  const { userStore } = useStores()
  const { userId } = userStore.user || {}
  let navigate = useNavigate()

  const toRecordingDetails = () => {
    navigate("/assignmentLandingPage", { state: activityInfo })
  }

  const status = JSON.parse(activityInfo.testParticipants)[userId].status
  const completedActivity = status === "Pending" || status === "Passed"
  const analysisInProgress = status === "In Progress"
  const individualDueDate = JSON.parse(activityInfo.testParticipants)[userId].dueDate
  const friendlyFormatDate = new Date(individualDueDate).toDateString()
  const stringToDate = new Date(individualDueDate)
  const epochDueDate = stringToDate.getTime()
  const today = new Date().toDateString()
  const epochToday = new Date(today).getTime()
  const overdue = epochDueDate < epochToday

  const RecordingStatusLabelStyle = {
    background:
      status === "Pending" ? "rgba(255, 199, 0)" : status === "Passed" ? "rgba(57, 202, 34)" : "rgba(255, 94, 94)",
    fontSize: "9px",
    color: "white",
    borderRadius: "5px",
    position: "absolute",
    top: "85%",
    right: "5%",
    padding: "5px",
    minWidth: "60px",
    display: "inline-block",
    textAlign: "center",
    fontWeight: 500,
  }

  return completedActivity ? (
    <div
      className={type === "FlexBox" ? "IndividualActivityFlexBox" : "IndividualActivityBox"}
      onClick={toRecordingDetails}>
      <label className="IndividualActivityTitle">{activityInfo.testName}</label>
      <div className="IndiModuleDueDateContainer">
        <div className="IndividualActivityDueDateIcon"></div>
        <label className="IndividualActivityBoxDueDateLabel">{`Due ${friendlyFormatDate}`}</label>
      </div>
      <div className="IndiModuleDurationContainer">
        <div className="IndividualActivityClockIcon"></div>
        <label className="IndividualActivityBoxDurationLabel">{`${activityInfo.timeLimit} minutes`}</label>
      </div>
      <p className="IndividualActivityDescriptionLabel">{`Description: ${activityInfo.testDecription}`}</p>
      <div style={RecordingStatusLabelStyle}>{status}</div>
    </div>
  ) : type === "FlexBox" ? (
    <div className="IndividualActivityFlexBox" onClick={toRecordingDetails}>
      <label className="IndividualActivityTitle">{activityInfo.testName}</label>
      {analysisInProgress ? (
        <div className="IndividualActvityStartLabelContainer">
          <label className="IndividualActvityStartLabel">Analyzing...</label>
        </div>
      ) : (
          <div>
            {overdue && !analysisInProgress ? (
              <div className="OverdueTag">Overdue</div>
            ) : (
                <div className="IndividualActvityStartLabelContainer">
                  <label className="IndividualActvityStartLabel">Start</label>
                  <div className="playIcon"></div>
                </div>
              )}
          </div>
        )}
      <div className="IndiModuleDueDateContainer">
        <div className="IndividualActivityDueDateIcon"></div>
        <label className="IndividualActivityBoxDueDateLabel">{`Due ${friendlyFormatDate}`}</label>
      </div>
      <div className="IndiModuleDurationContainer">
        <div className="IndividualActivityClockIcon"></div>
        <label className="IndividualActivityBoxDurationLabel">{`${activityInfo.timeLimit} minutes`}</label>
      </div>
      <p className="IndividualActivityDescriptionLabel">{`Description: ${activityInfo.testDecription}`}</p>
    </div>
  ) : (
        <div className="IndividualActivityBoxInactive" onClick={toRecordingDetails}>
          <label className="IndividualActivityTitle">{activityInfo.testName}</label>
          {analysisInProgress ? (
            <div className="IndividualActvityStartLabelContainer">
              <label className="IndividualActvityStartLabel">Analyzing...</label>
            </div>
          ) : (
              <div>
                {overdue && !analysisInProgress ? (
                  <div className="OverdueTag">Overdue</div>
                ) : (
                    <div className="IndividualActvityStartLabelContainer">
                      <label className="IndividualActvityStartLabel">Start</label>
                      <div className="playIcon"></div>
                    </div>
                  )}
              </div>
            )}
          <div className="IndiModuleDueDateContainer">
            <div className="IndividualActivityDueDateIcon"></div>
            <label className="IndividualActivityBoxDueDateLabel">{`Due ${friendlyFormatDate}`}</label>
          </div>
          <div className="IndiModuleDurationContainer">
            <div className="IndividualActivityClockIcon"></div>
            <label className="IndividualActivityBoxDurationLabel">{`${activityInfo.timeLimit} minutes`}</label>
          </div>
          <p className="IndividualActivityDescriptionLabel">{`Description: ${activityInfo.testDecription}`}</p>
        </div>
      )
}

export default IndividualActivityBox
