import React, { useEffect, useRef } from "react"

import ScrollToBottom from "react-scroll-to-bottom"

import Message from "./Message/Message"

import "./Messages.css"

const Messages = ({ messages, type, loading, uploadPersonaFile, uploadRubricFile, messagesType }) => {
  return (
    <ScrollToBottom className="messages">
      {messages.map((message, i) => (
        <div key={`${message.user}-${messages.length}`}>
          <Message
            message={message}
            type={type}
            uploadPersonaFile={uploadPersonaFile}
            uploadRubricFile={uploadRubricFile}
            loading={loading}
            messagesType={messagesType}
          />
        </div>
      ))}
    </ScrollToBottom>
  )
}

export default Messages
