import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { quantum } from "ldrs"
import "./ContextPage.css"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

const ENDPOINT = "wss://dfzlakpg93.execute-api.us-east-1.amazonaws.com/prod"
// const ENDPOINT = "wss://jvmahjkhx8.execute-api.us-east-1.amazonaws.com/pareuser"
let socket = null
quantum.register()

const ContextPage = () => {
  const { userStore, currentRoleplayStore } = useStores()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {


    if (!socket || socket.readyState == 3) {
      socket = new WebSocket(ENDPOINT)
    }

    socket.onopen = () => {
      console.log('OPEN')
      sendContext()
    }
    socket.onerror = (e) => {
      console.log("error:", e)
      socket = new WebSocket(ENDPOINT)
    }

    socket.onmessage = event => {
      const contextObj = JSON.parse(event.data)
      currentRoleplayStore.setField("additionalInstructions", contextObj.additionalInstructions)
      currentRoleplayStore.setField("assistant_id", contextObj.assistant_id)
      if (location.state.presentation) {
        navigate("/PresentationPage", { state: location.state })
      } else {
        navigate("/chat", { state: location.state })
      }
    }



    return () => {

      if (socket.readyState == 1) {
        socket.close()
      }

    }
  }, [])

  const sendContext = () => {
    const obj = {
      user: userStore.user.userId,
      manager_id: userStore.user.managerId,
      business: userStore.user.company,
      testObj: location.state.testObj,
      persona_id: location.state.persona_id,
      prospectName: location.state.voice,
      FirstName: userStore.user.firstName,
      activity_id: location.state.activity_id,
    }
    const payload = { action: "createcontext", message: obj }

    currentRoleplayStore.setField("simulationReady", "true")
    currentRoleplayStore.setField("prospectName", location.state.voice)
    currentRoleplayStore.setField("personaName", location.state.personaName)
    currentRoleplayStore.setField("callType", location.state.testType)
    socket.send(JSON.stringify(payload))
  }

  return (
    <div class="h-full xl:pl-0 w-full bg-pareBlue flex items-center justify-center">
      <div className="w-[30%] h-[30%] flex flex-col items-center justify-center">
        <p class="text-white semi-bold text-lg leading-7">Generating Conversation</p>
        <div className="QuantumLoaderDiv">
          <l-quantum size="60" speed="1.75" color="white"></l-quantum>
        </div>
      </div>
    </div>
  )
}

export default observer(ContextPage)
