import React, { useEffect, useState } from "react"
import Recordings from "../../components/Recordings/Recordings"
import { useNavigate, useLocation } from "react-router-dom"
import { Auth, API } from "aws-amplify"
import PageTitle from "../../components/PageTitle/PageTitle"
import { useQuery } from "@tanstack/react-query"
import { grid } from "ldrs"
import "./AssignmentLandingPage.css"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

grid.register()

const AssignmentLandingPage = () => {
  const { userStore } = useStores()
  const [attemptArray, setAttemptArray] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const [refreshAllowed, setRefreshAllowed] = useState(true)
  const [refreshInProgress, setRefreshInProgress] = useState(false)
  const [deletePopUpMenu, setDeletePopUpMenu] = useState(false)
  const [renamePopUpMenu, setRenamePopUpMenu] = useState(false)
  const [popUpMenuDetails, setPopUpMenuDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedTag, setSelectedTab] = useState("Overview")
  const [moduleCatInfo, setModuleCatInfo] = useState({
    description: "",
    objective: "",
    persona_summary: "",
    scenario: "",
    rubric_name: "",
    links: "",
    duration: "",
    dueDate: "",
    numAttempts: ""
  })
  const [modulePendingReview, setModulePendingReview] = useState("Not Passed")

  const { isPending, error, data, isRefetching } = useQuery({
    queryKey: [`AssignmentLandingPage_${location.state.activity_id}`],
    queryFn: async () => {
      const managerActivityData = await API.get("ZenoApp", "/getManagerActivity", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          activity_id: location.state.activity_id,
          manager_id: userStore.user.managerId,
        },
      })
      const attempts = await API.get("ZenoApp", "/getActivityAttempts", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          activity_id: location.state.activity_id,
        },
      })
      return { attempts, managerActivityData }
    },
    refetchOnMount: "always",
  })

  useEffect(() => {
    if (isPending) {
      console.log("Loading")
    } else {
      setAttemptArray(data.attempts)
      const dueDate = JSON.parse(data.managerActivityData.testParticipants)[userStore.user.userId]
      setModuleCatInfo({
        description: data.managerActivityData.testDecription,
        objective: data.managerActivityData.testObj,
        persona_summary: data.managerActivityData.persona_summary,
        scenario: data.managerActivityData.scenarioSummary,
        rubric_name: data.managerActivityData.rubricFile,
        links: data.managerActivityData.links,
        duration: data.managerActivityData.timeLimit,
        dueDate,
        numAttempts: data.attempts.length
      })
      const user_status = JSON.parse(data.managerActivityData.testParticipants)[userStore.user.userId].status
      setModulePendingReview(user_status)
    }
  }, [isPending, isRefetching])

  const openMenuPopUp = convoObj => {
    setPopUpMenuDetails(convoObj)
    if (convoObj.popUpType === "delete") {
      setDeletePopUpMenu(true)
    } else if (convoObj.popUpType === "rename") {
      setRenamePopUpMenu(true)
    }
  }
  const closeMenuPopUp = () => {
    if (popUpMenuDetails.popUpType === "delete") {
      setDeletePopUpMenu(false)
    } else if (popUpMenuDetails.popUpType === "rename") {
      setRenamePopUpMenu(false)
    }
    setPopUpMenuDetails(null)
  }

  const refresh = async () => {
    if (refreshAllowed) {
      setRefreshInProgress(true)
      setRefreshAllowed(false)
      setRefreshInProgress(false)
      setTimeout(() => setRefreshAllowed(true), 3000)
    }
  }

  const deleteRecording = async () => {
    setLoading(true)
    await API.post("ZenoApp", "/deleteRecording", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        conversation_id: popUpMenuDetails.conversation_id,
      },
    })
    refresh()
    setLoading(false)
    closeMenuPopUp()
  }

  const startNewAttempt = () => {
    navigate("/context", { state: location.state })
  }

  const DeletePopUpMenuDiv = deletePopUpMenu ? (
    <div className={`frostedBackground is-visible`}>
      {loading ? (
        <div className="RecordingPopUp">
          <div className="loadingDiv">
            <l-grid size="120" speed="1.4" color="#3045FF"></l-grid>
          </div>
        </div>
      ) : (
          <div className="RecordingPopUp">
            <button className="backButtonRecordingInfo" onClick={closeMenuPopUp}></button>
            <label className="deletePopUpHeader">Delete "{popUpMenuDetails.conversation_title}" ?</label>
            <label className="deletePopUpSubHeader">Are you sure you want to delete this recording?</label>
            <button className="deleteRecordingButton" onClick={deleteRecording}>
              Delete Recording
          </button>
          </div>
        )}
    </div>
  ) : null

  const recordingsBoxContent =
    refreshInProgress || isPending ? (
      <div className="flex-1 px-8 flex flex-row content-center justify-center">
        <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
      </div>
    ) : !attemptArray.length ? (
      <div className="flex flex-col items-center mt-20">
        <p className="text-sm text-muted-foreground font-semibold mb-2">No Attempts</p>
        <p className="text-sm text-muted-foreground">Click "Run Sim" to get started!</p>
      </div>
    ) : (
          <div className="w-full px-6 flex flex-row flex-wrap">
            <Recordings
              recordings={attemptArray}
              name={"user"}
              handleMenuPopUp={openMenuPopUp}
              activityName={location.state.testName}
            />
          </div>
        )


  console.log(modulePendingReview)
  return (
    <>
      <div class="xl:pl-0 w-full">
        <main>
          <header>
            <nav class="flex overflow-x-auto border-b border-white/10 py-4">
              <div className="px-8">
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink href="/assignmentsPage">Modules</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>{`${location.state.testName}`}</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
            </nav>

            <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-400/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
              <div>
                <div class="flex items-center gap-x-3">
                  {modulePendingReview === 'Incomplete' || modulePendingReview === 'Pending' ?
                    <div class="flex-none rounded-full bg-yellow_light p-1 text-yellow">
                      <div class="h-2 w-2 rounded-full bg-current"></div>
                    </div>
                    : modulePendingReview === 'Passed' ?
                      <div class="flex-none rounded-full bg-green_light p-1 text-green">
                        <div class="h-2 w-2 rounded-full bg-current"></div>
                      </div>
                      : <div class="flex-none rounded-full bg-red-400/10 p-1 text-red-400">
                        <div class="h-2 w-2 rounded-full bg-current"></div>
                      </div>}

                  <h1 class="flex gap-x-3 text-base leading-7">

                    <span class="font-semibold text-grey">{location.state.testName}</span>
                  </h1>
                </div>
                <p class="mt-2 text-xs leading-6 text-gray-400">{`${location.state.testName} Summary`}</p>
              </div>
              {(modulePendingReview === "Not Passed" || modulePendingReview === "Incomplete") && !isPending ? (
                <button class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-pareBlue ring-1 ring-inset ring-pareBlue sm:order-none" onClick={startNewAttempt}>Run Sim</button>

              ) : modulePendingReview == "Pending" && !isPending ? (
                <button disabled class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">Pending</button>
              ) : modulePendingReview == "Passed" && !isPending ? (
                <button disabled class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">Passed</button>
              ) : null}

            </div>

            <div class="grid grid-cols-1 bg-gray-400/10 sm:grid-cols-2 lg:grid-cols-4">
              <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:px-8">
                <p class="text-sm font-medium leading-6 text-gray-400">Due Date</p>
                <p class="mt-2 flex items-baseline gap-x-2">
                  <span class="text-4xl font-semibold tracking-tight text-grey">2/2/2044</span>
                </p>
              </div>
              <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                <p class="text-sm font-medium leading-6 text-gray-400">Duration</p>
                <p class="mt-2 flex items-baseline gap-x-2">
                  <span class="text-4xl font-semibold tracking-tight text-grey">{moduleCatInfo.duration}</span>
                  <span class="text-sm text-gray-400">mins</span>
                </p>
              </div>
              <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8">
                <p class="text-sm font-medium leading-6 text-gray-400">Number of attempts</p>
                <p class="mt-2 flex items-baseline gap-x-2">
                  <span class="text-4xl font-semibold tracking-tight text-grey">{moduleCatInfo.numAttempts}</span>
                </p>
              </div>
              <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                <p class="text-sm font-medium leading-6 text-gray-400">Status</p>
                <p class="mt-2 flex items-baseline gap-x-2">
                  <span class="text-4xl font-semibold tracking-tight text-grey">{modulePendingReview}</span>
                </p>
              </div>
            </div>
          </header>
          <div className="mb-4 mt-4 px-6">
            <Tabs defaultValue="Overview" className="w-[100%]" onValueChange={setSelectedTab}>
              <TabsList>
                <TabsTrigger value="Overview">Overview</TabsTrigger>
                <TabsTrigger value="Attempts">Attempts</TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
          {selectedTag === "Overview" ?
            <div class="px-8 ">
              <div class="mt-6 border-t border-gray-100">
                <dl class="divide-y divide-gray-100">
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Description</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.description}</dd>
                  </div>
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Objective</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.objective}</dd>
                  </div>
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Persona Summary</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.persona_summary}</dd>
                  </div>
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Scenario Background</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.scenario}</dd>
                  </div>
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Links</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.links.split(",").map(link => (
                      <button
                        key={`link_button_${link}`}
                        onClick={() => window.open(link, "_blank")}
                        className="rounded-md shadow-md p-2 hover:bg-gray-200">
                        <p className="line-clamp-2 font-semibold text-md text-left">{link}</p>
                      </button>
                    ))}</dd>
                  </div>

                </dl>
              </div>
            </div>
            : <>{recordingsBoxContent}</>}
        </main>
      </div>
    </>
  )
}

export default observer(AssignmentLandingPage)


{/* <div className="w-full h-full flex flex-col px-8 md:px-20 pt-8"> 
   //   <div className="mb-8">
    //     <Breadcrumb>
    //       <BreadcrumbList>
    //         <BreadcrumbItem>
    //           <BreadcrumbLink href="/assignmentsPage">Modules</BreadcrumbLink>
    //         </BreadcrumbItem>
    //         <BreadcrumbSeparator />
    //         <BreadcrumbItem>
    //           <BreadcrumbPage>{`${location.state.testName}`}</BreadcrumbPage>
    //         </BreadcrumbItem>
    //       </BreadcrumbList>
    //     </Breadcrumb>
    //   </div>
    //   {(modulePendingReview === "Not Passed" || modulePendingReview === "Incomplete") && !isPending ? ( 
    //     <PageTitle
    //       pageTitle={`${location.state.testName}`}
    //       buttonFunction={startNewAttempt}
    //       buttonLabel={"Run Simulation"}
    //       icon={"runSim"}></PageTitle>
    //   ) : modulePendingReview !== "Not Passed" && !isPending ? (
    //     <div className="flex flex-row justify-between items-center mb-4">
    //       <h1 className="text-2xl font-semibold">{location.state.testName}</h1>
    //       <button disabled className="py-2 px-2 md:px-8 rounded-md bg-blue-600 text-grey text-sm font-semibold">
    //         Pending
    //       </button>
    //     </div>
    //   ) : null}
    //   <div className="">
    //     <Tabs defaultValue={currentTab} className="mb-4" onValueChange={setCurrentTab}>
    //       <TabsList>
    //         <TabsTrigger value="Overview">Overview</TabsTrigger>
    //         <TabsTrigger value="Performance">Attempts</TabsTrigger>
    //       </TabsList>
    //     </Tabs>
    //   </div>
    //   {isPending ? (
    //     <div className="loadingDiv">
    //       <l-grid size="120" speed="1.4" color="#3045FF"></l-grid>
    //     </div>
    //   ) : (
    //     <div className="flex-1 flex flex-col">
    //       {currentTab === "Overview" ? (
    //         <div className="flex flex-col xl:flex-row pb-8">
    //           <div className="w-full xl:pr-8">
    //             <h1 className="text-2xl font-semibold">Description</h1>
    //             <p className="text-md font-light mb-4">{moduleCatInfo.description}</p>
    //             <h1 className="text-2xl font-semibold">Objective</h1>
    //             <p className="text-md font-light mb-4">{moduleCatInfo.objective}</p>
    //             <h1 className="text-2xl font-semibold">Persona Summary</h1>
    //             <p className="text-md font-light mb-4">{moduleCatInfo.persona_summary}</p>
    //             <h1 className="text-2xl font-semibold">Scenario Background</h1>
    //             <p className="text-md font-light mb-4">{moduleCatInfo.scenario}</p>
    //           </div>
    //           <div className="w-full flex flex-col xl:w-1/4 2xl:w-1/3 items-start">
    //             <h1 className="text-2xl font-semibold mb-1">Docs & Links</h1>
    //             <div className="mb-4 flex flex-col">
    //               {moduleCatInfo.links.split(",").map(link => (
    //                 <button
    //                   key={`link_button_${link}`}
    //                   onClick={() => window.open(link, "_blank")}
    //                   className="rounded-md shadow-md p-2 hover:bg-gray-200">
    //                   <p className="line-clamp-2 font-semibold text-md text-left">{link}</p>
    //                 </button>
    //               ))}
    //             </div>
    //             <h1 className="text-2xl font-semibold mb-1">Scoring Rubric</h1>
    //             <div className="font-semibold text-md rounded-md shadow-md p-2 border-sm">
    //               {moduleCatInfo.rubric_name}
    //             </div>
    //           </div>
    //         </div>
    //       ) : (
    //         <div className="w-full flex-1 flex flex-col">
    //           {recordingsBoxContent}
    //           {DeletePopUpMenuDiv}
    //         </div>
    //       )}
    //     </div>
    //   )}
// </div></div>}*/}