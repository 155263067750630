import React from "react"
import { Auth } from "aws-amplify"
import awsmobile from "../../aws-exports"

import "./SideBar.css"
Auth.configure(awsmobile)

export default function SideBarItem({ label, icon, onClick, isShaded }) {
  const navIcon = icon === 'home' ?
    <svg class={`h-6 w-6 shrink-0 ${isShaded ? "text-pareBlue" : "text-gray300"}`} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
    </svg> : icon === 'team' ?
      <svg class={`h-6 w-6 shrink-0 ${isShaded ? "text-pareBlue" : "text-gray300"}`} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
      </svg> : icon === 'modules' ?
        <svg class={`h-6 w-6 shrink-0 ${isShaded ? "text-pareBlue" : "text-gray300"}`} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
        </svg> : icon === 'learningCenter' || icon === 'coaching' ?
          <svg class={`h-6 w-6 shrink-0 ${isShaded ? "text-pareBlue" : "text-gray300"}`} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
          </svg> : icon === 'settings' ?
            <svg class={`h-6 w-6 shrink-0 ${isShaded ? "text-pareBlue" : "text-gray300"}`} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg> : null

  return (
    <>
      {isShaded ? <button class="group flex gap-x-3 rounded-md bg-gray-50 p-2 text-sm font-semibold leading-6 text-pareBlue mr-4" onClick={onClick}>
        {navIcon}
        <span className="hidden md:block">{label}</span>
      </button>
        : <button class="group flex gap-x-3 rounded-md p-2 text-sm hover:bg-gray-50 hover:text-pareBlue font-semibold leading-6 text-gray-700 mr-4" onClick={onClick}>
          {navIcon}
          <span className="hidden md:block ">{label}</span>
        </button>}
    </>
    // <div className={`SideBarItem ${isShaded ? "SideBarItemShading" : ""}`}>
    //   <button
    //     className={`flex flex-col md:flex-row font-semibold leading-6 SideBarButton ${!isShaded ? "SideBarButtonUnselected" : ""}`}
    //     onClick={onClick}>
    //     <img src={icon} className="SideBarIcon w-3/4 md:w-4 md:ml-2 md:mr-2 md:w-4" />
    //     <span className="hidden md:block">{label}</span>
    //   </button>
    // </div>
  )
}
