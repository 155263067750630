import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import MiniTeamView from "../MiniTeamView/MiniTeamView"
import ActionsMenuDropdown from "../ActionsMenuDropdown/ActionsMenuDropdown"
import { useQuery } from "@tanstack/react-query"
import { Auth, API } from "aws-amplify"
import awsmobile from "../../aws-exports"
import "./ActivityBox.css"

Auth.configure(awsmobile)

const ActivityBox = ({ activityInfo, type, order, handleMenuPopUp }) => {
  let navigate = useNavigate()
  const [showActionsMenu, setShowActionsMenu] = useState(false)

  const toActivityTeamView = () => {
    if (activityInfo.doneCreation === "Y") {
      navigate("/activityTeamView", { state: activityInfo })
    } else {
      alert("Module is being created!")
    }
  }

  useEffect(() => {
    const handler = () => setShowActionsMenu(false)

    window.addEventListener("click", handler)
    return () => {
      window.removeEventListener("click", handler)
    }
  }, [])

  const handleActionsButtonHover = e => {
    e.stopPropagation()
    setShowActionsMenu(true)
  }
  const handleActionsButtonNoHover = e => {
    e.stopPropagation()
    setShowActionsMenu(false)
  }

  const deleteConversationObj = {
    popUpType: "delete",
    activity_id: activityInfo.activity_id,
    testName: activityInfo.testName,
  }
  const renameConversationObj = {
    popUpType: "rename",
    activity_id: activityInfo.activity_id,
    testName: activityInfo.testName,
  }
  const deleteActivity = () => {
    handleMenuPopUp(deleteConversationObj)
  }
  const renameActivity = () => {
    handleMenuPopUp(renameConversationObj)
  }
  const overdueChecker = dueDate => {
    const stringToDate = new Date(dueDate)
    const epochDueDate = stringToDate.getTime()
    const today = new Date().toDateString()
    const epochToday = new Date(today).getTime()
    return epochToday > epochDueDate
  }
  const participantsObj = activityInfo.testParticipants ? JSON.parse(activityInfo.testParticipants) : {}
  const participantKeys = Object.keys(participantsObj)
  const completionDist = participantKeys.reduce(
    (acc, x) => {
      if (participantsObj[x].status === "Incomplete") {
        if (overdueChecker(participantsObj[x].dueDate)) {
          acc["Overdue"] += 1
        } else {
          acc["Not Started"] += 1
        }
      } else if (participantsObj[x].status === "Passed") {
        acc["Passed"] += 1
      } else if (participantsObj[x].status === "Not Passed" || participantsObj[x].status === "In Progress") {
        acc["Not Passed"] += 1
      } else {
        acc["Pending"] += 1
      }
      return acc
    },
    { "Not Started": 0, Passed: 0, Pending: 0, "Not Passed": 0, Overdue: 0 },
  )

  const totalParticipants = Object.values(participantsObj).length
  const completedStat = activityInfo.testParticipants
    ? Object.values(participantsObj).reduce((acc, x) => {
      if (x.status === "Passed") {
        acc++
      }
      return acc
    }, 0)
    : 0

  const completedPercentage = activityInfo.testParticipants ? Math.floor((completedStat / totalParticipants) * 100) : 0
  const MiniTeamViewInfo = activityInfo.testParticipants
    ? Object.keys(participantsObj).map(x => {
      const initials =
        participantsObj[x].name.split(" ")[0].toUpperCase().charAt(0) +
        participantsObj[x].name.split(" ")[1].toUpperCase().charAt(0)
      return { initials: initials, color: participantsObj[x].color, user_id: x }
    })
    : []

  const totalNum = Object.values(completionDist).reduce((acc, x) => (acc += x), 0)

  const greenBarStyle = {
    height: "100%",
    width: `${(completionDist.Passed / totalNum) * 100}%`,
    background: "rgba(57, 202, 34)",
  }
  const YellowBarStyle = {
    height: "100%",
    width: `${(completionDist["Pending"] / totalNum) * 100}%`,
    background: "rgba(255, 199, 0)",
  }
  const OrangeBarStyle = {
    height: "100%",
    width: `${(completionDist["Not Passed"] / totalNum) * 100}%`,
    background: "rgb(255,165,0)",
  }
  const RedBarStyle = {
    height: "100%",
    width: `${(completionDist["Overdue"] / totalNum) * 100}%`,
    background: "rgba(255, 94, 94)",
  }
  const GreyBarStyle = {
    height: "100%",
    width: totalNum === 0 ? "100%" : `${(completionDist["Not Started"] / totalNum) * 100}%`,
    background: "#c5c5c5",
  }

  return type === "FlexBox" ? (
    <div
      className={order === 0 ? "FirstActivityFlexBox" : "ActivtyFlexBox bg-gradient-to-br from-transparent to-blue-50 hover:to-blue-100 transition duration-300 cursor-pointer hover:outline hover:outline-pareBlue hover:outline-2"}
      onClick={toActivityTeamView}
      onMouseOver={handleActionsButtonHover}
      onMouseLeave={handleActionsButtonNoHover}>
      <label className="ActivityTitle">{activityInfo.testName}</label>
      {showActionsMenu ? (
        <ActionsMenuDropdown
          options={[
            { value: 1, label: "Delete" },
            { value: 2, label: "Rename" },
          ]}
          deleteAction={deleteActivity}
          renameAction={renameActivity}></ActionsMenuDropdown>
      ) : null}
      <div className="ActivityBoxTeamContainer">
        <div className="ActivityBoxTeamMemberContainer">
          <div className="TeamIcon"></div>
          <label className="ModuleCardTeamNum">{totalParticipants}</label>
        </div>
      </div>
      <p className="ActivityDescriptionLabel">{`Description: ${activityInfo.testDecription}`}</p>
      {activityInfo.doneCreation === "Y" ? (
        <div>
          <div className="ModuleCardPerformanceChart">
            <div style={greenBarStyle}></div>
            <div style={YellowBarStyle}></div>
            <div style={OrangeBarStyle}></div>
            <div style={RedBarStyle}></div>
            <div style={GreyBarStyle}></div>
          </div>
          <label className="CompletionLabel">Completion</label>
          <label className="CompletionPercentageLabel">{`${completedPercentage}%`}</label>
        </div>
      ) : (
          <div className="CreatingModuleLabel">Creating Module...</div>
        )}
    </div>
  ) : (
      <div className="ActivtyBox">
        <label className="HomeActivityTitle">{activityInfo.testName}</label>
        <div className="HomeActvityParticipationBanner">{`${completedStat} of ${totalParticipants} completed`}</div>
        <div className="HomeTimeLimitContainer">
          <div className="HomeTimeLimitIcon"></div>
          <label className="HomeActivityBoxDurationLabel">{`${activityInfo.timeLimit} min`}</label>
        </div>
        <div className="HomeTeamContainer">
          <div className="HomeTeamIcon"></div>
          <div className="HomeActivityBoxTeamMemberContainer">
            {MiniTeamViewInfo.map((x, i) => (
              <div key={i}>
                <MiniTeamView teamIconInfo={x}></MiniTeamView>
              </div>
            ))}
          </div>
        </div>
        <p className="HomeActivityDescriptionLabel">{`Description: ${activityInfo.testDecription}`}</p>
      </div>
    )
}

export default ActivityBox
