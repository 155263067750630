import React, { useState } from "react"
import Messages from "../Messages/Messages"
import { Auth, API } from "aws-amplify"
import { mirage } from "ldrs"
import "./DashboardChatBot.css"
import { useStores } from "../../models/root-store"


mirage.register()

const DashboardChatBot = ({ recordingDetails }) => {
  const [chatOpen, setChatOpen] = useState(false)
  const { userStore } = useStores()
  const { name } = userStore.user || {}
  const [chatbotMessages, setChatbotMessages] = useState([
    {
      user: "chatbot",
      text: "Hi! I'm your AI virtual assistant! Ask me anything about this role play to further dive in and gather deeper insights. How can I help today?",
    },
  ])
  const [chatpotInput, setChatbotInput] = useState("")
  const [chatBotLoading, setChatBotLoading] = useState(false)

  const handleInputChange = e => {
    setChatbotInput(e.target.value)
  }

  const askMeAnything = async event => {
    event.preventDefault()

    setChatbotMessages([...chatbotMessages, { user: "user", text: chatpotInput }])
    setChatbotInput("")
    setChatBotLoading(true)
    const LEMURResponse = await API.get("ZenoApp", "/askMeAnything", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      queryStringParameters: {
        user_id: recordingDetails.user_id,
        transcript_id: recordingDetails.aai_transcript_id,
        conversation_id: recordingDetails.conversation_id,
        input: chatpotInput.trim(),
      },
    })
    if (LEMURResponse === "Exceeded Max Number of Requests (10) for this Conversation") {
      const exchange = [
        { user: "user", text: chatpotInput },
        { user: "bot", text: LEMURResponse },
      ]
      setChatbotMessages([...chatbotMessages, ...exchange])
    } else {
      const exchange = [
        { user: "user", text: chatpotInput },
        { user: "bot", text: LEMURResponse.LEMURAdvice },
      ]
      setChatbotMessages([...chatbotMessages, ...exchange])
    }
    setChatBotLoading(false)
  }
  const handleChatBotOpen = open => {
    if (open) {
      setChatOpen(false)
    } else {
      setChatOpen(true)
    }
  }
  return !chatOpen ? (
    <div className="ClosedChatBotBubble" onClick={() => handleChatBotOpen(false)}></div>
  ) : (
    <div className="OpenChatBotBox">
      <div className="OpenChatBotBoxHeader">
        <label className="OpenChatBotBoxHeaderLabel">{`Hi ${name} 👋`}</label>
      </div>
      <div className="CloseChatbotButton" onClick={() => handleChatBotOpen(true)}></div>
      <div className="chatbotMessagesContainer">
        <Messages messages={chatbotMessages} type={"Pare"} loading={chatBotLoading}></Messages>
      </div>
      <form onSubmit={e => askMeAnything(e)}>
        {chatBotLoading ? (
          <div className="chatBotInputInactive">
            <l-mirage size="60" speed="1.4" color="grey"></l-mirage>
          </div>
        ) : (
          <input
            type="text"
            className="chatBotInput"
            placeholder="Ask me anything..."
            value={chatpotInput}
            onChange={handleInputChange}></input>
        )}
      </form>
    </div>
  )
}

export default DashboardChatBot
