import React from "react"


const LoadingPopUp = ({ header }) => {


  return (<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-pareBlue pb-6" id="modal-title">{header}</h3>
              <l-grid size="80" speed="1.4" color="#3045FF"></l-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default LoadingPopUp