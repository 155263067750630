import React from "react"

import "./AudioVisualizer.css"
import { LiveAudioVisualizer } from "react-audio-visualize"

const AudioVisualizer = ({ botRecorder }) => {

  return (
    <div class="w-full h-full rounded-[15px] flex justify-center items-center">
      <div>
        {botRecorder && (
          <LiveAudioVisualizer
            mediaRecorder={botRecorder}
            width={"300px"}
            height={"200px"}
            barColor={"#ADADAD"}
            barWidth={3}
          />
        )}
      </div>
    </div>
  )
}

export default AudioVisualizer
