import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Auth, API } from "aws-amplify"
import Messages from "../../components/Messages/Messages"
import { useQuery } from "@tanstack/react-query"
import PageTitle from "../../components/PageTitle/PageTitle"
import { quantum } from "ldrs"
import "./ActivityCreationPage.css"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

quantum.register()

// TODO: should probably use environment variable for this
const ENDPOINT = "wss://dfzlakpg93.execute-api.us-east-1.amazonaws.com/prod"

// TODO: Not good practice to declare/reassign local vars. Should move these to useState()
let socket
let convoIndex = 0
let splitConvoIndex = 0
let persona_file_id = null
let rubric_file_id = null
let contextCreationMessages = []
let persona_id = null
let rubric_id = null

const ActivityCreationPage = () => {
  const { userStore, currentRoleplayStore } = useStores()
  const navigate = useNavigate()
  const [showBasicInfoStep, setShowBasicInfoStep] = useState(true)
  const [showPersonaStep, setShowPersonaStep] = useState(false)
  const [testName, setTestName] = useState("")
  const [timeLimit, setTimeLimit] = useState("")
  const [testDesc, setTestDesc] = useState("")
  const [testObj, setTestObj] = useState("")
  const [publishing, setPublishing] = useState(false)
  const [promptCreated, setPromptCreated] = useState(false)
  const [savedPersona, setSavedPersona] = useState(null)
  const [savedRubric, setSavedRubric] = useState(null)
  const [rubricFile, setRubricFile] = useState(null)
  const [personaFile, setPersonaFile] = useState(null)
  const [links, setLinks] = useState("")
  const voiceOptions = {
    Sam: "Male 1",
    Bob: "Male 2",
    Jennifer: "Female 1",
    Bridgette: "Female 2",
    Margot: "Female 3",
    Alexa: "Female 4",
  }
  const [voice, setVoice] = useState("Sam")
  const [rubricOptions, setRubricOptions] = useState([])
  const [personaOptions, setPersonaOptions] = useState([])
  const [endContextConvo, setEndContextConvo] = useState(false)
  const [chatBotLoading, setChatBotLoading] = useState(false)
  const roleplayConvoOver = useRef(false)
  const [currBotSpeakerTurn, setCurrBotSpeakerTurn] = useState("")
  const [screenShareChecked, setScreenShareChecked] = useState(false)

  const handleScreenShareCheckBoxChange = () => {
    setScreenShareChecked(!screenShareChecked)
  }

  const handleKeyDown = e => {
    e.target.style.height = "inherit"
    e.target.style.height = `${e.target.scrollHeight}px`
  }
  const [contextCreationInput, setContextCreationInput] = useState("")
  const handleContextCreationInputChange = e => {
    // handleKeyDown()
    setContextCreationInput(e.target.value)
  }

  const { isPending, isRefetching, error, data } = useQuery({
    queryKey: [`ActivityCreation_${userStore.user.userId}`],
    queryFn: async () => {
      const managerActivites = await API.get("ZenoApp", "/getManagerActivities", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      })
      const activityCreationData = await API.get("ZenoApp", "/getActivityCreationData", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      })
      return {
        managerActivites,
        rubrics: activityCreationData.rubrics.Items,
        personas: activityCreationData.personas.Items,
      }
    },
    refetchOnMount: "always",
  })

  useEffect(() => {
    if (isPending) {
      console.log("Loading")
    } else {
      setPersonaOptions(
        data.personas.reduce((acc, x) => {
          acc[`${x.persona_id}`] = x.displayName
          return acc
        }, {}),
      )

      setRubricOptions(
        data.rubrics.reduce((acc, x) => {
          acc[`${x.rubric_id}`] = x.displayName
          return acc
        }, {}),
      )
    }
  }, [isPending])

  const cleanupFunction = refresh => {

    currentRoleplayStore.setField("contextCreationRefresh", "N")
    const endPayloadObj = {
      user_id: userStore.user.userId,
      convoIndex,
      convoEnd: true,
      assistant_id: userStore.user.assistant_id,
    }
    const endConvoPayload = { action: "createassistantcontext", message: endPayloadObj }
    socket.send(JSON.stringify(endConvoPayload))
    if (!refresh) {
      socket.close()
    }
    contextCreationMessages = []
    convoIndex = 0
    roleplayConvoOver.current = null
    persona_id = null
    rubric_id = null
    splitConvoIndex = 0
    persona_file_id = null
    rubric_file_id = null
  }

  useEffect(() => {
    let botSpeech = ""
    socket = new WebSocket(ENDPOINT)
    socket.onopen = () => {
      console.log("Succesfully opened WS")
      if (window.performance) {
        if (performance.navigation.type == 1) {
          console.log("This page is reloaded")
          if (currentRoleplayStore.currentRoleplay.contextCreationRefresh === 'Y') {
            cleanupFunction(true)
          } else {
            currentRoleplayStore.setField("contextCreationRefresh", "N")
          }
        } else {
          console.log("This page is not reloaded")
        }
      }

      const payloadObj = {
        user_id: userStore.user.userId,
        convoIndex,
        assistant_id: userStore.user.assistant_id,
      }
      const payload = { action: "createassistantcontext", message: payloadObj }
      socket.send(JSON.stringify(payload))

      setChatBotLoading(true)
    }
    socket.onerror = () => {
      console.log("error")
      alert("Please refresh the page to continue. The session has timed out.")
    }
    // socket.onclose = () => {
    //   alert('Please refresh the page to continue. The session has timed out.')
    // }
    socket.onmessage = async event => {
      console.log(event.data)
      const eventObj = JSON.parse(event.data)
      const botSpeechSegment = eventObj.sentence
      if (
        botSpeechSegment.trim().match(/--CONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/### --CONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/\*\*--CONFIRMED--\*\*/gm)
      ) {
        console.log("received CONFIRMATION")
        if (!roleplayConvoOver.current) {
          roleplayConvoOver.current = true
          splitConvoIndex = contextCreationMessages.length
        }
        if (eventObj.orderIdx === 0) {
          botSpeech += botSpeechSegment.replace(/--CONFIRMED--/gm, "").trim()
          receiveResponse({ user: "Pare", text: botSpeech })
        } else {
          botSpeech += botSpeechSegment.replace(/--CONFIRMED--/gm, "").trim()
          contextCreationMessages[contextCreationMessages.length - 1].text = botSpeech
            .replace(/###/gm, "")
            .replace(/\*\*/gm, "")
          setCurrBotSpeakerTurn(botSpeech)
        }
      } else if (
        botSpeechSegment.trim().match(/--RUBRICCONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/### --RUBRICCONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/\*\*--RUBRICCONFIRMED--\*\*/gm)
      ) {
        if (roleplayConvoOver.current) {
          setEndContextConvo(true)
        } else {
          alert("Something went wrong in the creation process, please refresh to restart the process")
        }
        if (eventObj.orderIdx === 0) {
          botSpeech += botSpeechSegment.replace(/--RUBRICCONFIRMED--/gm, "").trim()
          receiveResponse({ user: "Pare", text: botSpeech })
        } else {
          botSpeech += botSpeechSegment.replace(/--RUBRICCONFIRMED--/gm, "").trim()
          contextCreationMessages[contextCreationMessages.length - 1].text = botSpeech
            .replace(/###/gm, "")
            .replace(/\*\*/gm, "")
          setCurrBotSpeakerTurn(botSpeech)
        }
      } else if (botSpeechSegment.trim() === "DONE") {
        console.log("DONE")
      } else {
        if (eventObj.orderIdx === 0) {
          botSpeech += botSpeechSegment
          receiveResponse({ user: "Pare", text: botSpeech })
        } else {
          botSpeech += botSpeechSegment
          contextCreationMessages[contextCreationMessages.length - 1].text = botSpeech
            .replace(/###/gm, "")
            .replace(/\*\*/gm, "")
          setCurrBotSpeakerTurn(botSpeech)
        }
      }
      if (botSpeechSegment === "DONE") {
        botSpeech = ""
        convoIndex += 1
        stopLoading()
      }
    }

    return () => {
      cleanupFunction(false)
    }
  }, [])

  const receiveResponse = botSpeakerTurn => {
    contextCreationMessages.push(botSpeakerTurn)
    setChatBotLoading(true)
  }
  const stopLoading = async () => {
    await wait(1000)
    setChatBotLoading(false)
  }

  function handlePersonaFileChange(e) {
    if (e.target.files[0] && /\.(doc|pdf)$/i.test(e.target.files[0].name)) {
      setPersonaFile(e.target.files[0])
      setSavedPersona(null)
      // if(personaFile){
      //   contextCreationMessages.pop()
      // }
      contextCreationMessages.push(
        { user: "user", text: e.target.files[0].name },
        {
          user: "Pare",
          text: `Are you sure you want want to upload ${e.target.files[0].name}?`,
          type: "personaFileUploadButton",
        },
      )
    } else {
      alert("Please upload a .doc or pdf formatted file.")
    }
  }

  const handleRubricFileChange = e => {
    if (e.target.files[0] && /\.(doc|pdf)$/i.test(e.target.files[0].name)) {
      setRubricFile(e.target.files[0])
      setSavedRubric(null)
      // if(rubricFile){
      //   contextCreationMessages.pop()
      // }
      contextCreationMessages.push(
        { user: "user", text: e.target.files[0].name },
        {
          user: "Pare",
          text: `Are you sure you want want to upload ${e.target.files[0].name}?`,
          type: "rubricFileUploadButton",
        },
      )
    } else {
      alert("Please upload a .doc or pdf formatted file.")
    }
  }

  const transitionToPersonaStep = () => {
    if (testName.trim().length > 0 && testName.trim().length < 27) {
      if (timeLimit.trim().length > 0 && testDesc.trim().length > 0 && testObj.trim().length > 0 && voice) {
        if (parseInt(timeLimit) > 0 && parseInt(timeLimit) < 31) {
          setShowBasicInfoStep(false)
          setShowPersonaStep(true)
        } else {
          alert("Please enter a value between 1 and 30")
        }
      } else {
        alert("Please fill out all the required information")
      }
    } else {
      alert("Please fill out the module title and make sure it is less than 27 characters")
    }
  }

  const uploadPersonaFile = async () => {
    setChatBotLoading(true)
    const signedUrlImgUpload = await API.get("ZenoApp", "/getSignedUrlDoc", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      queryStringParameters: {
        personaFile: personaFile ? personaFile.name : null,
      },
    })
    persona_id = signedUrlImgUpload.persona_id
    const personaFileType = personaFile.name.replace(/.*\./, "")
    await fetch(signedUrlImgUpload.personaSignedUploadUrl, {
      method: "PUT",
      headers: { "Content-Type": personaFileType === "pdf" ? "application/pdf" : "application/msword" },
      body: personaFile,
    })
    const personaAssistantStatus = await API.post("ZenoApp", "/sendPersonaFile", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        personaFilename: personaFile ? personaFile.name : null,
        persona_id,
        name: userStore.user.firstName,
      },
    })
    persona_file_id = personaAssistantStatus.file_id
    const payloadObj = {
      user_id: userStore.user.userId,
      convoIndex,
      persona_file_id,
      assistant_id: userStore.user.assistant_id,
    }
    const payload = { action: "createassistantcontext", message: payloadObj }
    socket.send(JSON.stringify(payload))
    convoIndex += 1
  }
  const uploadRubricFile = async () => {
    setChatBotLoading(true)
    const signedUrlRubricUpload = await API.get("ZenoApp", "/getSignedUrlDoc", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      queryStringParameters: {
        rubricFile: rubricFile ? rubricFile.name : null,
      },
    })
    rubric_id = signedUrlRubricUpload.rubric_id
    const rubricFileType = rubricFile.name.replace(/.*\./, "")
    await fetch(signedUrlRubricUpload.rubricSignedUploadUrl, {
      method: "PUT",
      headers: { "Content-Type": rubricFileType === "pdf" ? "application/pdf" : "application/msword" },
      body: rubricFile,
    })
    const rubricStatus = await API.post("ZenoApp", "/sendRubricFile", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        rubricFilename: rubricFile ? rubricFile.name : null,
        rubric_id,
        name: userStore.user.firstName,
      },
    })
    rubric_file_id = rubricStatus.file_id
    const payloadObj = {
      user_id: userStore.user.userId,
      convoIndex,
      rubric_file_id,
      assistant_id: userStore.user.assistant_id,
    }
    const payload = { action: "createassistantcontext", message: payloadObj }
    socket.send(JSON.stringify(payload))
    convoIndex += 1
  }

  const createModule = async () => {
    if (!publishing) {
      setPublishing(true)

      API.post("ZenoApp", "/createModule", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          activity_id: null,
          testName: testName,
          timeLimit: timeLimit,
          testType: null,
          testDecription: testDesc,
          persona_id: persona_id,
          personaFilename: personaFile ? personaFile.name : null,
          rubricFilename: rubricFile ? rubricFile.name : null,
          personaPreset: savedPersona,
          rubric_id: rubric_id,
          rubricPreset: savedRubric,
          company: userStore.user.company,
          testObj: testObj,
          links: links,
          voice: voice,
          roleplay_history: contextCreationMessages.slice(0, splitConvoIndex),
          rubric_history: contextCreationMessages.slice(splitConvoIndex, contextCreationMessages.length),
          presentation: screenShareChecked,
        },
      })
      await wait(4000)
      const publishPayloadObj = {
        user_id: userStore.user.userId,
        convoEnd: true,
        assistant_id: userStore.user.assistant_id,
      }
      const publishModulePayload = { action: "createassistantcontext", message: publishPayloadObj }
      socket.send(JSON.stringify(publishModulePayload))
      setPublishing(false)
      navigate("/managerDev")
    } else {
      alert("Publishing already... chill")
    }
  }

  const wait = delay => {
    return new Promise(res => setTimeout(res, delay))
  }

  const handleLinksChange = e => {
    setLinks(e.target.value)
  }
  const handleTestObjChange = e => {
    setTestObj(e.target.value)
  }
  const handleTimeLimitChange = e => {
    setTimeLimit(e.target.value)
  }
  const handleTestDescChange = e => {
    setTestDesc(e.target.value)
  }
  const handleTestNameChange = e => {
    setTestName(e.target.value)
  }

  const handleSavedPersonaChange = persona => {
    setSavedPersona(persona)
    setPersonaFile(null)
  }
  const handleSavedRubricChange = rubric => {
    setSavedRubric(rubric)
    setRubricFile(null)
  }

  const respondToContextBot = async event => {
    event.preventDefault()
    if (contextCreationInput.trim().length > 0) {
      const exchange = { user: "user", text: contextCreationInput }
      contextCreationMessages.push(exchange)
      const payloadObj = {
        user_response: contextCreationInput,
        user_id: userStore.user.userId,
        convoIndex,
        assistant_id: userStore.user.assistant_id,
      }
      const payload = { action: "createassistantcontext", message: payloadObj }
      socket.send(JSON.stringify(payload))
      convoIndex += 1
      setContextCreationInput("")
      setChatBotLoading(true)
    }
  }

  return !promptCreated ? (
    <div className={`w-full h-full flex flex-col px-8 md:px-20 pt-8`}>
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/managerDev">Modules</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{`Create New Roleplay`}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Role Play Builder</h1>
          <p class="mt-2 text-sm text-gray-700">Create your modules with the role play builder</p>
        </div>
      </div>
      {/* <PageTitle pageTitle={"Role Play Builder"}></PageTitle> */}
      {showBasicInfoStep ? (
        <div>
          <div class="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Enter a name for this role play module</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <input placeholder="Enter your module name" value={testName} onChange={handleTestNameChange} type="text" name="first-name" id="first-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"></input>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Provide a description for this role play module</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea placeholder="Enter your module description" value={testDesc} onChange={handleTestDescChange} type="text" name="last-name" id="last-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"></textarea>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Outline the rep’s objective for this role play module</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea placeholder="Enter your module objective" value={testObj} onChange={handleTestObjChange} id="email" name="email" type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"></textarea>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Choose the voice type of your persona</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <select id="voices" name="voices" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  {Object.keys(voiceOptions).map((x, i) => (
                    <option key={i} value={x}>
                      {voiceOptions[x]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Add link(s) to pre-work for reps to review before completing this role play module</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea placeholder="Enter links to trainings or documentation separated by commas" value={links} onChange={handleLinksChange} type="text" name="links" id="links" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"></textarea>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="city" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Set a time limit for this role (min)</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <input type="number" placeholder="Select your module time limit (max 30 min)" min="1" max="30" value={timeLimit} onChange={handleTimeLimitChange} class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"></input>
              </div>
            </div>
          </div>
          <div class="mt-6 pb-8 pt-4 flex items-center justify-end gap-x-6">
            <button type="submit" class="rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={transitionToPersonaStep}>Continue</button>
          </div>
        </div>
      ) : (
          <div className="w-full h-full pb-10">
            <div className="w-full h-full rounded">
              <div className="w-full h-full pt-8 overflow-auto">
                <Messages
                  messages={contextCreationMessages}
                  type={"Pare"}
                  loading={chatBotLoading}
                  uploadPersonaFile={uploadPersonaFile}
                  uploadRubricFile={uploadRubricFile}
                  messagesType={"roleplayCreation"}></Messages>
              </div>
              <form class="pb-8" onSubmit={respondToContextBot}>
                {chatBotLoading ? (
                  <div className="w-full h-25 flex items-center justify-center">
                    <l-mirage size="60" speed="1.4" color="grey"></l-mirage>
                  </div>
                ) : endContextConvo ? (
                  <div className="w-full h-25 flex items-center justify-center">
                    <div className="PublishModuleButton" onClick={createModule}>
                      {publishing ? "Publishing Module..." : "Publish Module"}
                    </div>
                  </div>
                ) : (
                      <>
                        <div class=" w-full flex items-end bg-gray-300 rounded-lg shadow-sm ">
                          <button class="UploadFileButton flex w-[55px] h-[30px] items-center justify-center text-gray-500 hover:text-blue-500 focus:outline-none mb-4 ml-2">
                            <input type="file" class="hidden" id="file-upload"></input>
                          </button>

                          <textarea
                            type="text"
                            placeholder="Type your message..."
                            class="flex-grow border-none focus:ring-0 outline-none resize-none overflow-hidden h-auto max-h-36 bg-gray-300 overflow-auto p-2"
                            onKeyDown={handleKeyDown}
                            value={contextCreationInput}
                            onChange={handleContextCreationInputChange}
                          />

                          <button class=" SubmitChatButton w-[70px] h-[30px] flex items-center justify-center bg-pareBlue text-white hover:text-blue-500 focus:outline-none mb-4 mr-4 rounded">

                          </button>
                        </div>
                      </>
                    )}
              </form>
            </div>
          </div>
        )}
    </div>
  ) : (
      <div class="h-full xl:pl-0 w-full bg-pareBlue flex items-center justify-center">
        <div className="w-[30%] h-[30%] flex flex-col items-center justify-center">
          <p class="text-white semi-bold text-lg leading-7">Generating Conversation</p>
          <div className="QuantumLoaderDiv">
            <l-quantum size="60" speed="1.75" color="white"></l-quantum>
          </div>
        </div>
      </div>
    )
}

export default observer(ActivityCreationPage)
