import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Messages from "../../components/Messages/Messages"
import { Auth, API } from "aws-amplify"
import { useQuery } from "@tanstack/react-query"
import DashboardChatBot from "../../components/DashboardChatBot/DashboardChatBot"
import { grid } from "ldrs"
import "./ModuleAnalysisPage.css"
import "video-react/dist/video-react.css"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import DynamicText from "../../components/TextComponents/DynamicText"
import SubmitReviewPopUp from "../../components/PopUps/SubmitReviewPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

grid.register()

const ModuleAnalysisPage = () => {
  const { userStore } = useStores()
  const [transcript, setTranscript] = useState([])
  const location = useLocation()
  const [convoTitle, setConvoTitle] = useState("")
  const [totalDuration, setTotalDuration] = useState(0)
  const [videoURL, setVideoURL] = useState("")
  const [lemurAdvice, setLemurAdvice] = useState("")
  const [viewType, setViewType] = useState("Overview")
  const [metricOpen, setMetricOpen] = useState("PaceMetric")
  const [searchBarInput, setSearchBarInput] = useState("")
  const [talkTrackPos, setTalkTrackPos] = useState(0)
  const [teamMemberName, setTeamMemberName] = useState("")
  const [rubricScore, setRubricScore] = useState("")
  const [showSubmitReviewPopUp, setShowSubmitReviewPopUp] = useState(false)
  const [popUpLoading, setPopUpLoading] = useState(false)
  const [reviewSuccess, setReviewSuccess] = useState(true)
  const [comments, setComments] = useState("")
  const [passed, setPassed] = useState(false)
  const [date, setDate] = useState(null)

  const { isPending, error, data } = useQuery({
    queryKey: [`RecordingDetailsManagerView_${location.state.conversation_id}`],
    queryFn: async () => {
      const conversation_id = location.state.conversation_id
      const conversationData = await API.get("ZenoApp", "/getRecordingDetails", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: location.state.user_id,
          conversation_id: conversation_id,
        },
      })
      const transcript_id = conversationData.user_recordings_data.aai_transcript_id
      const AAIData = await API.get("ZenoApp", "/getAaiData", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: location.state.user_id,
          transcript_id: transcript_id,
        },
      })
      const video = await API.get("ZenoApp", "/getVideo", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: location.state.user_id,
          conversation_id: conversation_id,
        },
      })

      return { conversationData, AAIData, video }
    },
  })

  useEffect(() => {
    if (!isPending) {
      console.log(data)
      setTeamMemberName(data.conversationData.user_recordings_data.name)
      setTranscript(JSON.parse(data.conversationData.user_transcripts_data.transcript))
      setConvoTitle(
        location.state.activity_id ? location.state.testName : data.conversationData.user_recordings_data.convo_title,
      )
      const AI_advice = JSON.parse(data.AAIData.lemuradvice)
      const lemur_advice = AI_advice.LEMURAdvice.replace(/- /g, "\u2022 ")
        .replace(/^\n/, "")
        .replace(/\*\*(.*)\*\*/gm, "$1")
        .replace(/###(.*\n)/gm, "$1")

      setLemurAdvice(lemur_advice.replace(/\|\|.*\|\|\n/gm, ""))
      const total_duration = data.AAIData.audio_duration
      setTotalDuration(total_duration)
      setVideoURL(data.video)
      setRubricScore(data.AAIData.rubric_score.replace(/Overall Score:/g, ""))
      const convo_date = new Date(data.conversationData.user_recordings_data.createdAt)

      setDate(convo_date.toDateString())
    }
  }, [isPending])

  const handleCommentChange = e => {
    setComments(e.target.value)
  }

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }
  const markAsPassed = async () => {
    setPopUpLoading(true)
    await API.post("ZenoApp", "/markAsPassed", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        activity_id: location.state.activity_id,
        user_id: location.state.user_id,
        conversation_id: location.state.conversation_id,
        passed: reviewSuccess,
        comments: comments,
      },
    })
    setPassed(true)
    setPopUpLoading(false)
    setShowSubmitReviewPopUp(false)
  }
  const handleViewTransition = view => {
    if (view === "Overview") {
      setViewType("Overview")
    } else if (view === "Scoring") {
      setViewType("Scoring")
    } else {
      switchToAnalysisView()
    }
  }

  const switchToAnalysisView = () => {
    setViewType("Analysis")
    setMetricOpen("PaceMetric")
  }

  const handleTimeUpdate = event => {
    setTalkTrackPos(event.target.currentTime)
  }

  const exitPopUp = () => {
    setShowSubmitReviewPopUp(false)
  }

  const submitReviewPopUp = showSubmitReviewPopUp ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Submitting Feedback..."></LoadingPopUp>
      ) : (
          <SubmitReviewPopUp header="Submit Review" body1="Select whether the rep passed this module or try again" body2="Leave some feedback for your rep on what influenced your decision" rightButtonFunction={markAsPassed} rightButtonLabel="Submit" leftButtonFunction={exitPopUp} leftButtonLabel="Cancel" comments={comments} handleCommentChange={handleCommentChange}></SubmitReviewPopUp>
        )}
    </div>
  ) : null

  const handleSubmitReviewButtonClick = () => {
    setShowSubmitReviewPopUp(true)
  }

  const isManager = userStore.user.type === "Manager"
  const pageName = isManager ? `${data.conversationData.user_recordings_data.name}'s Attempt` : "My Attempt"
  const breadCrumbView = (
    <div className="flex flex-col items-start">
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={isManager ? "/managerDev" : "/assignmentsPage"}>Modules</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>{`${location.state.testName}`}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{pageName}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="w-full flex flex-row justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">{location.state.testName}</h1>
        {isManager ? (
          <button
            onClick={handleSubmitReviewButtonClick}
            className="py-2 px-2 md:px-8 rounded-md bg-blue-600 text-white text-sm font-semibold">
            Submit Review
          </button>
        ) : null}
      </div>
    </div>
  )

  const cardsView = isPending ? null : (
    <div className="cardsContainer mb-4">
      {isManager ? (
        <div className="cardContainer">
          <label>{data.conversationData.user_recordings_data.name}</label>
        </div>
      ) : null}
      <div className="cardContainer">
        <div className="blackCalendarIcon"></div>
        <label>{date}</label>
      </div>
      <div className="cardContainer">
        <div className="blackClockIcon"></div>
        <label>{data.conversationData.user_recordings_data.conversation_time_str}</label>
      </div>
    </div>
  )

  const tabs = (
    <Tabs defaultValue="Overview" className="mb-4" onValueChange={str => handleViewTransition(str)}>
      <TabsList>
        <TabsTrigger value="Overview">Overview</TabsTrigger>
        <TabsTrigger value="Scoring">Scoring</TabsTrigger>
      </TabsList>
    </Tabs>
  )

  const pendingView = (
    <div className="flex-1 flex flex-row content-center justify-center">
      <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
    </div>
  )

  const videoView = (
    <div className="flex-1 aspect-[16/9] 2xl:w-1/2">
      <video src={videoURL} controls height="100%" width="100%" preload="auto" onTimeUpdate={handleTimeUpdate}></video>
    </div>
  )

  const messagesToDisplay = searchBarInput.length
    ? transcript.filter(x => x.text.match(new RegExp(`^.*${searchBarInput}.*`, "i")))
    : transcript

  const transcriptView = (
    <div className="flex-1 flex flex-col aspect-[16/9] 2xl:w-1/2">
      <div className="flex flex-col px-2">
        <p className="text-lg">Transcript</p>
        <input
          type="text"
          placeholder="Search"
          className="w-full rounded-sm border-2 border-gray-400 mb-2"
          value={searchBarInput}
          onChange={handleSearchBarChange}></input>
      </div>
      <div className="flex-1 overflow-auto px-2 pt-2">
        <Messages messages={messagesToDisplay} name={""} />
      </div>
    </div>
  )

  // const heatmapView = (
  //   <div className="hidden xl:block">
  //     <div className="w-full">
  //       <p className="text-xl font-semibold">{`${teamMemberName}`}</p>
  //       <div className="relative h-[2px] bg-gray-500 my-4">
  //         {JSON.parse(data.conversationData.user_transcripts_data.transcript)
  //           .filter(x => x.user === "user")
  //           .map(x => (
  //             <div
  //               key={`user-bar-${x.start}`}
  //               style={{
  //                 width: `${((x.end - x.start) / 1000 / totalDuration) * 100}%`,
  //                 height: "10px",
  //                 background: "#3045ff",
  //                 position: "absolute",
  //                 top: "-4px",
  //                 left: `${(x.start / 1000 / totalDuration) * 100}%`,
  //                 borderRadius: "5px",
  //               }}></div>
  //           ))}
  //         <div
  //           style={{
  //             width: "3px",
  //             height: "20px",
  //             background: "black",
  //             position: "absolute",
  //             top: "-7.5px",
  //             left: `${(talkTrackPos / totalDuration) * 100}%`,
  //           }}></div>
  //       </div>
  //     </div>
  //     <div className="ProspectTalkTrackContainer">
  //       <p className="text-xl font-semibold">{`Prospect`}</p>
  //       <div className="relative h-[2px] bg-gray-500 my-4">
  //         {JSON.parse(data.conversationData.user_transcripts_data.transcript)
  //           .filter(x => x.user === "bot")
  //           .map(x => (
  //             <div
  //               key={`bot-bar-${x.start}`}
  //               style={{
  //                 width: `${((x.end - x.start) / 1000 / totalDuration) * 100}%`,
  //                 height: "10px",
  //                 background: "grey",
  //                 position: "absolute",
  //                 top: "-4px",
  //                 left: `${(x.start / 1000 / totalDuration) * 100}%`,
  //                 borderRadius: "5px",
  //               }}></div>
  //           ))}
  //         <div
  //           style={{
  //             width: "3px",
  //             height: "20px",
  //             background: "black",
  //             position: "absolute",
  //             top: "-7.5px",
  //             left: `${(talkTrackPos / totalDuration) * 100}%`,
  //           }}></div>
  //       </div>
  //     </div>
  //   </div>
  // )

  // "Overview" tab
  const overviewTab = (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-col 2xl:flex-row 2xl:mb-4">
        {videoView}
        {transcriptView}
      </div>
      {/* {heatmapView} */}
    </div>
  )

  // "Scoring" tab
  const scoringTab = (
    <div className="flex flex-col 2xl:flex-row">
      <div className="flex flex-col items-center px-10 pt-12">
        <p className="text-3xl font-bold mb-3">{rubricScore}</p>
        <div className="text-md text-gray-300">Score</div>
      </div>

      <div className="whitespace-pre-line text-lg 2xl: text-xl text-slate-400 pb-10">
        <DynamicText content={lemurAdvice}></DynamicText>
      </div>
    </div>
  )

  return (
    <div className="w-full h-full flex flex-col px-8 2xl:px-20 pt-8">
      {breadCrumbView}
      {cardsView}
      {tabs}
      {isPending ? pendingView : viewType === "Overview" ? overviewTab : viewType === "Scoring" ? scoringTab : null}
      <DashboardChatBot
        recordingDetails={isPending ? {} : data.conversationData.user_recordings_data}></DashboardChatBot>
      {submitReviewPopUp}
    </div>
  )
}

export default observer(ModuleAnalysisPage)
