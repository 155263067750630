import React, { useState } from "react"
import IndividualActivityBox from "../../components/IndividualActivityBox/IndividualActivityBox"
import { Auth, API } from "aws-amplify"
import { useQuery } from "@tanstack/react-query"
import { grid } from "ldrs"
import "./AssignmentsPage.css"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

grid.register()

const AssignmentsPage = () => {
  const { userStore } = useStores()
  const [searchBarInput, setSearchBarInput] = useState("")
  const [selectedFilter, setSelectedFilter] = useState("All")

  const { isPending, error, data, isRefetching } = useQuery({
    queryKey: [`AssignmentData_${userStore.user.userId}`],
    queryFn: async () =>
      await API.get("ZenoApp", "/getAssignedModules", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          manager_id: userStore.user.managerId,
        },
      }),
    refetchOnMount: "always",
  })

  const today = new Date().toDateString()
  const epochToday = new Date(today).getTime()
  const filteredActivities = data ?.updatedInvolvedActivities.filter(activity => {
    // Filter out anything that doesn't match search
    if (searchBarInput.length > 0) {
      if (!activity.testName.match(new RegExp(`^${searchBarInput}.*`, "i"))) {
        return false
      }
    }

    const individualDueDate = JSON.parse(activity.testParticipants)[userStore.user.userId].dueDate
    const stringToDate = new Date(individualDueDate)
    const epochDueDate = stringToDate.getTime()
    const participantObj = JSON.parse(activity.testParticipants)

    // Passed
    if (selectedFilter === "Passed" && participantObj[userStore.user.userId].status !== "Passed") {
      return false
    }

    // Overdue
    if (
      selectedFilter === "Overdue" &&
      ((epochDueDate > epochToday && participantObj[userStore.user.userId].status === "Incomplete") ||
        participantObj[userStore.user.userId].status === "Passed" ||
        participantObj[userStore.user.userId].status === "Pending" ||
        participantObj[userStore.user.userId].status === "In Progress" ||
        participantObj[userStore.user.userId].status === "Not Passed")
    ) {
      return false
    }

    // Not Passed
    if (selectedFilter === "Not Passed" && participantObj[userStore.user.userId].status !== "Not Passed") {
      return false
    }

    return true
  })

  return (
    <div className="w-full h-full flex flex-col px-8 md:px-20 pt-8">
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbPage>Modules</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Module Library</h1>
          <p class="mt-2 text-sm text-gray-700">View and complete assigned role play modules</p>
        </div>

      </div>
      <div className="mb-4 mt-4">
        <Tabs defaultValue="All" className="w-[100%]" onValueChange={setSelectedFilter}>
          <TabsList>
            <TabsTrigger value="All">All</TabsTrigger>
            <TabsTrigger value="Passed">Passed</TabsTrigger>
            <TabsTrigger value="Overdue">Overdue</TabsTrigger>
            <TabsTrigger value="Not Passed">Not Passed</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      {isPending ? (
        <div className="flex-1 flex flex-row items-center justify-center">
          <l-grid size="120" speed="1.4" color="#3045FF" className="bg-gray-200 mt-20" />
        </div>
      ) : filteredActivities ?.length === 0 ? (
        <div className="flex flex-col items-center mt-20">
          <p className="text-sm text-muted-foreground">No Active Modules</p>
          <p className="text-sm text-muted-foreground">Please wait for your manager to create and assign</p>
        </div>
      ) : (
          <div className="w-full h-full rounded-md shadow-sm flex flex-row flex-wrap">
            {filteredActivities ?.map((activity) => (
              <IndividualActivityBox
                type={"FlexBox"}
                activityInfo={activity}
                key={`activity_card_${activity.activity_id}`}
              />
            ))}
          </div>
        )}
    </div>
  )
}

export default observer(AssignmentsPage)
