import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { API } from "aws-amplify"
import "./PricingPage.css"

const PricingPage = () => {
  const [FirstName, setFirstName] = useState("")
  const [LastName, setLastName] = useState("")
  const [Email, setEmail] = useState("")
  const [Company, setCompany] = useState("")
  const [requested, setRequested] = useState(false)
  const [requestPopUp, setRequestPopUp] = useState(false)
  const [FadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    setFadeIn(true)
  }, [])

  const handleFirstNameChange = event => {
    setFirstName(event.target.value)
  }
  const handleLastNameChange = event => {
    setLastName(event.target.value)
  }
  const handleEmailChange = event => {
    setEmail(event.target.value)
  }
  const handleCompanyChange = event => {
    setCompany(event.target.value)
  }
  const handleWaitListSubmit = () => {
    setRequested(false)
    setRequestPopUp(false)
  }

  const sendAccessRequest = async () => {
    if (!Email.trim().match(/^.+@.+\.[a-z]+$/)) {
      alert("Please provide a valid email address!")
    } else if (
      FirstName.trim().length > 0 &&
      LastName.trim().length > 0 &&
      Company.trim().length > 0 &&
      Email.trim().length > 0
    ) {
      setRequested(true)
      const waitlistRequest = API.post("ZenoApp", "/addToRequestWaitlist", {
        body: {
          name: FirstName,
          Lname: LastName,
          email: Email,
          Company: Company,
        },
      })
      setTimeout(() => handleWaitListSubmit(), 2000)
    } else {
      alert("Make sure to fill out all the information fields and submit again.")
    }
  }

  const requestPopUpBody = requested ? (
    <div className="requestPopUpRequested">
      <p className="requestedMessage"> Thank you for showing your interest! We will provide you an update soon! 🎉</p>
    </div>
  ) : (
    <div className="requestPopUp">
      <button className="requestAccessBackButton" onClick={() => setRequestPopUp(false)}></button>
      <h1 className="requestAccess4">Join Waitlist</h1>
      <input className="firstName" placeholder="First Name" value={FirstName} onChange={handleFirstNameChange} />
      <input className="lastName" placeholder="Last Name" value={LastName} onChange={handleLastNameChange} />
      <input className="company" placeholder="Company Name" value={Company} onChange={handleCompanyChange} />
      <input className="emailAddress" placeholder="Email" value={Email} onChange={handleEmailChange} />
      <button className="submit" onClick={sendAccessRequest}>
        Submit
      </button>
      <p className="dealSealIsCurrentlyInviteOnlyP">
        The Pare Beta is currently invite–only. Please fill this out to request access.
      </p>
    </div>
  )

  const AccessReqPopup = requestPopUp ? <div className="frostedBackgroundLP">{requestPopUpBody}</div> : null

  return (
    <div className={`backgroundDivPricingPage ${FadeIn ? "is-visible" : ""}`}>
      <div className="topBannerPP">
        <div className="textBlock">We are currently in private beta – join the waitlist to get access!</div>
      </div>
      <div className="SubBannerPP">
        <div className="subBannerButtonDiv">
          <Link to={`/`} style={{ textDecoration: "none", fontWeight: 500 }} className="whyPareButtonLink">
            Meet Pare
          </Link>
          <Link to={`/`} style={{ textDecoration: "none" }} className="howItWorksButtonLink">
            How It Works
          </Link>
          <Link to={"/pricingpage"} style={{ textDecoration: "none" }} className="PricingButton">
            Pricing
          </Link>
        </div>
        <div className="PareBlackLogo"></div>
        <Link to={`/signin`} style={{ textDecoration: "none" }}>
          <button className="SignInButtonLP">Log In</button>
        </Link>
        <button className="requestAccessBanner">
          <label className="requestAccessBannerLabel">Join Waitlist</label>
          <div style={{ width: "5%" }}></div>
          <div className="RightArrowSmallButton"></div>
        </button>{" "}
      </div>
      <br></br>
      <br></br>
      <h1 className="PPHeader">Plans And Pricing</h1>
      <div className="pricingBox">
        <div className="PareLogoNoWords"></div>
        <h1 className="pricingBoxHeader">Current Pricing: FREE</h1>
        <p className="pricingBoxP">
          Pare is currently in private beta, giving you full access for free. You will be notified before we launch paid
          plans. Once those plans are rolled out, you will still be able to enjoy your usage on a free plan even if you
          choose to not upgrade.
        </p>
        <button className="pricingBoxRAB" onClick={() => setRequestPopUp(true)}>
          <label className="requestAccessBannerLabel">Join Waitlist</label>
          <div style={{ width: "5%" }}></div>
          <div className="RightArrowSmallButton"></div>
        </button>
      </div>
      {AccessReqPopup}
    </div>
  )
}

export default PricingPage
