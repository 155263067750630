import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import paresvg from "../../tailwinds_components/images/logos/parelogo.svg"
import Alert from "../../tailwinds_components/components/alert"; // Import the Alert component

export default function ExampleLogin() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState(""); // Renamed state to 'type'
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState(null); // State to handle alert
  const navigate = useNavigate();

  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleCompanyChange = (event) => setCompany(event.target.value);
  const handleTypeChange = (event) => setType(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);

  const handleWaitlistRequest = async () => {
    const errors = [];
    if (!email.trim().match(/^.+@.+\.[a-z]+$/)) {
      errors.push("Please provide a valid email address.");
    }
    if (firstName.trim().length === 0) {
      errors.push("First name is required.");
    }
    if (lastName.trim().length === 0) {
      errors.push("Last name is required.");
    }
    if (company.trim().length === 0) {
      errors.push("Company is required.");
    }
    if (type.trim().length === 0) {
      errors.push("Please select your role.");
    }

    if (errors.length > 0) {
      // Set an error alert with the errors
      setAlert({ type: "error", title: "There were errors with your submission", messages: errors });
    } else {
      try {
        await API.post("ZenoApp", "/addToRequestWaitlist", {
          body: {
            Fname: firstName.trim(),
            Lname: lastName.trim(),
            email: email.trim(),
            company: company.trim(),
            type: type.trim(),
          },
        });
        // Set a success alert after the API request completes
        setAlert({ type: "success", title: "You have been added to the waitlist!", messages: [] });

        // Clear the form
        setFirstName("");
        setLastName("");
        setCompany("");
        setType("");
        setEmail("");

        // Optionally redirect after a short delay
        setTimeout(() => navigate("/"), 3000); // Redirect after 3 seconds
      } catch (error) {
        // Handle API errors
        setAlert({ type: "error", title: "Submission failed", messages: ["An error occurred. Please try again."] });
      }
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="Pare"
          src={paresvg}
          className="mx-auto h-10 w-auto"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Join the waitlist
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
        {/* Display alert if there's any */}
        {alert && <Alert type={alert.type} title={alert.title} messages={alert.messages} />}

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-900">
            Email Address
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
            First Name
          </label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            value={firstName}
            onChange={handleFirstNameChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-900">
            Last Name
          </label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            value={lastName}
            onChange={handleLastNameChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="company" className="block text-sm font-medium text-gray-900">
            Company
          </label>
          <input
            id="company"
            name="company"
            type="text"
            value={company}
            onChange={handleCompanyChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="type" className="block text-sm font-medium text-gray-900">
            Role
          </label>
          <select
            id="type"
            name="type"
            value={type}
            onChange={handleTypeChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Select your role</option>
            <option value="admin">Admin</option>
            <option value="manager">Manager</option>
            <option value="AE">AE</option>
            <option value="SDR">SDR</option>
            <option value="other">Other</option>
          </select>
        </div>

        <button
          onClick={handleWaitlistRequest}
          className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-500"
        >
          Join Waitlist
        </button>

        <p className="text-center text-sm">
          By joining, you agree to our{" "}
          <button className="text-indigo-600 hover:underline" onClick={() => navigate("/tos")}>
            Terms of Service
          </button>{" "}
          and{" "}
          <button className="text-indigo-600 hover:underline" onClick={() => navigate("/privacy")}>
            Privacy Policy
          </button>
        </p>
      </div>
    </div>
  );
}
