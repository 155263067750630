import { Button } from "./Button"
import { Container } from "./Container"
import backgroundImage from "../images/background-call-to-action.jpg"

export function CallToAction() {
  return (
    <section id="get-started-today" className="relative overflow-hidden bg-blue-600 py-32">
      <img
        className="absolute left-1/2 top-1/2 max-w-none transform -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        style={{ width: "2347px", height: "1244px", objectFit: "cover" }} // adjust size if needed
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">Get started today</h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Ready to see what Pare can do for your team? Joing the waitlist to start using Pare, entirely for free
          </p>
          <Button href="/waitlistRequestPage" color="white" className="mt-10">
            Join the waitlist
          </Button>
        </div>
      </Container>
    </section>
  )
}
