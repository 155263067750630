import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"

const CurrentRoleplayFieldsModel = types
  .model("CurrentRoleplayFields", {
    contextCreationRefresh: types.string,
    simulationReady: types.string,
    prospectName: types.optional(types.string, ""),
    personaName: types.optional(types.string, ""),
    callType: types.optional(types.string, ""),
    additionalInstructions: types.optional(types.string, ""),
    assistant_id: types.optional(types.string, ""),
    conversationContext: types.optional(types.string, ""),
    systemPrompt: types.optional(types.string, "")
    // - FirstName, LastName (we have name, but SessionStorage was using first/last)
    //    - Note: made views for firstName/lastName, but should prob send down all 3
    //      separately (firstName, lastName, fullName) for all scenarios
  })
export type CurrentRoleplayFieldsSnapshotIn = SnapshotIn<typeof CurrentRoleplayFieldsModel>

export const CurrentRoleplayStoreModel = types
  .model("CurrentRoleplayStore", {
    currentRoleplay: types.maybeNull(CurrentRoleplayFieldsModel),
  })
  .actions(self => ({
    setup: flow(function* setup() {
      try {
        self.currentRoleplay = CurrentRoleplayFieldsModel.create({
          contextCreationRefresh: "N",
          simulationReady: "N",
          prospectName: "",
          personaName: "",
          callType: "",
          additionalInstructions: "",
          assistant_id: "",
          conversationContext: "",
          systemPrompt: ""
        })
      } catch (e) {
        console.log(`Error creating CurrentRoleplayFieldsModel`);
      }

    }),
  }))
  .actions(self => ({
    setField: flow(function* setField(key: string, value: string) {
      try {
        console.log(self.currentRoleplay)
        if (Object.keys(self.currentRoleplay).includes(key)) {
          console.log(self.currentRoleplay)
          self.currentRoleplay[key] = value;

        } else {
          console.log(`Field ${key} does not exist on CurrentRoleplayStore.`);
        }

      } catch (e) {
        console.log(`Error updating ${key} in CurrentRoleplayStore.`);
      }
    }),
  }))
export type CurrentRoleplayStore = Instance<typeof CurrentRoleplayStoreModel>
