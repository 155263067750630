import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Auth, API } from "aws-amplify"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { grid } from "ldrs"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"

grid.register()

const LearningCenterPage = () => {
  const queryClient = useQueryClient()
  let navigate = useNavigate()

  // const { isPending, error, data, refetch, isRefetching } = useQuery({
  //   queryKey: ["ManagerActivities"],
  //   queryFn: async () =>
  //     await API.get("ZenoApp", "/getManagerActivities", {
  //       headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
  //     }),
  //   refetchOnMount: "always",
  // })

  // useEffect(() => {
  //   if (isPending) {
  //     console.log("Loading")
  //   } else if (isRefetching) {
  //     setActivityList(data.managerActivities)
  //   } else {
  //     setActivityList(data.managerActivities)
  //     activityNameList = data.managerActivities.map(x => x.testName.toLowerCase())
  //   }
  // }, [isPending, isRefetching])




  return (
    <div class="w-full h-full flex flex-col px-8 md:px-20 pt-8">
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>Learning Center</BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Learning Center</h1>
          <p class="mt-2 text-sm text-gray-700">See how you stack up again the Ideal Rep Profile</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button type="button" class="block rounded-md bg-pareBlue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Do Something</button>
        </div>
      </div>

    </div>
  )
}

export default observer(LearningCenterPage)
