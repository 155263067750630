import { APIQueryBuilder } from "./helpers"
import { GetProfilePictureFromS3Response, GetUserInfoResponse } from "./api.types"

export async function getUserInfo(): Promise<GetUserInfoResponse> {
  try {
    const res = await APIQueryBuilder("/getUserInfo")
    return { kind: "ok", data: res.userInfo }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getProfilePictureFromS3(): Promise<GetProfilePictureFromS3Response> {
  try {
    const res  = await APIQueryBuilder("/getProfilePicture")
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}
