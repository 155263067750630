import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { sendToVercelAnalytics } from "./vitals"
import { Amplify } from "aws-amplify"
import config from "./config"
import { Auth } from "aws-amplify"
import awsmobile from "./aws-exports"

// AWS configs
Auth.configure(awsmobile)
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    userPoolId: "us-east-1_0BhFmOvSg",
    identityPoolId: "us-east-1:e679daa4-cd88-4109-929d-2d417f809d93",
    userPoolWebClientId: "750srjjhn1drresm7h5ri4shh7",
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "ZenoApp",
        endpoint: "https://pmzlvuqc1c.execute-api.us-east-1.amazonaws.com",
        region: "us-east-1",
      },
      {
        name: "DataIntegration",
        endpoint: "https://mlimddved4.execute-api.us-east-1.amazonaws.com",
        region: "us-east-1",
      },
    ],
  },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>,
)

reportWebVitals(sendToVercelAnalytics)
