import React from "react"
import SingleRecording from "./SingleRecording/SingeRecording"

import "./Recordings.css"

const Recordings = ({ recordings, name, handleMenuPopUp, filterParameter, activityName }) => {
  const sortedRecordings =
    filterParameter === 1
      ? recordings
          .filter(x => !x.activity_id)
          .sort(function (a, b) {
            return b.createdAt - a.createdAt
          })
      : filterParameter === 2
        ? recordings
            .filter(x => !x.activity_id)
            .sort(function (a, b) {
              return a.conversation_time - b.conversation_time
            })
        : filterParameter === 3
          ? recordings
              .filter(x => !x.activity_id)
              .sort(function (a, b) {
                return b.conversation_time - a.conversation_time
              })
          : recordings
  return (
    <div className="recordings">
      {sortedRecordings.map((recording, i) => (
        <div key={i}>
          <SingleRecording
            recording={recording}
            name={name}
            handleMenuPopUp={handleMenuPopUp}
            activityName={activityName}
          />
        </div>
      ))}
    </div>
  )
}

export default Recordings
